import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {OrderByPipe} from '@shared/shared-module/pipes/order-by.pipe';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {DutyContextComponent} from 'projects/admin-query/src/app/components/duty-context/duty-context.component';
import {
  CyclesDto,
  RecruitmentRestService,
  RequestType
} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {take, tap} from 'rxjs';
import {FormStepperNavigationComponent} from '../../../../../../../../src/app/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaContentNoticeComponent} from '../../../../../../../../src/app/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaSaveDraftComponent} from '../../../../../../../../src/app/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '../../../../../../../../src/app/shared-module/components/required-fields-notice/required-fields-notice.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

@Component({
  selector: 'msa-side-process-substitution-time',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './side-process-substitution-time.component.html',
  imports: [
    CommonModule,
    SafeTranslateModule,
    DutyContextComponent,
    RequestContextComponent,
    ReactiveFormsModule,
    MatSelectModule,
    FormStepperNavigationComponent,
    MsaSaveDraftComponent,
    RequiredFieldsNoticeComponent,
    MsaContentNoticeComponent,
    OrderByPipe
  ]
})
export class StepSideProcessSubstitutionTimeComponent extends StepEditRequestComponent implements OnInit {
  private recruitmentRestService = inject(RecruitmentRestService);
  private destroyRef = inject(DestroyRef);

  public readonly RequestType = RequestType;

  public form = new FormGroup({
    cycleId: new FormControl<CyclesDto['cycleId'] | null>(null)
  });

  // exposed state
  public availableSubstitutionDates = signal<CyclesDto[]>([]);

  public freePlacesAvailable = computed(
    () => isDefined(this.availableSubstitutionDates()) && this.availableSubstitutionDates().length > 0
  );

  ngOnInit(): void {
    const request = this.request();
    this.form.patchValue({cycleId: request.requestDetail.substitutionCycleId});
    this.fetchSubstitutionDateCycles();
  }

  onNext(): void {
    this.action.emit({type: 'updateSubstitutionDate', payload: {substitutionCycleId: this.form.value.cycleId!}});
  }

  onSaveClick(): void {
    this.action.emit({type: 'saveDraft'});
  }

  private fetchSubstitutionDateCycles(): void {
    this.form.controls.cycleId.patchValue(null);
    this.recruitmentRestService
      .getSideProcessesCycles(this.request().dutyId)
      .pipe(
        take(1),
        tap(response => this.availableSubstitutionDates.set(response)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
