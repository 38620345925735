import {HttpErrorResponse} from '@angular/common/http';
import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Action, State, StateContext} from '@ngxs/store';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {Observable, catchError, tap, throwError} from 'rxjs';
import {RequestDutyInfoDto, RequestsRestService} from '../../core/api/generated/msa-duty-service';
import {FetchRequestDutyInfos} from '../actions/duty.state.actions';
import {FetchAllRequests, FetchRequest, OpenRequest} from '../actions/requests.state.actions';
import {DutyStateModel} from '../models/duty.state.model';

@State<DutyStateModel>({
  name: 'duty',
  defaults: {
    dutyInfos: [],
    errorMessage: null
  }
})
@Injectable()
export class DutyState {
  constructor(
    private requestsRestService: RequestsRestService,
    private destroyRef: DestroyRef,
    private errorMessageHandler: ErrorMessageHandler
  ) {}

  @Action(FetchRequest)
  @Action(FetchRequestDutyInfos)
  @Action(FetchAllRequests)
  @Action(OpenRequest)
  updateDuties(ctx: StateContext<DutyStateModel>): Observable<RequestDutyInfoDto[]> {
    return this.requestsRestService.getRequestDutyInfo().pipe(
      catchError(error => this.handleInlineError(error, ctx, 'i18n.duties.error.load')),
      catchError(error => this.errorMessageHandler.logAndIgnore(error, 'i18n.duties.error.load')),
      tap(dutyInfos => ctx.patchState({dutyInfos: dutyInfos ?? [], errorMessage: null})),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private handleInlineError(
    error: HttpErrorResponse,
    ctx: StateContext<DutyStateModel>,
    errorMessage: string
  ): Observable<RequestDutyInfoDto[]> {
    ctx.patchState({errorMessage});
    return throwError(() => error);
  }
}
