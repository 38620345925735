<p class="msa-text tw-mb-6" safeTranslate="i18n.duty-relocation-form.affected-duty"></p>
@if (affectedDuty(); as duty) {
  <div class="tw-rounded-4 tw-rounded tw-bg-black-light-1">
    <div class="tw-pe-30 tw-ps-30 tw-pt-24">
      <div class="msa-text">
        <span class="tw-font-bold">{{ duty.dutyType | translateObject }}</span
        >&nbsp;
        <span>{{ duty.division | translateObject }}</span>
      </div>
      <div class="msa-text-small tw-pb-30 tw-pt-18">
        @for (dateTime of affectedDateRanges(); track dateTime.startDate) {
          <div class="tw-mb-6">
            <span>{{ dateTime.startDate | date: GERMAN_SHORT_DATE_FORMAT }}</span>
            @if (dateTime.startTime) {
              <span>, {{ dateTime.startTime }}</span>
            }
            -
            <span>{{ dateTime.endDate | date: GERMAN_SHORT_DATE_FORMAT }}</span>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="tw-mt-30 d:tw-mt-60"></div>
}
