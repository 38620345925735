<div class="msa-text tw-mt-9 tw-flex tw-flex-col d:tw-mt-18 d:tw-flex-row d:tw-gap-30">
  <p
    class="tw-mb-9 tw-w-full tw-text-black-light-5 d:tw-mb-0 d:tw-min-w-[240px] d:tw-max-w-[240px]"
    [safeTranslate]="key"
  ></p>

  @if (value) {
    <p
      class="tw-flex tw-flex-col tw-gap-9 tw-break-words tw-text-white d:tw-flex-row d:tw-gap-12"
      [translate]="value"
      [translateParams]="valueParams"
    ></p>
  }
  <div class="text-white tw-flex tw-flex-col tw-gap-9 d:tw-gap-12">
    <ng-content />
  </div>
</div>
