<div>
  <msa-file-overview
    [uploadedDocuments]="request().responseData.responses"
    [titleText]="'i18n.request-overview.reply.title'"
    [titleColor]="'theme(\'colors.black.light[5.5]\')'"
    (download)="downloadResponse.emit($event)"
    data-cy="responseDocuments"
  />
</div>

<!-- show additional context information -->
<ng-content />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.recap-container.reason-title"
  data-cy="headerReasonType"
></h3>

@if (reasonTypeCode(); as reasonType) {
  <app-key-value
    [value]="reasonType.descriptionDto ? (reasonType.descriptionDto | translateObject) : 'i18n.code.not-found'"
    key="i18n.duty-relocation-form.reason.label"
    data-cy="reasonType"
  />
}

<hr class="tw-mb-30 tw-mt-30 tw-w-full tw-border-t tw-border-t-black-light-5 d:tw-mb-60 d:tw-mt-60" />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.duty-type.form.header.study"
  data-cy="headerUniversity"
></h3>

<app-key-value
  [value]="universityCode()?.descriptionDto | translateObject"
  key="i18n.duty-relocation-form.university.universityName.label"
  data-cy="fieldUniversity"
/>

<app-key-value
  [value]="departmentCode()?.descriptionDto | translateObject"
  key="i18n.duty-relocation-form.university.universityDepartment.label"
  data-cy="fieldDepartment"
/>

<app-key-value
  [value]="studyTypeText()"
  key="i18n.duty-relocation-form.university.studyType.label"
  data-cy="fieldStudyType"
/>

<app-key-value
  [value]="
    university().studyPartTime
      ? 'i18n.duty-relocation-form.university.studyPartTime.partTime'
      : 'i18n.duty-relocation-form.university.studyPartTime.fullTime'
  "
  key="i18n.duty-relocation-form.university.studyPartTime.label"
  data-cy="fieldStudyPartTime"
/>

@if (university().studyPartTime) {
  <app-key-value key="i18n.duty-relocation-form.university.studyDays.label" data-cy="fieldStudyDays">
    <div class="tw-flex tw-flex-row">
      @for (studyDay of studyDays(); track studyDay.id) {
        <p>
          @if (!$first) {
            ,
          }
          {{ studyDay.value | translate }}
        </p>
      }
    </div>
  </app-key-value>
}

<app-key-value
  [value]="(university().semesterDuration.from | date) + ' - ' + (university().semesterDuration!.to | date)"
  key="i18n.duty-relocation-form.university.semesterDuration.label"
  data-cy="fieldSemesterDuration"
/>

<app-key-value
  [value]="university().studiesEnd | date: MONTH_YEAR_FORMAT"
  key="i18n.duty-relocation-form.university.studiesEnd.label"
  data-cy="fieldStudiesEnd"
/>

<hr class="tw-mb-30 tw-mt-30 tw-w-full tw-border-t tw-border-t-black-light-5 d:tw-mb-60 d:tw-mt-60" />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.recap-container.reason-description"
  data-cy="headerRasonDescription"
></h3>

<app-key-value key="i18n.duty-relocation-form.university.schedule.label" data-cy="fieldSchedules">
  @for (schedule of university().schedules; track schedule.scheduleReason) {
    <p>{{ schedule.scheduleReason }}:&nbsp; {{ schedule.from | date }} - {{ schedule.to | date }}</p>
  }
</app-key-value>

<msa-file-overview
  [uploadedDocuments]="requestDetailsUniversity().documents"
  [titleText]="'i18n.upload-container.title'"
  [titleColor]="'theme(\'colors.black.light[5.5]\')'"
  (download)="downloadAttachment.emit($event)"
  data-cy="uploadedFiles"
/>

<app-key-value
  [value]="requestDetailsUniversity().reason"
  key="i18n.duty-relocation-form.university.remark.label"
  data-cy="fieldReason"
/>

@if (gadRequestDetails(); as details) {
  <app-gad-substitution-time-summary [requestDetails]="details" [requestId]="request().id" />
}
@if (recruitmentRequestDetails(); as details) {
  <msa-recruitment-substitution-time-summary [requestDetails]="details" [dutyId]="duty().dutyId" />
}
@if (sideProcessRequestDetails(); as details) {
  <msa-side-process-substitution-time-summary [requestDetails]="details" [dutyId]="duty().dutyId" />
}
@if (fdtRequestDetails(); as details) {
  <app-substitution-time-summary [requestDetails]="details" [duty]="duty()" />
}
