<!-- Additional context information -->
<ng-content />

@if (reasonTimeDetails(); as reasonTimeDetails) {
  <h3
    class="msa-text-field-set tw-mb-9 d:tw-mb-18"
    safeTranslate="i18n.leave.summary.reasonTimeHeader"
    data-cy="headerReasonType"
  ></h3>

  <app-key-value [value]="reasonTypeText()" key="i18n.leave.summary.reasonLabel" data-cy="reasonType" />

  <app-key-value key="i18n.leave.summary.periodLabel" data-cy="leavePeriod">
    <p class="tw-flex tw-items-center tw-gap-6">
      <span [class.tw-line-through]="divergentAcceptedPeriod()">
        {{ reasonTimeDetails.leaveFrom | date: GERMAN_DATE_TIME }} -
        {{ reasonTimeDetails.leaveTo | date: GERMAN_DATE_TIME }}
      </span>

      @if (divergentAcceptedPeriod()) {
        <msa-tooltip
          tooltipTitle="i18n.leave.overview.tooltipPeriodModifiedTitle"
          tooltipContent="i18n.leave.overview.tooltipPeriodModifiedText"
        />
      }
    </p>
    @if (divergentAcceptedPeriod() && acceptedPeriod(); as acceptedPeriod) {
      <p>{{ acceptedPeriod.from | date: GERMAN_DATE_TIME }} - {{ acceptedPeriod.to | date: GERMAN_DATE_TIME }}</p>
    }
  </app-key-value>

  <app-key-value
    [value]="reasonTimeDetails.destination"
    key="i18n.leave.summary.destinationLabel"
    data-cy="destination"
  />

  @if (reasonTimeDetails.travelOutbound; as travelDetails) {
    <app-key-value key="i18n.leave.travelOutboundLabel" data-cy="travelOutbound">
      <ng-container *ngTemplateOutlet="summaryTravelDetails; context: { travelDetails }" />
    </app-key-value>
  }

  @if (reasonTimeDetails.travelReturn; as travelDetails) {
    <app-key-value key="i18n.leave.travelReturnLabel" data-cy="travelReturn">
      <ng-container *ngTemplateOutlet="summaryTravelDetails; context: { travelDetails }" />
    </app-key-value>
  }

  <ng-template #summaryTravelDetails let-travelDetails="travelDetails">
    @switch (travelDetails.transportationType) {
      @case (TransportationType.PublicTransport) {
        <ng-container *ngTemplateOutlet="travelPublicTransport; context: { travelDetails }" />
      }
      @case (TransportationType.PrivateVehicle) {
        <ng-container *ngTemplateOutlet="travelPrivateVehicle; context: { travelDetails }" />
      }
      @case (TransportationType.Combined) {
        <ng-container *ngTemplateOutlet="travelPublicTransport; context: { travelDetails }" />
        <ng-container *ngTemplateOutlet="travelPrivateVehicle; context: { travelDetails }" />
      }
    }
  </ng-template>

  <ng-template #travelPublicTransport let-travelDetails="travelDetails">
    <p class="tw-flex tw-items-center" data-cy="publicTransport">
      {{
        "i18n.leave.summary.travelDurationPublicTransport"
          | safeTranslate
            : {
                duration: travelDetails.publicTransportDeparture + " - " + travelDetails.publicTransportArrival,
              }
      }}
    </p>
  </ng-template>

  <ng-template #travelPrivateVehicle let-travelDetails="travelDetails">
    <p class="tw-flex tw-items-center" data-cy="privateVehicle">
      {{
        "i18n.leave.summary.travelDurationPrivateVehicle"
          | safeTranslate: { duration: formatMinutesToHumanReadable(travelDetails.privateVehicleTravelTimeMinutes) }
      }}
    </p>
  </ng-template>

  <hr class="tw-mb-30 tw-mt-30 tw-w-full tw-border-t tw-border-t-black-light-5 d:tw-mb-60 d:tw-mt-60" />

  <h3
    class="msa-text-field-set tw-mb-9 d:tw-mb-18"
    safeTranslate="i18n.recap-container.reason-description"
    data-cy="headerReasonDescription"
  ></h3>

  <app-key-value [value]="requestDetails().reason" key="i18n.leave.explanationLabel" data-cy="fieldReason" />

  <msa-file-overview
    [uploadedDocuments]="requestDetails().documents"
    [titleText]="'i18n.upload-container.title'"
    [titleColor]="'theme(\'colors.black.light[5.5]\')'"
    (download)="downloadAttachment.emit($event)"
    data-cy="uploadedFiles"
  />

  <hr class="tw-mb-30 tw-mt-30 tw-text-white d:tw-mb-60" />

  <h3
    class="msa-text-field-set tw-mb-9 d:tw-mb-18"
    safeTranslate="i18n.leave.summary.grantsHeader"
    data-cy="headerGrants"
  ></h3>

  @if (requestDetails().privateVehicle) {
    <app-key-value key="i18n.leave.summary.grantPrivateVehicleLabel" data-cy="fieldGrantPrivateVehicle">
      <p class="tw-flex tw-items-center">
        <mat-icon class="me-3">check</mat-icon>
        {{ "i18n.leave.summary.grantPrivateVehicleText" | safeTranslate }}
      </p>
    </app-key-value>
  }

  @if (requestDetails().civilClothes) {
    <app-key-value key="i18n.leave.summary.grantCivilClothingLabel" data-cy="fieldGrantCivilClothing">
      <p class="tw-flex tw-items-center">
        <mat-icon class="me-3">check</mat-icon>
        {{ "i18n.leave.summary.grantCivilClothingText" | safeTranslate }}
      </p>
    </app-key-value>
  }
}
