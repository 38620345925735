@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
}

@if (request().requestType === RequestType.Reconsideration) {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<div class="tw-mt-30 d:tw-mt-60"></div>
<p class="msa-text-field-set tw-mb-30" safeTranslate="i18n.sideProcesses.substitutionDateTitle"></p>

@if (freePlacesAvailable()) {
  <p class="msa-text-small tw-mb-9" safeTranslate="i18n.sideProcesses.substitutionDateLabel"></p>

  <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
    <mat-select [formControl]="form.controls.cycleId">
      @for (substitutionDate of availableSubstitutionDates() | orderBy: "startDate"; track substitutionDate.cycleId) {
        <mat-option [value]="substitutionDate.cycleId">
          <p>{{ substitutionDate.startDate | date }} - {{ substitutionDate.endDate | date }}</p>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div class="tw-mt-30"></div>
} @else {
  <app-msa-content-notice type="Warning" contentNoticeTitle="i18n.sideProcesses.warningNoFreePlaces.title">
    <p [innerHTML]="'i18n.sideProcesses.warningNoFreePlaces.text' | safeTranslate"></p>
  </app-msa-content-notice>
}

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation
  [showBackButton]="true"
  [preventNext]="form.invalid"
  (next)="onNext()"
  (previous)="back.emit()"
/>

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
