import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {Store} from '@ngxs/store';
import {EditRequestStateSelectors} from '../../stores/selectors/edit-request-state.selectors';
import {RequestFormStateSelectors} from '../../stores/selectors/request-stepper.state.selectors';
import {RequestFormStepperComponent} from '../request-form/general/stepper/request-form-stepper.component';
import {LeaveRequestOverviewComponent} from '../request-overview/leave/leave-request-overview.component';
import {ShiftRequestOverviewComponent} from '../request-overview/shift/shift-request-overview.component';

/**
 * This component is needed to distinguish, which view (edit vs. overview)
 * to show the user
 */
@Component({
  selector: 'app-request-view-wrapper',
  templateUrl: './request-view-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ShiftRequestOverviewComponent, LeaveRequestOverviewComponent, RequestFormStepperComponent]
})
export class RequestViewWrapperComponent {
  private store = inject(Store);

  public canEdit$ = this.store.select(RequestFormStateSelectors.showFormEditMode);
  readonly request = toSignal(this.store.select(EditRequestStateSelectors.getCurrentEditingRequest));
  readonly isLeaveRequest = computed(() => {
    const request = this.request();
    if (!request) return false;
    return EditRequestStateSelectors.isLeaveDetail(request.requestDetail);
  });
}
