<div class="tw-max-w-[892px] tw-pb-60 tw-pt-60 d:tw-mx-auto d:tw-my-0 d:tw-pb-120 d:tw-pt-120" data-cy="requestForm">
  <div class="tw-absolute tw-right-[3px] tw-top-[3px]">
    <mat-icon class="!tw-size-48 tw-text-black-light-7" (click)="closeRequest()" svgIcon="close" data-cy="close" />
  </div>

  <p class="msa-text tw-mb-6 d:tw-mb-12">{{ formTitle() | translate }}</p>
  <h1 class="msa-text-h1 tw-mb-15 d:tw-mb-30">{{ selectedStepTitle() | translate }}</h1>

  <msa-stepper [selectedIndex]="currentIndex()" [linear]="true">
    @for (step of steps(); track step.component) {
      <msa-step>
        @defer (on viewport) {
          <app-step-page-wrapper
            [component]="step.component"
            [inputs]="step.inputs ?? {}"
            (back)="goBack()"
            (action)="onRequestFormAction($event)"
            (fetchResource)="fetchResource($event)"
          />
        } @placeholder {
          <div class="tw-h-screen"></div>
        }
      </msa-step>
    }
  </msa-stepper>
</div>
