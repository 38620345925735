import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {HostControlDirective} from '@shared/shared-module/directives/host-control.directive';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {MsaFormErrorDirective} from '@shared/shared-module/directives/msa-error.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {MsaButtonComponent} from '../msa-button/msa-button.component';

@Component({
  selector: 'msa-time-picker',
  templateUrl: './msa-time-picker.component.html',
  standalone: true,
  // needed to be able to style time picker dialog
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MarkRequiredDirective,
    SafeTranslatePipe,
    MsaButtonComponent,
    MsaFormErrorDirective
  ],
  hostDirectives: [HostControlDirective]
})
export class MsaTimePickerComponent {
  public label = input<TranslationString>('i18n.common.time');
  public cancelButtonText = input<TranslationString>('i18n.common.discard');
  public confirmButtonText = input<TranslationString>('i18n.common.confirm');

  public hcd = inject(HostControlDirective);

  readonly darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#282828',
      buttonColor: '#fff'
    },
    dial: {
      dialBackgroundColor: '#282828'
    },
    clockFace: {
      clockFaceBackgroundColor: '#333333',
      clockHandColor: '#50536A',
      clockFaceTimeInactiveColor: '#fff'
    }
  };
}
