import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {TranslationParams, TranslationString} from '@shared/shared-module/utils/translation.utils';

@Component({
  selector: 'app-key-value',
  templateUrl: './key-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SafeTranslateDirective, TranslateModule]
})
export class KeyValueComponent {
  @Input() key!: TranslationString;
  @Input() value!: string | null | undefined;
  @Input() valueParams!: TranslationParams;
}
