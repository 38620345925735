import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {agreementGuard} from '@shared/shared-module/guards/agreement.guard';
import {authorizationGuard} from '@shared/shared-module/guards/authorization.guard';
import {AppComponent} from './app.component';
import {hasNoUnsavedChangesGuard} from './guards/block-edit-mode-escape.guard';
import {DutyDetailsComponent} from './pages/duty-details/duty-details.component';
import {DutiesOverviewComponent} from './pages/duty-overview/duties-overview.component';
import {RequestViewWrapperComponent} from './pages/request-view-wrapper/request-view-wrapper.component';
import {RequestsComponent} from './pages/requests/requests.component';
import {RequestResolver} from './resolvers/request.resolver';
import {popupsGuard} from '@shared/shared-module/guards/popups.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [authorizationGuard(), agreementGuard(), popupsGuard],
    component: AppComponent,
    children: [
      {
        path: 'duties',
        children: [
          {
            path: '',
            component: DutiesOverviewComponent,
            pathMatch: 'full'
          },
          {
            path: ':id',
            component: DutyDetailsComponent,
            data: {hideNavigationBar: true}
          }
        ]
      },
      {
        path: 'requests',
        children: [
          {path: '', component: RequestsComponent},
          {
            path: ':type/:id',
            component: RequestViewWrapperComponent,
            canDeactivate: [hasNoUnsavedChangesGuard()],
            resolve: [RequestResolver],
            data: {hideNavigationBar: true}
          }
        ]
      },
      {path: '', redirectTo: 'duties', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModuleAdminQuery {}
