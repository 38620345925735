<div class="tw-pb-60 tw-pt-60 d:tw-pb-120 d:tw-pt-120" data-cy="requestOverview">
  <div class="tw-absolute tw-right-[3px] tw-top-[3px]">
    <mat-icon
      class="!tw-size-48 tw-text-black-light-7"
      routerLink="/admin-query/requests"
      data-cy="close"
      svgIcon="close"
    />
  </div>

  <h1 class="msa-text-h1 tw-mb-30 d:tw-mb-60" [translate]="requestTitle()"></h1>

  @if (requestState) {
    <app-key-value key="i18n.requests.headers.state">
      <div class="tw-flex tw-items-center tw-gap-12">
        <msa-status-pill
          class="tw-flex-grow-0"
          [text]="requestState.statusText"
          [backgroundClass]="requestState.backgroundColor"
        />
        @if (requestTrackingState(); as tracking) {
          <span class="msa-text-tiny tw-flex-grow-1">{{ tracking | safeTranslate }}</span>
        }
      </div>
    </app-key-value>
  }

  @if (requestSnapshot) {
    <msa-request-summary [requestId]="requestSnapshot.id" [showDutyContext]="true">
      @if (requestSnapshot.requestType !== RequestType.Reconsideration) {
        <app-duty-context data-cy="affectedDuty" />
      } @else {
        <msa-request-context
          [requestId]="requestSnapshot.id"
          [dutyId]="requestSnapshot.dutyId"
          data-cy="reconsideredRequest"
        />
      }
    </msa-request-summary>
  }

  <div class="tw-grid tw-grid-cols-1 tw-gap-15 d:tw-grid-cols-2 d:tw-gap-30">
    <div class="">
      @if (canOpenReconsideration) {
        <msa-button (click)="createReconsideration()" data-cy="openReconsideration" color="secondary"
          >{{ "i18n.requests.actions.reconsider" | safeTranslate }}
        </msa-button>
      }
    </div>

    <div class="">
      <msa-button color="primary" routerLink="/admin-query/requests"
        >{{ "i18n.common.close" | safeTranslate }}
      </msa-button>
    </div>
  </div>
</div>
