import {Inject, Injectable, Optional, computed, effect, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateAdapterOptions,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';

/**
 * This date adapter extends the MomentDateAdapter to keep the original behaviour.
 * We need this custom adapter to react on langauge changes and set the locale accordingly.
 */
@Injectable()
export class LocalizedDateAdapter extends MomentDateAdapter {
  private translateService = inject(TranslateService);

  private langChanges = toSignal(this.translateService.onLangChange, {
    initialValue: {lang: this.translateService.currentLang, translations: []}
  });
  private currentLanguage = computed(() => this.langChanges().lang);

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) options: MatMomentDateAdapterOptions
  ) {
    super(dateLocale, options);

    effect(() => {
      const language = this.currentLanguage();
      this.setLocale(language);
      moment.locale(language);
    });
  }

  override format(date: moment.Moment, displayFormat: string): string {
    return super.format(date, displayFormat);
  }
}
