export class FetchReasonTypeCodeList {
  static readonly type = '[codeList] Fetch reason type code list';
}

export class FetchUniversityNameCodeList {
  static readonly type = '[codeList] Fetch university name code list';
}
export class FetchDepartmentCodesForUniversity {
  static readonly type = '[codeList] Fetch department codes for university';

  constructor(public universityCode: string) {}
}

export class FetchFixedHashCodes {
  static readonly type = '[codeList] Fetch fixed hash codes';
}

export class ResetUniversityDepartments {
  static readonly type = '[codeList] Reset departemnts';
}
