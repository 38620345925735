import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {RemoveWhitespacePipe} from '@shared/shared-module/pipes/remove-whitespace.pipe';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {DutyDetailsDto} from '../../../core/api/generated/msa-duty-service';
import {CommanderComponent} from './commander/commander.component';

@Component({
  selector: 'msa-contacts',
  standalone: true,
  imports: [
    SafeTranslatePipe,
    MatIconModule,
    RouterLink,
    CommanderComponent,
    MsaCollapsibleComponent,
    RemoveWhitespacePipe,
    MsaArrowLinkComponent
  ],
  templateUrl: './contacts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent {
  duty = input.required<DutyDetailsDto>();
  commander = computed(() => this.duty().contacts?.commander);
  hotline = '+41 800 424 111';
}
