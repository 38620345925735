import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {DocumentInfoDto, RequestType, TrackingState} from '../../../../core/api/generated/msa-duty-service';
import {DownloadResponseDocument} from '../../../../stores/actions/edit-request.state.actions';
import {EditRequestStateSelectors} from '../../../../stores/selectors/edit-request-state.selectors';

@Component({
  selector: 'msa-request-incomplete-notice',
  templateUrl: './request-incomplete-notice.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MsaContentNoticeComponent,
    SafeTranslateDirective,
    TranslateObjectPipe,
    SafeTranslatePipe
  ]
})
export class RequestIncompleteNoticeComponent {
  private store = inject(Store);
  private requestId = this.store.selectSnapshot(EditRequestStateSelectors.slices.requestId)!;

  public incompleteNotice$ = this.store.select(EditRequestStateSelectors.getRequestIncompleteNotice);

  protected readonly MessageType = MessageType;
  public readonly TrackingState = TrackingState;

  downloadResponseDocument(document: DocumentInfoDto) {
    this.store.dispatch(new DownloadResponseDocument(this.requestId, document, RequestType.Shift));
  }
}
