import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {EditRequestStateSelectors} from '../../stores/selectors/edit-request-state.selectors';
import {GERMAN_SHORT_DATE_FORMAT} from '@shared/shared-module/utils/date-time.utils';

@Component({
  selector: 'app-duty-context',
  templateUrl: './duty-context.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateObjectPipe, SafeTranslateDirective]
})
export class DutyContextComponent {
  private store = inject(Store);
  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;

  affectedDuty = toSignal(this.store.select(EditRequestStateSelectors.getAffectedDuty), {
    initialValue: this.store.selectSnapshot(EditRequestStateSelectors.getAffectedDuty)
  });
  affectedDateRanges = computed(() => {
    return this.affectedDuty()?.dateRanges;
  });
}
