import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MsaLocalizedDatePickerModule} from '@shared/shared-module/adapters/msa-localized-date-picker.module';
import {HostControlDirective} from '@shared/shared-module/directives/host-control.directive';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {MsaFormErrorDirective} from '@shared/shared-module/directives/msa-error.directive';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {SafeTranslatePipe} from '../../pipes/safe-translate.pipe';

@Component({
  selector: 'msa-date-picker',
  templateUrl: './msa-date-picker.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MsaLocalizedDatePickerModule,
    MarkRequiredDirective,
    SafeTranslatePipe,
    MsaFormErrorDirective
  ],
  hostDirectives: [HostControlDirective]
})
export class MsaDatePickerComponent {
  public label = input<TranslationString>('i18n.common.date');

  public hcd = inject(HostControlDirective);
}
