import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import moment from 'moment';
import {DutyContextComponent} from '../../../components/duty-context/duty-context.component';
import {Action, DetailsLeaveDto, RequestState} from '../../../core/api/generated/msa-duty-service';
import {WithdrawLeaveRequest} from '../../../stores/actions/requests.state.actions';
import {EditRequestStateSelectors} from '../../../stores/selectors/edit-request-state.selectors';
import {RequestTranslationsUtils} from '../../../utils/translation.utils';
import {RequestSummaryComponent} from '../request-summary/request-summary.component';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'msa-leave-request-overview',
  templateUrl: './leave-request-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    RouterLink,
    StatusPillComponent,
    SafeTranslateModule,
    KeyValueComponent,
    RequestSummaryComponent,
    MsaContentNoticeComponent,
    MsaButtonComponent,
    DutyContextComponent
  ]
})
export class LeaveRequestOverviewComponent {
  private store = inject(Store);

  public requestSnapshot = this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest)!;
  public readonly requestState = RequestTranslationsUtils.getStatusFields(this.requestSnapshot.state);
  protected readonly requestTitle: TranslationString = 'i18n.requests.label.leave';

  readonly acceptedPeriod = computed(() => this.requestSnapshot.responseData.approvedDateTime!);
  readonly requestDetails = computed<DetailsLeaveDto>(() => this.requestSnapshot.requestDetail as DetailsLeaveDto);
  readonly divergentAcceptedPeriod = computed(() => {
    if (!this.acceptedPeriod()) return false;
    const divergingFrom = !moment(this.requestDetails().transportDetails!.leaveFrom).isSame(
      moment(this.acceptedPeriod()?.from)
    );
    const divergingTo = !moment(this.requestDetails().transportDetails!.leaveTo).isSame(
      moment(this.acceptedPeriod()?.to)
    );

    return divergingFrom || divergingTo;
  });

  readonly MessageType = MessageType;
  readonly RequestState = RequestState;

  constructor(public dialog: MatDialog) {}

  /** Returns null if the notification should not be shown at all */
  readonly notificationText = computed<TranslationString | null>(() => {
    const state = this.requestSnapshot.state;
    switch (state) {
      case RequestState.Accepted:
        return 'i18n.leave.overview.notificationAccepted';
      case RequestState.Submitted:
        return null;
      case RequestState.Rejected:
        return 'i18n.leave.overview.notificationRejected';
      default:
        throw new Error(`We should not land in overview mode with given state ${state}`);
    }
  });
  readonly showLeavePassInfo = computed(() => this.requestSnapshot.state === RequestState.Accepted);

  canWithdrawRequest(): boolean {
    return this.requestSnapshot.actions.includes(Action.WithdrawRequest) ? true : false;
  }

  withdrawRequest() {
    this.store.dispatch(new WithdrawLeaveRequest(this.requestSnapshot.id));
  }
}
