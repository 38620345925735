import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {MsaFileOverviewComponent} from '@shared/shared-module/components/msa-file-overview/msa-file-overview.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {
  formatMinutesToHumanReadable,
  GERMAN_DATE_TIME,
  MONTH_YEAR_FORMAT
} from '@shared/shared-module/utils/date-time.utils';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import moment from 'moment';
import {
  DetailsLeaveDto,
  DocumentInfoDto,
  RequestDto,
  TransportationType,
  TransportDetailsDto
} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {REASON_LEAVE_TEXTS} from 'projects/admin-query/src/app/models/leave/reason-types.model';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'app-leave-request-summary',
  templateUrl: './leave-request-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MsaFileOverviewComponent,
    MatOptionModule,
    SafeTranslatePipe,
    SafeTranslateDirective,
    KeyValueComponent,
    MsaTooltipComponent
  ]
})
export class LeaveRequestSummaryComponent {
  public request = input.required<RequestDto>();
  public showDutyContext = input(false);

  @Output()
  public downloadAttachment = new EventEmitter<DocumentInfoDto>();

  // Transformed Details
  readonly requestDetails = computed<DetailsLeaveDto>(() => this.request().requestDetail as DetailsLeaveDto);
  readonly reasonTimeDetails = computed<TransportDetailsDto>(() => this.requestDetails().transportDetails!);
  readonly reasonTypeText = computed<TranslationString>(() =>
    this.requestDetails().transportDetails?.reasonType
      ? REASON_LEAVE_TEXTS[this.requestDetails().transportDetails!.reasonType]
      : ''
  );
  readonly acceptedPeriod = computed(() => this.request().responseData.approvedDateTime!);
  readonly divergentAcceptedPeriod = computed(() => {
    if (!this.acceptedPeriod()) return false;
    const divergingFrom = !moment(this.requestDetails().transportDetails!.leaveFrom).isSame(
      moment(this.acceptedPeriod()?.from)
    );
    const divergingTo = !moment(this.requestDetails().transportDetails!.leaveTo).isSame(
      moment(this.acceptedPeriod()?.to)
    );

    return divergingFrom || divergingTo;
  });

  readonly MONTH_YEAR_FORMAT = MONTH_YEAR_FORMAT;
  readonly GERMAN_DATE_TIME = GERMAN_DATE_TIME;
  readonly TransportationType = TransportationType;
  readonly formatMinutesToHumanReadable = formatMinutesToHumanReadable;
}
