import {CdkStepper, CdkStepperModule} from '@angular/cdk/stepper';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  effect,
  input,
  QueryList,
  TemplateRef
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MsaStepComponent} from '../msa-step/msa-step.component';

@Component({
  selector: 'msa-stepper',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, CdkStepperModule],
  templateUrl: './msa-stepper.component.html',
  providers: [{provide: CdkStepper, useExisting: MsaStepperComponent}]
})
export class MsaStepperComponent extends CdkStepper {
  public headerTemplate = input<TemplateRef<unknown>>();

  // using just the steps somehow does not updates, when the steps inside the DOM change...
  override readonly steps: QueryList<MsaStepComponent> = new QueryList<MsaStepComponent>(false);
  public readonly stepList = contentChildren(MsaStepComponent);

  private updateStepLabelsEffect = effect(() => {
    this.stepList().forEach((comp, index) => {
      if (comp.labelProvided) return;

      comp.label = `${index + 1}`;

      comp.ngOnChanges();
    });
  });

  public setSelectedIndex(selectedIdx: number): void {
    if (this.linear) return;

    this.selectedIndex = selectedIdx;
  }
}
