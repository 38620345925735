@if (incompleteNotice$ | async; as incompleteNotice) {
  <div class="tw-mb-30 d:tw-mb-60">
    <app-msa-content-notice type="Info" data-cy="requestIncompleteNotice">
      <p [innerHTML]="incompleteNotice.title | safeTranslate"></p>
      <div class="tw-flex tw-flex-col tw-gap-6">
        @if (incompleteNotice.respondedAt && incompleteNotice.decisionMaker) {
          <small>
            {{
              "i18n.leave.overview.infoNotificationBy"
                | safeTranslate
                  : { person: incompleteNotice.decisionMaker | translate, date: incompleteNotice.respondedAt | date }
            }}
          </small>
        }
        @if (incompleteNotice.reason) {
          <p>{{ incompleteNotice.reason }}</p>
        }
        <ng-container *ngTemplateOutlet="responseDocuments; context: { notice: incompleteNotice }" />
      </div>
    </app-msa-content-notice>
  </div>
}

<ng-template #responseDocuments let-notice="notice">
  @if (notice.documents.length > 0) {
    <div class="tw-flex tw-flex-col">
      @for (document of notice.documents; track document.id) {
        <a
          class="msa-text-small tw-flex tw-items-center tw-gap-6 tw-underline"
          (click)="downloadResponseDocument(document)"
          role="button"
        >
          <mat-icon class="!tw-size-21 d:!tw-size-24">picture_as_pdf</mat-icon>
          <span>{{ document.name }}</span>
        </a>
      }
    </div>
  }
</ng-template>
