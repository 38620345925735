import {NgModule} from '@angular/core';

import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DATE_FORMATS} from '../config/date.config';
import {DateFormatMonthYearDirective} from '../directives/date-format.directive';
import {LocalizedDateAdapter} from './localized-date.adapter';

// these need to be included for moment locales to work
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';

/**
 * This module provides a custom implementation of a date adapter that
 * updates the locale when the language changes
 */
@NgModule({
  imports: [MatDatepickerModule, MatMomentDateModule, DateFormatMonthYearDirective],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {provide: DateAdapter, useClass: LocalizedDateAdapter, deps: [MAT_DATE_LOCALE]}
  ],
  exports: [MatDatepickerModule, MatMomentDateModule, DateFormatMonthYearDirective]
})
export class MsaLocalizedDatePickerModule {}
