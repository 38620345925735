import {effect, inject, Signal, untracked} from '@angular/core';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {combineDateAndTime} from '@shared/shared-module/utils/date-time.utils';
import {createValidationError, MsaValidators} from '@shared/shared-module/utils/validator.utils';
import {merge} from 'lodash';
import moment, {Moment} from 'moment';
import {ReasonTypeLeave, RequestDutyInfoDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {DutyStateSelectors} from 'projects/admin-query/src/app/stores/selectors/duty.state.selectors';
import {combineLatest, map} from 'rxjs';
import {TransportDetails} from './leave-transport/leave-transport.component';

export interface LeaveReasonTimeForm {
  reason: FormControl<ReasonTypeLeave | null>;
  fromDate: FormControl<Moment | null>;
  fromTime: FormControl<string>;
  toDate: FormControl<Moment | null>;
  toTime: FormControl<string>;
  destination: FormControl<string>;
  travelOutbound: FormControl<TransportDetails | null>;
  travelReturn: FormControl<TransportDetails | null>;
}

const addErrorsToControl = (control: AbstractControl, newErrors: ValidationErrors | null): void => {
  const errors = merge(control.errors, newErrors);
  control.setErrors(Object.keys(errors!).length > 0 ? errors : null);
};

const updateControlValidtyIfTouched = (control: AbstractControl): void => {
  if (control.touched) {
    control.updateValueAndValidity({onlySelf: true});
  }
};

const validateDatesInSameDetachment = (
  startDateTime: Moment,
  endDateTime: Moment,
  formGroup: LeaveReasonTimeFormGroup,
  dutyInfo: RequestDutyInfoDto | null
): void => {
  if (!dutyInfo) return;

  const dateRanges =
    dutyInfo.dateRanges?.length > 0
      ? dutyInfo.dateRanges.map(range => ({
          fromDate: range.startDate,
          fromTime: range.startTime,
          toDate: range.endDate,
          toTime: range.endTime
        }))
      : [{fromDate: dutyInfo.startDate, toDate: dutyInfo.endDate}];

  const inSomeDutyRange =
    !MsaValidators.validateDateContainedInRanges(startDateTime, dateRanges, true) &&
    !MsaValidators.validateDateContainedInRanges(endDateTime, dateRanges, true);

  if (!inSomeDutyRange) return;

  const inSameDetachment = dateRanges.some(range => {
    return (
      MsaValidators.isDateInsideRange(startDateTime, range, true) &&
      MsaValidators.isDateInsideRange(endDateTime, range, true)
    );
  });

  const notInRangeError = {notInDutyRange: createValidationError('i18n.leave.error.dateNotInDutyRange')};
  if (!inSameDetachment) {
    addErrorsToControl(formGroup.controls.fromDate, notInRangeError);
    addErrorsToControl(formGroup.controls.fromTime, notInRangeError);
    addErrorsToControl(formGroup.controls.toDate, notInRangeError);
    addErrorsToControl(formGroup.controls.toTime, notInRangeError);
  }
};

const validateDatesInDutyRange = (
  control: AbstractControl,
  dateTime: Moment,
  dutyInfo: RequestDutyInfoDto | null,
  compareTime = false
) => {
  if (!dutyInfo) return;

  const dateRangeErrors = MsaValidators.validateDateContainedInRanges(
    moment(dateTime),
    dutyInfo.dateRanges?.length > 0
      ? dutyInfo.dateRanges.map(range => ({
          fromDate: range.startDate,
          fromTime: range.startTime,
          toDate: range.endDate,
          toTime: range.endTime
        }))
      : [{fromDate: dutyInfo.startDate, toDate: dutyInfo.endDate}],
    compareTime
  );

  addErrorsToControl(control, dateRangeErrors);
};

// This class is used to easily allow cross validation between dependent form values
const LeaveDateCrossValidator: (dutyInfo: RequestDutyInfoDto) => ValidatorFn =
  (dutyInfo: RequestDutyInfoDto) => (control: AbstractControl<LeaveReasonTimeForm>) => {
    if (!(control instanceof LeaveReasonTimeFormGroup)) return null;

    const formGroup = control as LeaveReasonTimeFormGroup;

    const startDateTime = formGroup.value.fromDate
      ? combineDateAndTime(formGroup.value.fromDate, formGroup.value.fromTime, false)
      : undefined;
    const endDateTime = formGroup.value.toDate
      ? combineDateAndTime(formGroup.value.toDate, formGroup.value.toTime, false)
      : undefined;

    updateControlValidtyIfTouched(formGroup.controls.fromDate);
    updateControlValidtyIfTouched(formGroup.controls.toDate);

    // Leave end should be after start
    if (startDateTime && endDateTime) {
      formGroup.controls.toDate.setErrors(
        merge(formGroup.controls.toDate.errors, MsaValidators.isDateSameOrAfter(endDateTime, startDateTime))
      );
    }

    // Leave start should be inside duty date range
    if (startDateTime && (formGroup.value.fromDate || formGroup.value.fromTime)) {
      updateControlValidtyIfTouched(formGroup.controls.fromTime);

      validateDatesInDutyRange(formGroup.controls.fromDate, startDateTime, dutyInfo);
      validateDatesInDutyRange(formGroup.controls.fromTime, startDateTime, dutyInfo, true);
    }

    // Leave end should be inside duty date range
    if (endDateTime && (formGroup.value.toDate || formGroup.value.toTime)) {
      updateControlValidtyIfTouched(formGroup.controls.toTime);

      validateDatesInDutyRange(formGroup.controls.toDate, endDateTime, dutyInfo);
      validateDatesInDutyRange(formGroup.controls.toTime, endDateTime, dutyInfo, true);
    }

    // start and end should be in same EC
    if (startDateTime && endDateTime) {
      validateDatesInSameDetachment(startDateTime, endDateTime, formGroup, dutyInfo);
    }

    return null;
  };

export class LeaveReasonTimeFormGroup extends FormGroup<LeaveReasonTimeForm> {
  private store = inject(Store);
  private dutyInfo = toSignal(
    combineLatest([toObservable(this.dutyId), this.store.select(DutyStateSelectors.getDutyInfoByIdFn)]).pipe(
      map(([dutyId, dutyInfoByIdFn]) => dutyInfoByIdFn(dutyId))
    )
  );

  constructor(private dutyId: Signal<string>) {
    super({
      reason: new FormControl(null, {
        validators: [Validators.required]
      }),
      fromDate: new FormControl(null, {
        validators: [Validators.required]
      }),
      fromTime: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      toDate: new FormControl(null, {
        validators: [Validators.required]
      }),
      toTime: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      destination: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(3), Validators.maxLength(170)]
      }),
      travelOutbound: new FormControl(null, [Validators.required, MsaValidators.allNestedFieldsSetAndNotEmpty]),
      travelReturn: new FormControl(null, [Validators.required, MsaValidators.allNestedFieldsSetAndNotEmpty])
    });

    effect(() => {
      // makes sure that we show errors for fields
      // that already have a value
      for (const field in this.controls) {
        const control = this.get(field)!;
        if (control.value) {
          control.markAsTouched();
        }
      }
      this.removeValidators(LeaveDateCrossValidator(this.dutyInfo()!));
      this.addValidators(LeaveDateCrossValidator(this.dutyInfo()!));

      untracked(() => this.updateValueAndValidity());
    });
  }
}
