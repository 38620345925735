import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {MsaFileOverviewComponent} from '@shared/shared-module/components/msa-file-overview/msa-file-overview.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {getOtherDepartment, isOtherUniversity} from '@shared/shared-module/utils/code-utils';
import {GERMAN_DATE_TIME, MONTH_YEAR_FORMAT} from '@shared/shared-module/utils/date-time.utils';
import {findByCode, findByCodeV2} from '@shared/shared-module/utils/find.utils';
import {getStudyDayTranslation, getStudyTypeTranslation} from '@shared/shared-module/utils/translation.utils';
import {DutyContextComponent} from 'projects/admin-query/src/app/components/duty-context/duty-context.component';
import {
  CodeDto,
  DetailsShiftFdtUniversityDto,
  DetailsShiftGadUniversityDto,
  DocumentInfoDto,
  FixedCodeHashesDto,
  RequestDto,
  RequestDutyInfoDto
} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {EditRequestStateSelectors} from 'projects/admin-query/src/app/stores/selectors/edit-request-state.selectors';
import {MsaRecruitmentSubstitutionTimeSummaryComponent} from '../../recruitment/msa-recruitment-substitution-date-summary.component';
import {MsaSideProcessSubstitutionTimeSummaryComponent} from '../../side-process/msa-side-process-substitution-date-summary.component';
import {GadSubstituionTimeSummaryComponent} from '../gad-substitution-time/gad-substitution-time-summary.component';
import {SubstituionTimeSummaryComponent} from '../substitution-time/substitution-time-summary.component';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'app-shift-university-request-summary',
  templateUrl: './shift-university-request-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    TranslateObjectPipe,
    MsaFileOverviewComponent,
    DutyContextComponent,
    MatOptionModule,
    SafeTranslatePipe,
    SafeTranslateDirective,
    KeyValueComponent,
    SubstituionTimeSummaryComponent,
    GadSubstituionTimeSummaryComponent,
    MsaRecruitmentSubstitutionTimeSummaryComponent,
    MsaSideProcessSubstitutionTimeSummaryComponent
  ]
})
export class ShiftRequestUniversitySummaryComponent {
  public request = input.required<RequestDto>();
  public duty = input.required<RequestDutyInfoDto>();
  public reasonTypes = input.required<CodeDto[]>();
  public universityCodes = input.required<CodeDto[]>();
  public departmentCodes = input.required<CodeDto[]>();

  public gadRequestDetails = computed(() => {
    const details = this.request().requestDetail;
    return EditRequestStateSelectors.isGadDetail(details) ? details : undefined;
  });
  public fdtRequestDetails = computed(() => {
    const details = this.request().requestDetail;
    return EditRequestStateSelectors.isFdtDetail(details) ? details : undefined;
  });
  public recruitmentRequestDetails = computed(() => {
    const details = this.request().requestDetail;
    return EditRequestStateSelectors.isRecruitmentDetail(details) ? details : undefined;
  });
  public sideProcessRequestDetails = computed(() => {
    const details = this.request().requestDetail;
    return EditRequestStateSelectors.isSideProcessDetail(details) ? details : undefined;
  });

  public showDutyContext = input(false);

  @Output()
  public downloadAttachment = new EventEmitter<DocumentInfoDto>();

  @Output()
  public downloadResponse = new EventEmitter<DocumentInfoDto>();

  readonly requestDetailsUniversity = computed<DetailsShiftFdtUniversityDto | DetailsShiftGadUniversityDto>(() => {
    return this.request().requestDetail as DetailsShiftFdtUniversityDto | DetailsShiftGadUniversityDto;
  });
  readonly requestDetailsFdtUniversity = computed<DetailsShiftFdtUniversityDto>(
    () => this.request().requestDetail as DetailsShiftFdtUniversityDto
  );
  readonly requestDetailsGadUniversity = computed<DetailsShiftGadUniversityDto>(
    () => this.request().requestDetail as DetailsShiftGadUniversityDto
  );
  readonly university = computed(() => this.requestDetailsUniversity().university);
  readonly reasonTypeCode = computed(
    () => this.reasonTypes()?.find(findByCode(this.requestDetailsUniversity().reasonType)) ?? null
  );
  readonly studyTypeText = computed(() => getStudyTypeTranslation(this.university().studyType));
  readonly studyDays = computed(() =>
    this.requestDetailsUniversity().university.studyDays.map(d => ({value: getStudyDayTranslation(d), id: d}))
  );

  readonly universityCode = computed(() => this.universityCodes().find(findByCodeV2(this.university().university)));
  readonly departmentCode = computed(() => {
    const departmentCodeHash = this.university().universityDepartment;
    isOtherUniversity;
    if (departmentCodeHash === FixedCodeHashesDto.OtherDepartment) return getOtherDepartment();

    return this.departmentCodes().find(findByCodeV2(departmentCodeHash));
  });

  readonly MONTH_YEAR_FORMAT = MONTH_YEAR_FORMAT;
  readonly GERMAN_DATE_TIME = GERMAN_DATE_TIME;
}
