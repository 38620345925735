<hr class="tw-mb-30 tw-mt-30 tw-w-full tw-border-t tw-border-t-black-light-5 d:tw-mb-60 d:tw-mt-60" />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.duty-relocation-form.period.substitution.label"
  data-cy="headerPreventedPeriods"
></h3>

<app-key-value key="i18n.duty-relocation-form.gad.replacement-period.questionAtOnce" data-cy="preventedPeriods">
  <p class="tw-flex tw-items-center">
    <mat-icon class="me-3">{{ replacementText().icon }}</mat-icon>
    {{ replacementText().value | translate }}
  </p>
</app-key-value>

@if (!requestDetails().fraction && fraction1()) {
  <app-key-value key="i18n.duty-relocation-form.gad.replacement-period.questionWhen" data-cy="preventedPeriods">
    <p class="tw-flex tw-items-center">
      {{ fraction1()?.season | safeTranslate }} {{ requestDetails().replacementYear1 }} ,
      {{ fraction1()?.startDate | date }} - {{ fraction1()?.endDate | date }}
    </p>
  </app-key-value>
} @else {
  @if (fraction1(); as fractionOne) {
    <app-key-value
      key="i18n.duty-relocation-form.gad.replacement-period.duty-fraction-part-one"
      data-cy="preventedPeriods"
    >
      <p class="tw-flex tw-items-center">
        {{ fractionOne.season | safeTranslate }} {{ requestDetails().replacementYear1 }} ,
        {{ "i18n.common.weeks" | safeTranslate }} {{ fractionOne.startWeeks }} - {{ fractionOne.endWeeks }}
      </p>
      <p class="tw-flex tw-items-center">{{ fractionOne.startDate | date }} - {{ fractionOne.endDate | date }}</p>
    </app-key-value>
  }

  @if (fraction2(); as fractionTwo) {
    <app-key-value
      key="i18n.duty-relocation-form.gad.replacement-period.duty-fraction-part-two"
      data-cy="preventedPeriods"
    >
      <p class="tw-flex tw-items-center">
        {{ fractionTwo.season | safeTranslate }} {{ requestDetails().replacementYear2 }} ,
        {{ "i18n.common.weeks" | safeTranslate }} {{ fractionTwo.startWeeks }} - {{ fractionTwo.endWeeks }}
      </p>
      <p class="tw-flex tw-items-center">{{ fractionTwo.startDate | date }} - {{ fractionTwo.endDate | date }}</p>
    </app-key-value>
  }
}
