import {HttpResponse} from '@angular/common/http';
import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DocumentsRestService} from '../core/api/generated/msa-duty-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(
    private documentsRestService: DocumentsRestService,
    private destroyRef: DestroyRef
  ) {}

  downloadMarchingOrder(dutyId: string, documentId: string, documentName: string): Observable<HttpResponse<Blob>> {
    return this.documentsRestService
      .downloadMarchingOrder(dutyId, documentId, 'response', true, {
        httpHeaderAccept: 'application/pdf'
      })
      .pipe(
        tap(response => {
          if (response instanceof HttpResponse && response.body) {
            const blob = new Blob([response.body], {type: 'application/pdf'});
            this.downloadFile(blob, `${documentName}.pdf`);
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      );
  }

  downloadFile(blob: Blob, documentName: string): void {
    saveAs(blob, documentName);
  }
}
