import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {MsaEmptyListBoxComponent} from '@shared/shared-module/components/msa-empty-list-box/msa-empty-list-box.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {PendingRequestDto} from '../../../core/api/generated/msa-duty-service';
import {RequestTranslationsUtils} from '../../../utils/translation.utils';

@Component({
  selector: 'msa-pending-request-component',
  standalone: true,
  imports: [
    SafeTranslateDirective,
    SafeTranslatePipe,
    DatePipe,
    RouterLink,
    MsaCollapsibleComponent,
    StatusPillComponent,
    MsaEmptyListBoxComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pending-requests.component.html'
})
export class PendingRequestsComponent {
  public requests = input.required<PendingRequestDto[]>();

  public pendingRequestViews = computed(() =>
    this.requests().map((r: PendingRequestDto) => ({
      id: r.id,
      requestType: r.requestType,
      requestTypeText: RequestTranslationsUtils.getTypeText(r.requestType),
      ...RequestTranslationsUtils.getStatusFields(r.state),
      submittedAt: r.submittedAt
    }))
  );
}
