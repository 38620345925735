import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, DestroyRef, inject, input} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaDatePickerComponent} from '@shared/shared-module/components/msa-date-picker/msa-date-picker.component';
import {MsaFileUploadComponent} from '@shared/shared-module/components/msa-file-upload/msa-file-upload.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {MsaTimePickerComponent} from '@shared/shared-module/components/msa-time-picker/msa-time-picker.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {isEmpty, pickBy} from 'lodash';
import {distinctUntilChanged, identity, tap} from 'rxjs';

export type PublicTransportFormGroup = {
  departure: FormControl<string | null>;
  arrival: FormControl<string | null>;
};

export type PrivateVehicleFormGroup = {
  hours: FormControl<number | null>;
  minutes: FormControl<number | null>;
};

export type LeaveTransportForm = {
  usingPublicTransport: FormControl<boolean>;
  usingPrivateVehicle: FormControl<boolean>;
  publicTransport?: FormGroup<PublicTransportFormGroup>;
  privateVehicle?: FormGroup<PrivateVehicleFormGroup>;
};

export type TransportDetails = {
  publicTransport?: {
    departure: string | null;
    arrival: string | null;
  };
  privateVehicle?: {
    hours: number | null;
    minutes: number | null;
  };
};

@Component({
  selector: 'app-leave-transport',
  templateUrl: './leave-transport.component.html',
  styleUrl: './leave-transport.component.css',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LeaveTransportComponent,
      multi: true
    }
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    FormStepperNavigationComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    MsaFileUploadComponent,
    MsaSaveDraftComponent,
    SafeTranslateDirective,
    MsaTimePickerComponent,
    MsaDatePickerComponent,
    SafeTranslatePipe,
    MsaCheckboxComponent
  ]
})
export class LeaveTransportComponent implements ControlValueAccessor {
  public label = input.required<TranslationString>();

  private destroyRef = inject(DestroyRef);

  form = new FormGroup<LeaveTransportForm>({
    usingPrivateVehicle: new FormControl(false, {nonNullable: true}),
    usingPublicTransport: new FormControl(false, {nonNullable: true})
  });
  uniqueId = crypto.randomUUID().slice(0, 8);

  registerOnChange(changeFn: (output: Partial<TransportDetails> | null) => void): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(values => {
          // remove undefined values from formChanges
          const formChanges = pickBy(
            {
              privateVehicle: values.privateVehicle
                ? {
                    hours: values.privateVehicle.hours ?? null,
                    minutes: values.privateVehicle.minutes ?? null
                  }
                : undefined,
              publicTransport: values.publicTransport
                ? {
                    arrival: values.publicTransport?.arrival ?? null,
                    departure: values.publicTransport.departure ?? null
                  }
                : undefined
            },
            identity
          );

          changeFn(!isEmpty(formChanges) ? formChanges : null);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  writeValue(travelDetails: TransportDetails | null): void {
    if (!travelDetails) return;

    if (isDefined(travelDetails.privateVehicle)) {
      this.togglePrivateVehicle(true);
      this.form.patchValue({usingPrivateVehicle: true, privateVehicle: travelDetails.privateVehicle});
    }

    if (isDefined(travelDetails.publicTransport)) {
      this.togglePublicTransport(true);
      this.form.patchValue({
        usingPublicTransport: true,
        publicTransport: {
          departure: travelDetails.publicTransport.departure,
          arrival: travelDetails.publicTransport.arrival
        }
      });
    }
  }

  registerOnTouched(_fn: unknown): void {
    //ignore
  }

  togglePublicTransport(checked: boolean): void {
    if (checked) {
      this.form.addControl(
        'publicTransport',
        new FormGroup<PublicTransportFormGroup>(
          {
            departure: new FormControl(null, Validators.required),
            arrival: new FormControl(null, Validators.required)
          },
          Validators.required
        )
      );
    } else {
      this.form.removeControl('publicTransport');
    }
  }

  togglePrivateVehicle(checked: boolean): void {
    if (checked) {
      this.form.addControl(
        'privateVehicle',
        new FormGroup<PrivateVehicleFormGroup>(
          {
            hours: new FormControl(null, Validators.required),
            minutes: new FormControl(null, Validators.required)
          },
          Validators.required
        )
      );
    } else {
      this.form.removeControl('privateVehicle');
    }
  }
}
