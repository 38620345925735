<button
  class="tw-absolute tw-right-0 tw-top-0 tw-size-[42px] !tw-bg-navy hover:!tw-bg-navy-dark-1 disabled:!tw-bg-navy-dark-4 d:tw-relative"
  [matMenuTriggerFor]="menu"
  [disabled]="actions().length === 0"
  (click)="$event.stopPropagation()"
  data-cy="openMenu"
  mat-icon-button
>
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">
  @for (action of actions(); track action.id) {
    <button (click)="onClick(action)" mat-menu-item>{{ action.text | safeTranslate }}</button>
  }
</mat-menu>
