import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {Action, State, StateContext} from '@ngxs/store';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {MsaDialogAction, MsaDialogData} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {MsaDialogService} from '@shared/shared-module/services/msa-dialog.service';
import {EMPTY, Observable, catchError, switchMap, take, tap, throwError} from 'rxjs';
import {
  LeaveRequestRestService,
  RequestDto,
  RequestType,
  RequestsRestService,
  ShiftRequestRestService
} from '../../core/api/generated/msa-duty-service';
import {UpdateUserFormData} from '../actions/edit-request.state.actions';
import {
  DeleteRequest,
  FetchAllRequests,
  FetchRequest,
  UpdateRequest,
  WithdrawLeaveRequest
} from '../actions/requests.state.actions';
import {EditRequestStateModel} from '../models/edit-request-state.model';
import {RequestsStateModel} from '../models/requests-state.model';

@State<RequestsStateModel>({
  name: 'requests',
  defaults: {
    requests: [],
    errorMessage: null
  }
})
@Injectable()
export class RequestsState {
  constructor(
    private requestRestService: RequestsRestService,
    private shiftRequestRestService: ShiftRequestRestService,
    private leaveRequestRestService: LeaveRequestRestService,
    private router: Router,
    private destroyRef: DestroyRef,
    private snackbarService: SnackbarService,
    private msaDialogService: MsaDialogService,
    private errorMessageHandler: ErrorMessageHandler
  ) {}

  @Action(FetchRequest)
  loadRequest(ctx: StateContext<RequestsStateModel>, {requestId, requestType}: FetchRequest): Observable<void> {
    let fetchStream!: Observable<HttpResponse<RequestDto>>;
    switch (requestType) {
      case RequestType.Reconsideration:
      case RequestType.Shift:
        fetchStream = this.shiftRequestRestService.fetchShiftRequest(requestId, 'response');
        break;
      case RequestType.Leave:
        fetchStream = this.leaveRequestRestService.fetchLeaveRequest(requestId, 'response');
        break;
      default:
        throw new Error(`Unknown requestType `);
    }

    return fetchStream.pipe(
      take(1),
      tap(response => RequestsState.updateRequests(ctx, response.body!)),
      switchMap(response => {
        if (response.ok) {
          const request = response.body;
          return request && request.reconsidered
            ? ctx.dispatch(new FetchRequest(request.reconsidered, RequestType.Reconsideration))
            : EMPTY;
        } else {
          return throwError(
            () =>
              new HttpErrorResponse({
                error: response.body,
                status: response.status,
                statusText: response.statusText
              })
          );
        }
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          return this.errorMessageHandler.logAndIgnore(error);
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchAllRequests)
  updateShiftRequests(ctx: StateContext<RequestsStateModel>): Observable<RequestDto[]> {
    return this.requestRestService.getAllRequests().pipe(
      catchError(error => {
        this.handleInlineError(error, ctx, 'i18n.requests.error.load');
        ctx.patchState({errorMessage: 'i18n.requests.error.load'});
        return this.errorMessageHandler.logAndIgnore(error, 'i18n.requests.error.load');
      }),
      tap(requests => ctx.patchState({requests, errorMessage: null})),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(WithdrawLeaveRequest)
  withdrawRequest(ctx: StateContext<EditRequestStateModel>, {requestId}: WithdrawLeaveRequest) {
    this.openWithdrawDialog$()
      .pipe(
        switchMap(dialogAction => {
          if (dialogAction === MsaDialogAction.CANCEL) {
            return EMPTY;
          }
          return this.leaveRequestRestService.withdrawRequest(requestId);
        }),
        tap(requestDto => {
          this.msaDialogService.closeCurrentDialog();
          ctx.dispatch(new UpdateRequest(requestDto));

          this.snackbarService.openSnackbar({
            text: 'i18n.leave.withdraw.success',
            type: MessageType.Success
          });

          this.router.navigateByUrl('/admin-query/requests');
        }),

        catchError(error => {
          this.snackbarService.openSnackbar({
            text: 'i18n.common.error.generic',
            type: MessageType.Error
          });
          this.errorMessageHandler.logAndIgnore(error);
          return error;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  @Action(DeleteRequest)
  deleteRequest(ctx: StateContext<RequestsStateModel>, {requestId, s3BucketType}: DeleteRequest): Observable<void> {
    const {requests} = ctx.getState();
    if (requests == null) {
      throw new Error(`No request in state context!`);
    }
    return this.requestRestService.deleteRequestById(requestId, s3BucketType).pipe(
      take(1),
      // removal has influence on other present requests
      tap(() => ctx.dispatch(new FetchAllRequests())),
      tap(() => {
        ctx.patchState({
          requests: requests.filter(r => r.id != requestId)
        });
      }),
      tap(() =>
        this.snackbarService.openSnackbar({
          text: 'i18n.delete.request.success',
          type: MessageType.Success
        })
      ),
      catchError(response => this.errorMessageHandler.logAndIgnore(response)),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(UpdateRequest)
  updateRequest(ctx: StateContext<RequestsStateModel>, {request}: UpdateRequest) {
    RequestsState.updateRequests(ctx, request);
    ctx.dispatch(new UpdateUserFormData(request.requestDetail));
  }

  private static updateRequests(ctx: StateContext<RequestsStateModel>, request: RequestDto) {
    const presentRequests = ctx.getState().requests;
    ctx.patchState({
      requests: [...presentRequests.filter(r => r.id !== request.id), request]
    });
  }

  private handleInlineError(
    error: HttpErrorResponse,
    ctx: StateContext<RequestsStateModel>,
    errorMessage: string
  ): Observable<RequestDto[]> {
    ctx.patchState({errorMessage});
    return throwError(() => error);
  }

  private openWithdrawDialog$(): Observable<MsaDialogAction> {
    const dialogData: MsaDialogData = {
      title: 'i18n.leave.withdraw.title',
      message: 'i18n.leave.withdraw.question',
      confirmButtonText: 'i18n.leave.withdraw.doWithdraw',
      cypressTag: 'withdrawRequestDialog'
    };

    return this.msaDialogService.openConfirmationDialog(dialogData);
  }
}
