import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input, OnInit} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, ɵValue} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {DateRange} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {DEFAULT_DEBOUNCE_TIME_MS} from '@shared/shared-module/config/rxjs.config';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {ENGLISH_SHORT_DATE_FORMAT, formatDate} from '@shared/shared-module/utils/date-time.utils';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {MsaValidators} from '@shared/shared-module/utils/validator.utils';
import {RequestDutyInfoDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {debounceTime, tap} from 'rxjs';
import {MsaButtonComponent} from '../../../../../../../../src/app/shared-module/components/msa-button/msa-button.component';
import {MsaRadiobuttonComponent} from '../../../../../../../../src/app/shared-module/components/msa-radiobutton/msa-radiobutton.component';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {
  DateRangeDto,
  DetailsShiftFdtDto,
  DisplacementTime,
  Language,
  PreventedPeriodDto,
  RequestType
} from '../../../../core/api/generated/msa-duty-service';
import {MsaDateRangeComponent} from '../../general/date-range/date-range.component';
import {RequestIncompleteNoticeComponent} from '../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

interface SubstitutionPeriodForm {
  displacementTimeOption: FormControl<DisplacementTime | null>;
  preventedPeriods: FormArray<FormControl<DateRange<Date> | null>>;
  languages?: FormGroup<LanguagesFormGroup>;
}

interface LanguagesFormGroup {
  de: FormControl<boolean>;
  it: FormControl<boolean>;
  fr: FormControl<boolean>;
}

@Component({
  selector: 'msa-step-substitution-period',
  templateUrl: './step-displacement-time.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    RequestIncompleteNoticeComponent,
    MatButtonModule,
    MatIconModule,
    MsaContentNoticeComponent,
    MsaDateRangeComponent,
    MsaSaveDraftComponent,
    RequestContextComponent,
    SafeTranslatePipe,
    SafeTranslateDirective,
    MsaCheckboxComponent,
    MsaRadiobuttonComponent,
    MsaButtonComponent
  ]
})
export class StepDisplacementTimeComponent extends StepEditRequestComponent implements OnInit {
  public duty = input.required<RequestDutyInfoDto>();
  public isGADB = input.required<boolean>();

  form = new FormGroup<SubstitutionPeriodForm>({
    displacementTimeOption: new FormControl(null, {
      validators: [Validators.required]
    }),
    preventedPeriods: new FormArray<FormControl>([])
  });

  protected readonly DisplacementTime = DisplacementTime;
  protected readonly MessageType = MessageType;
  protected readonly RequestType = RequestType;

  readonly affectedDateRange = computed(() => ({
    from: formatDate(this.duty().startDate),
    to: formatDate(this.duty().endDate)
  }));

  readonly dutyYear = computed(() => Number(this.duty().startDate.split('-')[0]));
  readonly formValue = toSignal(this.form.valueChanges, {initialValue: this.form.value});
  readonly showCompensationMessage = computed(
    () => this.formValue().displacementTimeOption === DisplacementTime.NotThisYear && !this.isGADB()
  );

  constructor() {
    super();
    this.form.valueChanges
      .pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME_MS),
        tap(() => this.action.emit({type: 'updateUserFormData', payload: this.getUpdatedPreventedPeriods()})),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  ngOnInit() {
    const shiftRequestDetails = this.request().requestDetail as DetailsShiftFdtDto;

    // take over values from existing request
    const preventedPeriods = shiftRequestDetails.preventedPeriods;
    const displacementTime = shiftRequestDetails.displacementTime;

    this.form.controls.displacementTimeOption.patchValue(displacementTime ?? null, {emitEvent: false, onlySelf: true});
    this.toggleRequiredValidatorDutyRanges(displacementTime);

    preventedPeriods
      ?.map(range => new DateRange(range.from ? new Date(range.from) : null, range.to ? new Date(range.to) : null))
      .forEach(range => this.addNewPreventedPeriodsForm(range));

    if (!this.isGADB()) {
      this.form.addControl(
        'languages',
        new FormGroup<LanguagesFormGroup>(
          {
            de: new FormControl<boolean>(false, {nonNullable: true}),
            fr: new FormControl<boolean>(false, {nonNullable: true}),
            it: new FormControl<boolean>(false, {nonNullable: true})
          },
          [Validators.required, MsaValidators.atLeastOneSelectedValidator]
        )
      );
      this.updateLanguageCheckboxValues(shiftRequestDetails.language);
    }
  }

  onSubstitutionTypeChange(displacementType: DisplacementTime): void {
    this.form.controls.preventedPeriods.clear();
    this.toggleRequiredValidatorDutyRanges(displacementType);
    if (displacementType === DisplacementTime.Partially) {
      // add a default element
      this.addNewPreventedPeriodsForm();
    }
  }

  removeSubstitutionPeriod(removeAtIndex: number): void {
    this.form.controls.preventedPeriods.removeAt(removeAtIndex);
  }

  addNewPreventedPeriodsForm(dateRange?: DateRange<Date>): void {
    this.form.controls.preventedPeriods.push(new FormControl(dateRange ?? null, [Validators.required]));
  }

  onNext(): void {
    this.action.emit({type: 'updatePreventedPeriods', payload: this.getUpdatedPreventedPeriods()});
  }

  onSaveClick(): void {
    this.action.emit({type: 'saveDraft'});
  }

  private getUpdatedPreventedPeriods(): PreventedPeriodDto {
    return {
      preventedPeriods: this.getPreventedPeriods(this.form.value.preventedPeriods!)!,
      displacementTime: this.form.value.displacementTimeOption!,
      language: this.getUpdatedLanguages(this.form.value.languages!)
    };
  }

  private getUpdatedLanguages(languages: ɵValue<FormGroup<LanguagesFormGroup>>) {
    const languageValues: {[lang in Language]: boolean} = {
      [Language.De]: languages?.de ?? false,
      [Language.Fr]: languages?.fr ?? false,
      [Language.It]: languages?.it ?? false
    };
    return Object.entries(languageValues)
      .filter(([, value]) => value)
      .map(([lang]) => lang as Language);
  }

  private getPreventedPeriods(preventedPeriods: (DateRange<Date> | null)[]) {
    return preventedPeriods.filter(isDefined).map<DateRangeDto>(range => ({
      from: formatDate(range.start, ENGLISH_SHORT_DATE_FORMAT),
      to: formatDate(range.end, ENGLISH_SHORT_DATE_FORMAT)
    }));
  }

  private toggleRequiredValidatorDutyRanges(substitutionType: DisplacementTime | undefined): void {
    if (!substitutionType) return;

    this.form.controls.preventedPeriods.removeValidators(Validators.required);

    if (substitutionType === DisplacementTime.Partially) {
      this.form.controls.preventedPeriods.addValidators(Validators.required);
    }
    this.form.controls.preventedPeriods.updateValueAndValidity();
  }

  private updateLanguageCheckboxValues(languages: Language[]): void {
    this.form.controls.languages?.controls.de.patchValue(languages.some(it => it === Language.De));
    this.form.controls.languages?.controls.fr.patchValue(languages.some(it => it === Language.Fr));
    this.form.controls.languages?.controls.it.patchValue(languages.some(it => it === Language.It));
  }
}
