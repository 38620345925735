import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {EditRequestState} from './stores/states/edit-request.state';
import {CodeListState} from './stores/states/code-list.state';
import {DutyState} from './stores/states/duty.state';
import {RequestsState} from './stores/states/requests.state';

@NgModule({
  imports: [NgxsModule.forFeature([DutyState, EditRequestState, CodeListState, RequestsState])],
  exports: [NgxsModule]
})
export class AppStoreModule {}
