import {CodeDtoV2, FixedCodeHashesDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';

export function isOtherUniversity(code: string | undefined): boolean {
  return code === FixedCodeHashesDto.OtherUniversity;
}

export function getOtherDepartment(): CodeDtoV2 {
  return {
    codeHash: FixedCodeHashesDto.OtherDepartment,
    descriptionDto: {
      de: 'i18n.duty-relocation-form.university.other-department',
      fr: 'i18n.duty-relocation-form.university.other-department',
      it: 'i18n.duty-relocation-form.university.other-department'
    }
  };
}

export function getOtherUniversity(): CodeDtoV2 {
  return {
    codeHash: FixedCodeHashesDto.OtherUniversity,
    descriptionDto: {
      de: 'i18n.duty-relocation-form.university.other-university',
      fr: 'i18n.duty-relocation-form.university.other-university',
      it: 'i18n.duty-relocation-form.university.other-university'
    }
  };
}
