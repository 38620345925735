import {NgModule} from '@angular/core';

import {HttpClientModule} from '@angular/common/http';
import {environment} from '../../../../src/environments/environment';
import {AppRoutingModuleAdminQuery} from './app-routing.module';
import {AppStoreModule} from './app-store.module';
import {AppComponent} from './app.component';
import {MsaAdminQueryConfiguration, MsaAdminQueryConfigurationParameters} from './core/api/generated/msa-admin-query';
import {
  MsaDutyServiceConfiguration,
  MsaDutyServiceConfigurationParameters
} from './core/api/generated/msa-duty-service';
import {RequestsModule} from './pages/requests/requests.module';

export function msaAdminQueryConfigurationFactory(): MsaAdminQueryConfiguration {
  const params: MsaAdminQueryConfigurationParameters = {
    basePath: environment.api.adminQueryUrl
  };
  return new MsaAdminQueryConfiguration(params);
}

export function msaDutyServiceConfigurationFactory(): MsaDutyServiceConfiguration {
  const params: MsaDutyServiceConfigurationParameters = {
    basePath: environment.api.dutyServiceUrl
  };
  return new MsaDutyServiceConfiguration(params);
}

@NgModule({
  imports: [AppComponent, AppStoreModule, AppRoutingModuleAdminQuery, HttpClientModule, RequestsModule],
  exports: []
})
export class AppModule {}
