<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
} @else {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<form [formGroup]="form" [attr.data-cy]="'universityForm'">
  <div>
    <p
      class="msa-text-field-set tw-mb-9 d:tw-mb-18"
      safeTranslate="i18n.duty-relocation-form.university.school.label"
    ></p>

    <label
      class="msa-text tw-mb-6 tw-inline-block"
      [appMarkRequired]="form.controls.university"
      safeTranslate="i18n.duty-relocation-form.university.universityName.label"
    ></label>

    <!-- only show and init if universities are here -->
    @if (universities().length > 0) {
      <mat-form-field class="tw-w-full" appearance="fill" subscriptSizing="dynamic">
        <input
          class="msa-input-field"
          [formControl]="form.controls.university"
          [matAutocomplete]="auto"
          type="text"
          matInput
        />

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          @for (university of filteredUniversityNames(); track university.codeHash) {
            <mat-option [value]="university.codeHash"
              >{{
                university.descriptionDto
                  ? (university.descriptionDto | translateObject | translate)
                  : ("i18n.code.not-found" | translate)
              }}
            </mat-option>
          }
        </mat-autocomplete>

        @if (form.controls.university.value && form.controls.university.enabled) {
          <button (click)="clearField()" data-cy="clearField" type="button" mat-icon-button matSuffix>
            <mat-icon>clear</mat-icon>
          </button>
        }

        <button data-cy="search" mat-icon-button matSuffix type="button">
          <mat-icon>search</mat-icon>
        </button>
        <mat-hint align="start" safeTranslate="i18n.duty-relocation-form.university.universityName.hint" />
      </mat-form-field>
    }

    <div class="tw-my-9"></div>

    <label
      class="msa-text tw-mb-6 tw-inline-block"
      [appMarkRequired]="form.controls.universityDepartment"
      safeTranslate="i18n.duty-relocation-form.university.universityDepartment.label"
    ></label>
    <mat-form-field class="tw-w-full">
      <mat-select [formControl]="form.controls.universityDepartment">
        @for (universityDepartment of universityDepartments(); track universityDepartment.codeHash) {
          <mat-option [value]="universityDepartment.codeHash"
            >{{
              universityDepartment.descriptionDto
                ? (universityDepartment.descriptionDto | translateObject | translate)
                : ("i18n.code.not-found" | translate)
            }}
          </mat-option>
        }
      </mat-select>
      <mat-hint align="start" safeTranslate="i18n.duty-relocation-form.university.universityDepartment.hint" />
    </mat-form-field>

    <div class="tw-my-15 d:tw-my-30"></div>

    <p
      class="msa-text-field-set tw-mb-9 d:tw-mb-18"
      safeTranslate="i18n.duty-relocation-form.university.details.label"
    ></p>

    <label
      class="msa-text tw-mb-6 tw-inline-block"
      [appMarkRequired]="form.controls.studyType"
      safeTranslate="i18n.duty-relocation-form.university.studyType.label"
    ></label>
    <mat-form-field class="tw-w-full">
      <mat-select [formControl]="form.controls.studyType">
        @for (studyType of StudyType | keyvalue; track studyType.key) {
          <mat-option [value]="studyType.key">
            {{ "i18n.duty-relocation-form.university.studyType." + studyType.key | translate }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <label
      class="msa-text tw-mb-6 tw-inline-block"
      [appMarkRequired]="form.controls.studyPartTime"
      safeTranslate="i18n.duty-relocation-form.university.studyPartTime.label"
    ></label>
    <mat-form-field class="tw-w-full">
      <mat-select [formControl]="form.controls.studyPartTime">
        <mat-option [value]="false"
          >{{ "i18n.duty-relocation-form.university.studyPartTime.fullTime" | translate }}
        </mat-option>
        <mat-option [value]="true"
          >{{ "i18n.duty-relocation-form.university.studyPartTime.partTime" | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    @if (form.controls.studyPartTime.value && form.controls.studyDays) {
      <div [formGroup]="form.controls.studyDays">
        <label
          class="msa-text tw-mb-12 tw-inline-block"
          [appMarkRequired]="form.controls.studyDays"
          safeTranslate="i18n.duty-relocation-form.university.studyDays.label"
        ></label>

        <div class="tw-flex tw-flex-row tw-gap-12">
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.monday" key="mon"
              >{{ "i18n.duty-relocation-form.university.day1.monday-short.label" | translate }}
            </msa-checkbox>
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.tuesday" key="tue"
              >{{ "i18n.duty-relocation-form.university.day2.tuesday-short.label" | translate }}
            </msa-checkbox>
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.wednesday" key="wed"
              >{{ "i18n.duty-relocation-form.university.day3.wednesday-short.label" | translate }}
            </msa-checkbox>
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.thursday" key="thu"
              >{{ "i18n.duty-relocation-form.university.day4.thursday-short.label" | translate }}
            </msa-checkbox>
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.friday" key="fri"
              >{{ "i18n.duty-relocation-form.university.day5.friday-short.label" | translate }}
            </msa-checkbox>
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox
              [formControl]="form.controls.studyDays.controls.saturday"
              key="sat"
              label="i18n.duty-relocation-form.university.day6.saturday-short.label"
            />
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.sunday" key="sun"
              >{{ "i18n.duty-relocation-form.university.day7.sunday-short.label" | translate }}
            </msa-checkbox>
          </div>
          <div class="study-days-checkbox">
            <msa-checkbox [formControl]="form.controls.studyDays.controls.irregular" key="irr"
              >{{ "i18n.duty-relocation-form.university.day8.irregular.label" | translate }}
            </msa-checkbox>
          </div>
        </div>
      </div>
    }

    <div class="tw-mt-9 d:tw-mt-18"></div>

    <msa-date-range
      [formControl]="form.controls.semesterDuration"
      [label]="'i18n.duty-relocation-form.university.semesterDuration.label' | translate"
      [showDelete]="false"
      [hint]="'i18n.duty-relocation-form.university.semesterDuration.hint' | translate"
      dynamicClass="tw-mt-6"
    />

    <div class="tw-mt-9 d:tw-mt-18"></div>

    <label
      class="msa-text tw-mb-6 tw-inline-block"
      [appMarkRequired]="form.controls.studiesEnd"
      safeTranslate="i18n.duty-relocation-form.university.studiesEnd.label"
    ></label>
    <mat-form-field class="tw-w-full" appDateFormatMonthYear subscriptSizing="dynamic">
      <input [matDatepicker]="picker" [formControl]="form.controls.studiesEnd" (focus)="picker.open()" matInput />
      <mat-datepicker-toggle [for]="picker" matIconSuffix />
      <mat-datepicker
        #picker
        (monthSelected)="setStudyEnd($event, picker)"
        touchUi
        startView="multi-year"
        matIconSuffix
      />

      @if (form.controls.studiesEnd.errors) {
        <mat-error>
          {{ "i18n.common.error.dateMustBeInFuture" | safeTranslate }}
        </mat-error>
      }
    </mat-form-field>
  </div>
</form>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation [preventNext]="form.invalid" (next)="onNext()" (previous)="back.emit()" />

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
