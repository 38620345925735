<msa-collapsible title="i18n.duty.details.marchingOrders">
  @if (!doNotMarch()) {
    <div class="msa-text tw-grid tw-grid-cols-1 d:tw-grid-cols-3">
      <!-- Body -->
      @for (detachment of duty().detachmentCode | orderBy: "startDate"; track detachment.order) {
        @if ($first) {
          <!-- Header -->
          <span
            class="tw-col-span-1 tw-hidden tw-pb-18 tw-pt-18 tw-text-black-light-5.5 d:tw-block"
            safeTranslate="i18n.duties.headers.timePeriod"
          ></span>
          <span
            class="tw-col-span-2 tw-hidden tw-pb-18 tw-pt-18 tw-text-black-light-5.5 d:tw-block"
            safeTranslate="i18n.duties.headers.marchingOrder.title"
          ></span>
          <div class="tw-col-span-3 tw-mb-18 tw-hidden tw-border-b tw-border-b-black-light-3 d:tw-block"></div>
        }

        <p
          class="tw-mb-9 tw-block tw-text-black-light-5 d:tw-hidden"
          safeTranslate="i18n.duties.headers.timePeriod"
        ></p>
        <span class="msa-text tw-mb-18 tw-text-white d:tw-mb-0">
          {{ detachment.startDate | date }} - {{ detachment.endDate | date }}
        </span>

        <p
          class="tw-mb-9 tw-block tw-text-black-light-5 d:tw-hidden"
          safeTranslate="i18n.duties.headers.marchingOrder.title"
        >
          :
        </p>
        <msa-marching-order-document
          class="d:tw-col-span-2"
          [marchingOrderDoc]="detachment.marchingOrder!"
          [dutyId]="duty().dutyId"
        />
        <div class="tw-mb-18 tw-mt-18 tw-border-t tw-border-t-black-light-3 d:tw-col-span-3"></div>
      } @empty {
        <msa-empty-list-box
          class="tw-col-span-full"
          message="i18n.duties.headers.marchingOrder.flyouts.notYetIssued.title"
        />
      }
    </div>

    <div class="tw-mt-18"></div>
    @if (hasDigitalMarchingOrder()) {
      <app-msa-content-notice class="tw-hidden d:tw-block">
        <p [innerHTML]="'i18n.duty.details.importMarchingOrderToWalletInfo' | safeTranslate"></p>
        <qrcode
          [qrdata]="walletTriggerLink"
          [allowEmptyString]="false"
          [scale]="1"
          [width]="150"
          [margin]="1"
          cssClass="tw-w-[150px]"
          elementType="img"
          errorCorrectionLevel="M"
          noticeImage
        />
      </app-msa-content-notice>

      <div class="tw-flex tw-items-center tw-gap-9 d:tw-hidden">
        <msa-button class="tw-w-full" (click)="openWallet()" color="secondary"
          >{{ "i18n.duty.details.importMarchingOrderToWalletButton" | safeTranslate }}
        </msa-button>
        <msa-tooltip
          tooltipTitle="i18n.duty.details.toolTips.dimWallet.title"
          tooltipContent="i18n.duty.details.toolTips.dimWallet.text"
        />
      </div>
    }
  } @else {
    <app-msa-content-notice type="Warning" contentNoticeTitle="i18n.duties.headers.marchingOrder.states.doNotMarch">
      <p safeTranslate="i18n.duties.headers.marchingOrder.flyouts.doNotMarch.text"></p>
    </app-msa-content-notice>
  }
</msa-collapsible>
