@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
}

@if (request().requestType === RequestType.Reconsideration) {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<div class="tw-mt-30 d:tw-mt-60"></div>

<p class="msa-text-field-set" safeTranslate="i18n.recruitment.desiredMomentTitle"></p>

<p class="msa-text tw-mt-12" safeTranslate="i18n.recruitment.desiredMomentDisclaimer"></p>

<p class="msa-text-small tw-mb-9 tw-mt-30" safeTranslate="i18n.recruitment.desiredMomentLabel"></p>

<mat-form-field class="tw-w-full" subscriptSizing="dynamic">
  <mat-select [formControl]="form.controls.desiredMomentOfRecruitSchool">
    @for (
      recruitSchoolOption of availableRecruitSchoolOptions() | orderBy: "startDate";
      track recruitSchoolOption.startDate
    ) {
      <mat-option [value]="recruitSchoolOption.startDate">
        <p>
          {{ getSeasonTranslation(recruitSchoolOption.seasonDto) | safeTranslate }}
          {{
            "i18n.wzp.schoolText"
              | safeTranslate
                : {
                    rsLabel: "",
                    year: recruitSchoolOption.year,
                    start: recruitSchoolOption.startDate | date,
                    end: recruitSchoolOption.endDate | date,
                  }
          }}
        </p>
      </mat-option>
    }
  </mat-select>
</mat-form-field>

<div class="tw-mt-30 d:tw-mt-60"></div>

<p class="msa-text-field-set tw-mb-30" safeTranslate="i18n.recruitment.substitutionDateTitle"></p>

@if (freePZPPlacesAvailable()) {
  <p class="msa-text-small tw-mb-9" safeTranslate="i18n.recruitment.substitutionDateLabel"></p>

  <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
    <mat-select [formControl]="form.controls.cycleId">
      @for (substitutionDate of availableSubstitutionDates() | orderBy: "startDate"; track substitutionDate.cycleId) {
        <mat-option [value]="substitutionDate.cycleId">
          <p>{{ substitutionDate.startDate | date }} - {{ substitutionDate.endDate | date }}</p>
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <div class="tw-mt-30"></div>
  @if (isCurrentCycle()) {
    <div class="tw-mt-12 d:tw-mt-9">
      <app-msa-content-notice type="Info" contentNoticeTitle="i18n.recruitment.substitutionDateIdentical" />
    </div>
  }
  <div class="tw-mt-12 d:tw-mt-9">
    <app-msa-content-notice type="Info" contentNoticeTitle="i18n.recruitment.limitedPlacesDisclaimer" />
  </div>
} @else {
  <div class="tw-mt-12 d:tw-mt-9">
    <app-msa-content-notice type="Warning" contentNoticeTitle="i18n.recruitment.warningNoFreePlaces.title">
      <p [innerHTML]="'i18n.recruitment.warningNoFreePlaces.message' | safeTranslate"></p>
    </app-msa-content-notice>
  </div>
}

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation
  [showBackButton]="true"
  [preventNext]="form.invalid"
  (next)="onNext()"
  (previous)="back.emit()"
/>

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
