import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';

@Component({
  selector: 'app-required-fields-notice',
  templateUrl: './required-fields-notice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, SafeTranslateDirective]
})
export class RequiredFieldsNoticeComponent {}
