<section>
  <div class="tw-flex tw-flex-row tw-pb-30 tw-pe-15 tw-ps-15 tw-text-black-light-5 d:tw-pb-60 d:tw-pe-60 d:tw-ps-60">
    @for (step of stepList(); track step.content; let i = $index) {
      <button
        class="msa-text-small tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-12 tw-border-b-[3px] tw-pb-15 tw-ps-12 tw-pt-18 tw-text-start tw-font-extra-bold"
        [class.tw-text-white]="selectedIndex === i"
        (click)="setSelectedIndex(i)"
      >
        @if (step.icon()) {
          <mat-icon>{{ step.icon() }}</mat-icon>
        }
        <span>{{ step.label }}</span>
      </button>
    }
  </div>

  @if (selected?.content) {
    <ng-container [ngTemplateOutlet]="selected!.content" />
  }
</section>
