import {ChangeDetectionStrategy, Component, EventEmitter, inject, Injector, input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {ActivatedRoute, Router} from '@angular/router';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';

export type MsaMenuAction = {
  id: string;
  text: TranslationString;
  callback?: (injector: Injector) => void;
  routerLink?: string;
};

@Component({
  selector: 'msa-actions-menu',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './msa-actions-menu.component.html',
  imports: [SafeTranslateModule, MatIconModule, MatMenuModule]
})
export class MsaActionsMenuComponent {
  public actions = input<MsaMenuAction[]>([]);

  @Output()
  public actionSelected = new EventEmitter<MsaMenuAction>();

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private injector = inject(Injector);

  public onClick(action: MsaMenuAction): void {
    if (action.callback) action.callback(this.injector);
    if (action.routerLink) this.router.navigate([action.routerLink], {relativeTo: this.activatedRoute});
    this.actionSelected.emit(action);
  }
}
