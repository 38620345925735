import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {
  MsaActionsMenuComponent,
  MsaMenuAction
} from '@shared/shared-module/components/msa-actions-menu/msa-actions-menu.component';
import {MsaArrowLinkComponent} from '@shared/shared-module/components/msa-arrow-link/msa-arrow-link.component';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {MsaDialogAction} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {MsaSpinnerComponent} from '@shared/shared-module/components/msa-spinner/msa-spinner.component';
import {MsaTimelineComponent} from '@shared/shared-module/components/msa-timeline/msa-timeline.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {chain} from 'lodash';
import moment from 'moment';
import {catchError, EMPTY, take, tap} from 'rxjs';
import {MsaContentNoticeComponent} from '../../../../../../src/app/shared-module/components/msa-content-notice/msa-content-notice.component';
import {
  Action,
  DoneDutyDto,
  DutiesRestService,
  DutyMsaStateDto,
  FixedCodeHashesDto,
  MarchingOrderDocumentDtoTypeEnum,
  OpenDutyDto
} from '../../core/api/generated/msa-duty-service';
import {MsaDesiredRecreuitSchoolDialogComponent} from '../../dialogs/desired-recruit-school-dialog/desired-recruit-school-dialog.component';
import {CreateRequestDialogComponent} from '../../dialogs/duties-dialog/create-request-dialog.component';
import {CodeListStateSelectors} from '../../stores/selectors/code-list.state.selectors';
import {DutyTranslationsUtils} from '../../utils/translation.utils';
import {MarchingOrderDocumentComponent} from './marching-order-document/marching-order-document.component';

const SET_WZP_ACTION_ID = 'setWZP';

@Component({
  selector: 'msa-duties-overview',
  templateUrl: './duties-overview.component.html',
  styleUrl: './duties-overview.component.css',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    MatMenuModule,
    MatIconModule,
    MsaTimelineComponent,
    MsaCardComponent,
    TranslateObjectPipe,
    SafeTranslatePipe,
    SafeTranslateDirective,
    StatusPillComponent,
    MsaTooltipComponent,
    MarchingOrderDocumentComponent,
    MsaSpinnerComponent,
    MsaActionsMenuComponent,
    MsaContentNoticeComponent,
    MsaArrowLinkComponent
  ]
})
export class DutiesOverviewComponent implements OnInit {
  private dutyService = inject(DutiesRestService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private featureFlagService = inject(FeatureFlagService);
  private destroyRef = inject(DestroyRef);
  private snackbarService = inject(SnackbarService);

  protected readonly MarchingOrderDocumentDtoTypeEnum = MarchingOrderDocumentDtoTypeEnum;

  private openDuties = signal<Array<OpenDutyDto> | undefined>(undefined);
  private doneDuties = signal<Array<DoneDutyDto> | undefined>(undefined);

  openDutiesViewModels = computed(() =>
    chain(
      (this.openDuties() ?? []).map(openDuty => {
        const actions = this.getEnrichedActions(openDuty.id, openDuty.actions);
        return {
          ...openDuty,
          ...DutyTranslationsUtils.getStatusFields(openDuty.msaDutyState),
          actions,
          showWZPInfo: actions.find(a => a.id === SET_WZP_ACTION_ID) ?? false
        };
      })
    )
      .groupBy(item => moment(item.startDate).year())
      .mapValues(values => values.sort((a, b) => Date.parse(b.startDate!) - Date.parse(a.startDate!)))
      .mapValues(values =>
        values.map(item => ({
          ...item,
          detachmentCode: item.detachmentCode!.map(dc => ({
            ...dc
          }))
        }))
      )
      .value()
  );

  doneDutiesViewModels = computed(() =>
    chain(
      (this.doneDuties() ?? []).map(doneDuty => ({
        ...doneDuty,
        ...DutyTranslationsUtils.getStatusFields(doneDuty.msaDutyState),
        actions: this.getEnrichedActions(doneDuty.id, doneDuty.actions)
      }))
    )
      .groupBy(item => moment(item.startDate).year())
      .mapValues(values => values.sort((a, b) => Date.parse(b.startDate!) - Date.parse(a.startDate!)))
      .value()
  );

  public isOpenDutiesLoading = computed(() => this.openDuties() === undefined && !this.error());
  public isDoneDutiesLoading = computed(() => this.doneDuties() === undefined && !this.error());

  public error = signal<unknown>(null);
  public plannedValue = DutyMsaStateDto.Planned;
  public completedState = DutyMsaStateDto.Completed;
  private fixedHashCodes = readStoreSignal(CodeListStateSelectors.slices.fixedHashCodes);
  public dutyTypeA = computed(
    () => this.fixedHashCodes().find(code => code.name === FixedCodeHashesDto.DutyTypeA)?.hashCode
  );

  constructor(private dialog: MatDialog) {
    this.fetchAllDuties();
  }

  ngOnInit(): void {
    const wzpModalEnabled = this.featureFlagService.hasFeaturesEnabled('wzpModalEnabled')();
    if (wzpModalEnabled && this.activatedRoute.snapshot.queryParamMap.has('wzp')) {
      this.showWZPDialog();
    }
  }

  protected getEnrichedActions(dutyId: string, dutyActions: Action[] | undefined): MsaMenuAction[] {
    return (dutyActions ?? [])
      .map<MsaMenuAction | null>((action: Action) => {
        switch (action) {
          case Action.OpenDetails:
            return {
              id: 'openDuty',
              text: 'i18n.duties.actions.open',
              callback: () => this.router.navigate([dutyId], {relativeTo: this.activatedRoute})
            };
          case Action.DialogShiftAndLeaveRequests:
            return {
              id: 'createRequest',
              text: 'i18n.duties.headers.shift-or-leave-duty-request',
              callback: () => this.openDialog(dutyId)
            };
          case Action.ReportError:
            return {id: 'reportError', text: 'i18n.duties.actions.reportError'};
          case Action.SetDesiredRecruitSchool:
            return this.featureFlagService.hasFeaturesEnabled('wzpModalEnabled')()
              ? {
                  id: SET_WZP_ACTION_ID,
                  text: 'i18n.duties.actions.setWZP',
                  callback: () => this.showWZPDialog()
                }
              : null;
          default:
            return null;
        }
      })
      .filter(isDefined);
  }

  openDialog(dutyId: string) {
    try {
      this.dialog.open(CreateRequestDialogComponent, {
        data: dutyId
      });
    } catch (error) {
      this.snackbarService.openSnackbar({
        text: 'i18n.common.error.generic',
        type: MessageType.Error
      });
    }
  }

  private showWZPDialog(): void {
    const dialogRef = this.dialog.open(MsaDesiredRecreuitSchoolDialogComponent, {
      data: {wzp: this.activatedRoute.snapshot.queryParamMap.get('wzp')}
    });

    // refresh duties data after set
    dialogRef
      .afterClosed()
      .pipe(
        tap((action: MsaDialogAction) => {
          if (action === MsaDialogAction.CONFIRM) {
            this.fetchAllDuties();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private fetchAllDuties(): void {
    this.dutyService
      .getAllDuties()
      .pipe(
        take(1),
        tap(() => this.error.set(null)),
        tap(duties => {
          this.openDuties.set(duties.openDuties);
          this.doneDuties.set(duties.doneDuties);
        }),
        catchError((err: unknown) => {
          this.error.set(err);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
