<div class="tw-mt-30 d:tw-mt-60"></div>

<p class="msa-text-field-set" safeTranslate="i18n.recruitment.substitutionDateLabel"></p>

<app-key-value key="i18n.recruitment.desiredMomentLabel">
  <p>
    {{ getSeasonTranslation(requestDetails().recruitSchool.seasonDto) | safeTranslate }}
    {{ requestDetails().recruitSchool.year }}
  </p>
  <p>{{ requestDetails().recruitSchool.startDate | date }} - {{ requestDetails().recruitSchool.endDate | date }}</p>
  <p></p
></app-key-value>

<app-key-value key="i18n.recruitment.substitutionDateLabel">
  @if (substitutionCycle(); as cycle) {
    <p>{{ cycle.startDate | date }} - {{ cycle.endDate | date }}</p>
  }
</app-key-value>
