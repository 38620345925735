<app-msa-content-notice class="tw-pt-30 d:tw-pt-60" type="Success">{{
  message() | safeTranslate
}}</app-msa-content-notice>

<p
  class="msa-text tw-pt-30 d:tw-pt-60"
  style="white-space: pre-wrap"
  safeTranslate="i18n.user-info.submit-disclaimer"
></p>

<div class="tw-pt-30 d:tw-pt-60"></div>
@if (disclaimer()) {
  <app-msa-content-notice type="Info">{{ disclaimer() | safeTranslate }}</app-msa-content-notice>
}

<div class="row tw-mt-30 tw-flex tw-justify-end d:tw-mt-60" data-cy="submitSuccess">
  <msa-button
    class="d:tw-max-w-[50%]"
    (click)="closeShiftRequest()"
    data-cy="close"
    routerLink="/admin-query/requests"
    color="primary"
  >
    {{ "i18n.common.close" | safeTranslate }}
  </msa-button>
</div>
