import {inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {CanDeactivateFn} from '@angular/router';
import {Store} from '@ngxs/store';
import {
  MsaDialogAction,
  MsaDialogComponent,
  MsaDialogData
} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {map, merge, of} from 'rxjs';
import {DiscardUserFormData, SaveDraft} from '../stores/actions/edit-request.state.actions';
import {EditRequestStateSelectors} from '../stores/selectors/edit-request-state.selectors';

export const hasNoUnsavedChangesGuard = (): CanDeactivateFn<void> => {
  return () => {
    const store = inject(Store);
    const dialog = inject(MatDialog);

    const hasUnsavedChanges = store.selectSnapshot(EditRequestStateSelectors.hasUnsavedChanges);

    if (!hasUnsavedChanges) {
      return of(true);
    }

    const dialogRef = dialog.open<MsaDialogComponent, MsaDialogData>(MsaDialogComponent, {
      data: {
        title: 'i18n.common.save-draft',
        message: 'i18n.dialogs.save.text',
        cancelButtonText: 'i18n.dialogs.save.actions.discard',
        confirmButtonText: 'i18n.dialogs.save.actions.save'
      }
    });

    return merge(
      dialogRef.componentInstance.confirmEvent.pipe(map(() => MsaDialogAction.CONFIRM)),
      dialogRef.componentInstance.cancelEvent.pipe(map(() => MsaDialogAction.CANCEL))
    ).pipe(
      map(saveAction => {
        dialogRef.close();
        switch (saveAction) {
          case MsaDialogAction.CANCEL:
            store.dispatch(new DiscardUserFormData());
            return true;
          case MsaDialogAction.CONFIRM:
            store.dispatch(new SaveDraft());
            return true;
          default:
            return false;
        }
      }),
      takeUntilDestroyed()
    );
  };
};
