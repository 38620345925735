<div
  class="tw-relative tw-bg-black-light-1 tw-px-21 tw-py-30 d:tw-px-45 d:tw-py-60"
  [attr.data-cy]="'createRequestDialog'"
>
  <h2 class="msa-text-h2 tw-mb-15 d:tw-mb-18">
    {{ "i18n.duties.headers.shift-or-leave-duty-request" | safeTranslate }}
  </h2>
  <button
    class="!tw-absolute tw-right-12 tw-top-12 d:tw-right-24 d:tw-top-24"
    [attr.data-cy]="'close'"
    mat-icon-button
    aria-label="close dialog"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="tw-max-h-[50vh] tw-overflow-auto">
    <div class="tw-mb-30 d:tw-mb-60">
      <p class="msa-text tw-mb-15 d:tw-mb-18">{{ "i18n.duty-list.modal.request-option" | safeTranslate }}</p>
      <ul class="msa-text tw-list-disc">
        <li class="tw-mb-15 tw-ml-[1em] d:tw-mb-18">
          <p
            [innerHTML]="
              'i18n.duty-list.modal.request-option-shift'
                | safeTranslate: { startDate: dutyStartDate() | date, endDate: dutyEndDate() | date }
            "
          ></p>
        </li>
        <li class="tw-mb-15 tw-ml-[1em] d:tw-mb-18">
          <p
            [innerHTML]="
              'i18n.duty-list.modal.request-option-leave'
                | safeTranslate: { startDate: dutyStartDate() | date, endDate: dutyEndDate() | date }
            "
          ></p>
        </li>
      </ul>
    </div>

    @if (showDutyCancelledOrDoneInfo()) {
      <div class="tw-mb-30 d:tw-mb-60">
        <app-msa-content-notice type="Info">
          <p [innerHTML]="'i18n.duty-list.modal.info.duty-cancelled' | safeTranslate"></p>
        </app-msa-content-notice>
      </div>
    } @else if (showDvsNotAllowedInfo()) {
      <div class="tw-mb-30 d:tw-mb-60">
        <app-msa-content-notice type="Info">
          <p [innerHTML]="'i18n.shift.info.dvsNotAllowed' | safeTranslate"></p>
        </app-msa-content-notice>
      </div>
    } @else if (showDutyStartedInfo()) {
      <div class="tw-mb-30 d:tw-mb-60">
        <app-msa-content-notice type="Info">
          <p [innerHTML]="'i18n.duty-list.modal.info.duty-started' | safeTranslate"></p>
        </app-msa-content-notice>
      </div>
    } @else if (showHasExistingRequestInfo()) {
      <div class="tw-mb-30 d:tw-mb-60">
        <app-msa-content-notice type="Info">
          <p [innerHTML]="'i18n.duty-list.modal.info.existing-shift-request' | safeTranslate"></p>
        </app-msa-content-notice>
      </div>
    }

    @if (showDutyTooFarInFutureInfo()) {
      <div class="tw-mb-30 d:tw-mb-60">
        <app-msa-content-notice type="Info">
          <p [innerHTML]="'i18n.duties.error.dutyTooFarInFuture' | safeTranslate"></p>
        </app-msa-content-notice>
      </div>
    }
  </div>

  <div class="tw-flex tw-flex-col tw-gap-12 d:tw-flex-row">
    <msa-button
      class="tw-w-full tw-content-start"
      [attr.data-cy]="'openNewShiftDraft'"
      [disabled]="!canCreateShiftRequest()"
      (click)="openShiftDraft()"
      mat-dialog-close
      color="primary"
    >
      <p [innerHTML]="'i18n.duty-list.modal.shift-button' | safeTranslate"></p>
    </msa-button>
    <msa-button
      class="tw-w-full tw-content-end"
      [attr.data-cy]="'openNewLeaveDraft'"
      [disabled]="!canCreateLeaveRequest()"
      (click)="openLeaveDraft()"
      mat-dialog-close
      color="primary"
    >
      {{ "i18n.duty-list.modal.leave-button" | safeTranslate }}
    </msa-button>
  </div>
</div>
