import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {MsaEmptyListBoxComponent} from '@shared/shared-module/components/msa-empty-list-box/msa-empty-list-box.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {DutyDetailsDto} from '../../../core/api/generated/msa-duty-service';
import {MilitaryPostalAddressDto} from '../../../core/api/generated/msa-duty-service/model/militaryPostalAddressDto.model';
import {getRankAdditionText} from '../../../utils/translation.utils';

@Component({
  selector: 'msa-military-postal-address',
  templateUrl: './military-postal-address.component.html',
  standalone: true,
  imports: [
    DatePipe,
    MsaCollapsibleComponent,
    MsaEmptyListBoxComponent,
    SafeTranslateModule,
    TranslateModule,
    StatusPillComponent,
    TranslateObjectPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MilitaryPostalAddressComponent {
  /** @deprecated can be removed when the feature flag is always true */
  public duty = input.required<DutyDetailsDto>();
  public militaryPostalAddress = input.required<MilitaryPostalAddressDto | null>();

  /** @deprecated: can be removed when the feature flag is always true */
  private personalData = readStoreSignal(PersonDataStateSelectors.getPersonalData);
  private militaryData = readStoreSignal(PersonDataStateSelectors.getCurrentMilitaryData);

  private featureFlagService = inject(FeatureFlagService);

  public postalAddress = computed(() => {
    const usingBackendRoute = this.featureFlagService.hasFeaturesEnabled('dutyDetailsPostalAddress')();
    if (usingBackendRoute) {
      const rankAdditionText: TranslationString = getRankAdditionText(this.militaryPostalAddress()?.rankAddition);
      return {...this.militaryPostalAddress(), rankAddition: rankAdditionText};
    }

    /** @deprecated can be removed when the feature flag is always true */
    return {
      firstName: this.personalData().firstName,
      lastName: this.personalData().lastName,
      rank: this.militaryData()?.rankShort,
      rankAddition: this.militaryData()?.rankAddition,
      division: this.duty()?.divisionCode.descriptionDto,
      zipCode: this.duty()?.divisionCode.additionalProperties?.find(e => e.name === 'militaryPostalCode')?.value
    };
  });

  isRs = false; // logic will follow later to display message if rekrutenschule
}
