import {CdkAccordionModule} from '@angular/cdk/accordion';
import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {MsaEmptyListBoxComponent} from '@shared/shared-module/components/msa-empty-list-box/msa-empty-list-box.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {OrderByPipe} from '@shared/shared-module/pipes/order-by.pipe';
import {QRCodeModule} from 'angularx-qrcode';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {DutyDetailsDto, MarchingOrderDocumentDtoTypeEnum} from '../../../core/api/generated/msa-duty-service';
import {MarchingOrderDocumentComponent} from '../../duty-overview/marching-order-document/marching-order-document.component';

@Component({
  selector: 'msa-marching-order-details',
  standalone: true,
  imports: [
    CdkAccordionModule,
    DatePipe,
    OrderByPipe,
    SafeTranslateDirective,
    MsaContentNoticeComponent,
    MarchingOrderDocumentComponent,
    MsaCollapsibleComponent,
    MsaEmptyListBoxComponent,
    QRCodeModule,
    MsaButtonComponent,
    RouterLink,
    SafeTranslateModule,
    MsaTooltipComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './marching-order-details.component.html'
})
export class MarchingOrderDetailsComponent {
  public duty = input.required<DutyDetailsDto>();

  public doNotMarch = computed(
    () =>
      this.duty().marchingOrder.type === MarchingOrderDocumentDtoTypeEnum.DoNotMarch ||
      this.duty().detachmentCode.some(d => d.marchingOrder?.type === MarchingOrderDocumentDtoTypeEnum.DoNotMarch)
  );

  public hasDigitalMarchingOrder = computed(() =>
    this.duty().detachmentCode.some(
      detachmentCode => detachmentCode.marchingOrder?.type === MarchingOrderDocumentDtoTypeEnum.Digital
    )
  );

  public walletTriggerLink = 'https://app.portal-armee.ch/a/refresh';

  public openWallet(): void {
    window.location.href = this.walletTriggerLink;
  }
}
