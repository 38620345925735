import {NgOptimizedImage} from '@angular/common';
import {HttpStatusCode} from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  signal,
  untracked
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {MsaDialogAction} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {ConnectSwissPassDialogComponent} from 'projects/person-data/src/app/dialogs/connect-swiss-pass-dialog/connect-swiss-pass-dialog.component';
import {catchError, filter, map, tap} from 'rxjs';
import {KeyValueComponent} from '../../../../../../../src/app/shared-module/components/key-value/key-value.component';
import {MsaButtonComponent} from '../../../../../../../src/app/shared-module/components/msa-button/msa-button.component';
import {MsaContentNoticeComponent} from '../../../../../../../src/app/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaSpinnerComponent} from '../../../../../../../src/app/shared-module/components/msa-spinner/msa-spinner.component';
import {DutiesRestService} from '../../../core/api/generated/msa-duty-service';
import {PublicTransportClass} from '../../../core/api/generated/msa-duty-service/model/publicTransportClass.model';
import {PublicTransportDetailsDto} from '../../../core/api/generated/msa-duty-service/model/publicTransportDetailsDto.model';

type PublicTransportDetailsViewModel = {transportationClassText: TranslationString} & PublicTransportDetailsDto;

@Component({
  selector: 'msa-swisspass-details',
  standalone: true,
  imports: [
    SafeTranslateModule,
    RouterLink,
    MsaCollapsibleComponent,
    KeyValueComponent,
    NgOptimizedImage,
    MsaContentNoticeComponent,
    MsaButtonComponent,
    MsaSpinnerComponent,
    MsaTooltipComponent,
    MatIconModule
  ],
  templateUrl: './swisspass-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwissPassDetailsComponent {
  public dutyId = input.required<string>();

  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);
  private dutiesRestService = inject(DutiesRestService);
  private errorMessageHandler = inject(ErrorMessageHandler);

  public publicTransportDetails = signal<PublicTransportDetailsViewModel | undefined>(undefined);
  public isLoading = signal(true);
  public showDetails = signal(false);
  public swissPassConnected = computed(() => isDefined(this.publicTransportDetails()?.customerKeyNumber));

  constructor() {
    effect(() => {
      const dutyId = this.dutyId();
      untracked(() => this.fetchSwissPassData(dutyId));
    });
  }

  public connectSwissPass(): void {
    const dialogRef = this.dialog.open(ConnectSwissPassDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(
        tap(result => {
          if (result === MsaDialogAction.CONFIRM) {
            this.fetchSwissPassData(this.dutyId());
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private fetchSwissPassData(dutyId: string): void {
    this.isLoading.set(true);
    this.dutiesRestService
      .publicTransportDetails(dutyId, 'response')
      .pipe(
        tap(response => {
          this.isLoading.set(false);
          if (response.status === HttpStatusCode.Ok) {
            this.showDetails.set(true);
          }
        }),
        map(response => response.body),
        filter(isDefined),
        tap(publicTransportDetails => {
          this.publicTransportDetails.set(this.composeViewModel(publicTransportDetails));
        }),
        catchError((err: unknown) => {
          this.isLoading.set(false);
          return this.errorMessageHandler.logAndIgnore(err);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private composeViewModel(publicTransportDetails: PublicTransportDetailsDto): PublicTransportDetailsViewModel {
    return {
      ...publicTransportDetails,
      transportationClassText:
        publicTransportDetails.transportationClass === PublicTransportClass.FirstClass
          ? 'i18n.duty.details.publicTransport.labels.firstClass'
          : 'i18n.duty.details.publicTransport.labels.secondClass'
    };
  }
}
