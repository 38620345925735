import {createPropertySelectors, Selector} from '@ngxs/store';
import {findByDutyId} from '@shared/shared-module/utils/find.utils';
import {GetByIdFn} from '@shared/shared-module/utils/store.utils';
import {RequestDutyInfoDto} from '../../core/api/generated/msa-duty-service';
import {DutyStateModel} from '../models/duty.state.model';
import {DutyState} from '../states/duty.state';

export class DutyStateSelectors {
  static slices = createPropertySelectors<DutyStateModel>(DutyState);

  @Selector([DutyStateSelectors.slices.dutyInfos])
  static getDutyInfoByIdFn(dutyInfos: RequestDutyInfoDto[]): GetByIdFn<RequestDutyInfoDto | null> {
    return (dutyId: string) => dutyInfos.find(findByDutyId(dutyId)) ?? null;
  }
}
