import {Injectable, inject} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable, map, merge} from 'rxjs';
import {MsaDialogAction, MsaDialogComponent, MsaDialogData} from '../components/msa-dialog/msa-dialog.component';

@Injectable({providedIn: 'root'})
export class MsaDialogService {
  private dialog = inject(MatDialog);

  private matDialogRef: MatDialogRef<MsaDialogComponent> | null = null;

  openConfirmationDialog(dialogData: MsaDialogData): Observable<MsaDialogAction> {
    this.matDialogRef = this.dialog.open(MsaDialogComponent, {data: dialogData});

    return merge(
      this.matDialogRef.componentInstance.confirmEvent.pipe(map(() => MsaDialogAction.CONFIRM)),
      this.matDialogRef.componentInstance.cancelEvent.pipe(map(() => MsaDialogAction.CANCEL))
    );
  }

  closeCurrentDialog(): void {
    if (!this.matDialogRef) return;
    this.matDialogRef.close();
  }
}
