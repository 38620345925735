import {CdkStep, CdkStepperModule} from '@angular/cdk/stepper';
import {ChangeDetectionStrategy, Component, input, OnInit} from '@angular/core';
import {MsaSpinnerComponent} from '../msa-spinner/msa-spinner.component';

@Component({
  selector: 'msa-step',
  standalone: true,
  imports: [MsaSpinnerComponent, CdkStepperModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <ng-template>
    <ng-content></ng-content>
  </ng-template>`,
  providers: [{provide: CdkStep, useExisting: MsaStepComponent}]
})
export class MsaStepComponent extends CdkStep implements OnInit {
  public icon = input<string>();

  /**
   * Indicates if an inital label has been set. In that case
   * we always keep this label and won't overwrite it.
   */
  public labelProvided = false;

  ngOnInit(): void {
    this.labelProvided = this.label?.length > 0;
  }
}
