<div>
  <msa-file-overview
    [uploadedDocuments]="request().responseData.responses"
    [titleText]="'i18n.request-overview.reply.title'"
    [titleColor]="'theme(\'colors.black.light[5.5]\')'"
    (download)="downloadResponse.emit($event)"
    data-cy="responseDocuments"
  />
</div>

<!-- show additional context information -->
<ng-content />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.recap-container.reason-title"
  data-cy="headerReasonType"
></h3>

@if (reasonTypeCode(); as reasonTypeCode) {
  <app-key-value
    [value]="
      reasonTypeCode && reasonTypeCode?.descriptionDto
        ? (reasonTypeCode.descriptionDto | translateObject)
        : 'i18n.code.not-found'
    "
    key="i18n.duty-relocation-form.reason.label"
    data-cy="reasonType"
  />
}

<hr class="tw-mb-30 tw-mt-30 tw-w-full tw-border-t tw-border-t-black-light-5 d:tw-mb-60 d:tw-mt-60" />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.recap-container.reason-description"
  data-cy="headerRasonDescription"
></h3>

<app-key-value [value]="requestDetails().reason" key="i18n.recap-container.reason-description" data-cy="fieldReason" />

<msa-file-overview
  [uploadedDocuments]="requestDetails().documents"
  [titleText]="'i18n.upload-container.title'"
  [titleColor]="'theme(\'colors.black.light[5.5]\')'"
  (download)="downloadAttachment.emit($event)"
  data-cy="uploadedFiles"
/>

@if (gadRequestDetails(); as details) {
  <app-gad-substitution-time-summary [requestDetails]="details" [requestId]="request().id" />
}
@if (recruitmentRequestDetails(); as details) {
  <msa-recruitment-substitution-time-summary [requestDetails]="details" [dutyId]="duty().dutyId" />
}
@if (sideProcessRequestDetails(); as details) {
  <msa-side-process-substitution-time-summary [requestDetails]="details" [dutyId]="duty().dutyId" />
}
@if (fdtRequestDetails(); as details) {
  <app-substitution-time-summary [requestDetails]="details" [duty]="duty()" />
}
