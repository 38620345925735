<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
} @else {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<form [formGroup]="form" data-cy="displacementTimeForm">
  <div>
    <label
      class="msa-text-field-set"
      [appMarkRequired]="form.controls.displacementTimeOption"
      safeTranslate="i18n.duty-relocation-form.period.substitution.label"
    ></label>
    <p class="msa-text tw-mb-12 tw-mt-12" safeTranslate="i18n.recap-container.period.possible-duty-time"></p>

    <fieldset class="tw-flex tw-flex-col tw-gap-12" data-cy="substitutionPeriodOptions">
      <msa-radiobutton
        [formControl]="form.controls.displacementTimeOption"
        [value]="DisplacementTime.ThisYear"
        (selected)="onSubstitutionTypeChange(DisplacementTime.ThisYear)"
        key="thisYear"
        data-cy="thisYear"
      >
        {{
          "i18n.duty-relocation-form.period.always"
            | safeTranslate
              : {
                  startDate: affectedDateRange().from,
                  endDate: affectedDateRange().to,
                }
        }}
      </msa-radiobutton>

      <msa-radiobutton
        [formControl]="form.controls.displacementTimeOption"
        [value]="DisplacementTime.Partially"
        (selected)="onSubstitutionTypeChange(DisplacementTime.Partially)"
        key="partially"
        data-cy="partially"
      >
        {{ "i18n.duty-relocation-form.period.not_always" | safeTranslate }}
      </msa-radiobutton>

      @if (form.value.displacementTimeOption === DisplacementTime.Partially) {
        <div class="tw-gap-0">
          <label
            class="msa-text-field-set"
            safeTranslate="i18n.recap-container.period.impossible-duty-time.text"
          ></label>
          <p
            class="msa-text-small tw-mb-15 tw-mt-15 tw-pb-30 tw-text-black-light-5 d:tw-mb-18 d:tw-mt-18 d:tw-pb-60"
            safeTranslate="i18n.recap-container.period.impossible-duty-time.hint"
          ></p>

          @for (dateRangeForm of form.controls.preventedPeriods.controls; track dateRangeForm) {
            <div
              class="tw-border-b tw-border-t tw-border-black-light-4 tw-pb-15 tw-pt-15 d:tw-pb-18 d:tw-pt-18"
              formArrayName="preventedPeriods"
            >
              <msa-date-range
                [formControl]="dateRangeForm"
                [label]="('i18n.recap-container.appointment-entry' | safeTranslate) + ' ' + ($index + 1)"
                [yearFilter]="dutyYear()"
                (remove)="removeSubstitutionPeriod($index)"
                dynamicClass="tw-mt-6"
              />
            </div>
          }

          <div class="tw-mt-15 d:tw-mt-18"></div>
          <msa-button
            class="tw-w-auto"
            [disabled]="form.controls.preventedPeriods.controls.length >= 3"
            (click)="addNewPreventedPeriodsForm()"
            data-cy="addNewPreventedPeriodsForm"
            color="secondary"
          >
            <mat-icon>add</mat-icon>
            {{ "i18n.exam-component.exams-add-appointment" | safeTranslate }}
          </msa-button>
        </div>

        <div class="tw-mt-30 d:tw-mt-60"></div>
      }

      <msa-radiobutton
        [formControl]="form.controls.displacementTimeOption"
        [value]="DisplacementTime.NotThisYear"
        (selected)="onSubstitutionTypeChange(DisplacementTime.NotThisYear)"
        key="notThisYear"
        data-cy="notThisYear"
        >{{ "i18n.duty-relocation-form.period.never.text" | safeTranslate }}
      </msa-radiobutton>
    </fieldset>

    <div class="tw-mt-30 d:tw-mt-60"></div>

    @if (showCompensationMessage()) {
      <app-msa-content-notice [type]="MessageType.Info" data-cy="infoWehrpflichtErsatzdienst"
        >{{ "i18n.duty-relocation-form.period.never.info-reason" | safeTranslate }}
      </app-msa-content-notice>

      <div class="tw-mt-12"></div>

      <app-msa-content-notice [type]="MessageType.Info" data-cy="infoWehrpflichtersatzabgabe"
        >{{ "i18n.duty-relocation-form.period.never.info" | safeTranslate }}
      </app-msa-content-notice>

      <div class="tw-mt-30 d:tw-mt-60"></div>
    }
  </div>

  @if (!isGADB()) {
    <label
      class="msa-text-field-set"
      [appMarkRequired]="form.controls.languages!"
      safeTranslate="i18n.duty-relocation-form.other-languages.title"
    ></label>
    <p class="msa-text tw-mb-12 tw-mt-12" safeTranslate="i18n.duty-relocation-form.other-languages.label"></p>

    <div class="tw-flex tw-flex-col tw-gap-12" [formGroup]="form.controls.languages!">
      <msa-checkbox key="de" formControlName="de">{{ "i18n.language.de" | safeTranslate }}</msa-checkbox>
      <msa-checkbox key="fr" formControlName="fr">{{ "i18n.language.fr" | safeTranslate }}</msa-checkbox>
      <msa-checkbox key="it" formControlName="it">{{ "i18n.language.it" | safeTranslate }}</msa-checkbox>
    </div>
  }
</form>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation [preventNext]="form.invalid" (next)="onNext()" (previous)="back.emit()" />

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
