/* eslint-disable @typescript-eslint/no-explicit-any */
import {ComponentType} from '@angular/cdk/portal';
import {Selector} from '@ngxs/store';
import {ComponentInputs} from '@shared/shared-module/utils/simple-changes-typed';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {
  Action,
  CodeDto,
  FixedCodeHashesDto,
  RequestDto,
  RequestDutyInfoDto
} from '../../core/api/generated/msa-duty-service';
import {StepDetailedExplanationComponent} from '../../pages/request-form/general/step-detailed-explanation/step-detailed-explanation.component';
import {StepEditRequestComponent} from '../../pages/request-form/general/step-edit-request-component/step-edit-request.component';
import {StepShiftRequestSummaryComponent} from '../../pages/request-form/general/step-request-summary/step-request-summary.component';
import {StepSubmitSuccessComponent} from '../../pages/request-form/general/step-submit-success/step-submit-success.component';
import {StepLeaveAndTransportDetailsComponent} from '../../pages/request-form/leave-form/step-leave-and-transport-details/step-leave-and-transport-details.component';
import {StepLeavePrivilegesComponent} from '../../pages/request-form/leave-form/step-leave-privileges/step-leave-privileges.component';
import {StepGadDisplacementTimeComponent} from '../../pages/request-form/shift-form/gad/step-gad-substitution-period/step-gad-displacement-time.component';
import {StepRecruitmentSubstitutionTimeComponent} from '../../pages/request-form/shift-form/recruitment/recruitment-substitution-time.component';
import {StepSideProcessSubstitutionTimeComponent} from '../../pages/request-form/shift-form/side-processes/side-process-substitution-time.component';
import {StepDetailedExplanationUniversityComponent} from '../../pages/request-form/shift-form/step-detailed-explanation-university/step-detailed-explanation-university.component';
import {StepShiftReasonComponent} from '../../pages/request-form/shift-form/step-shift-reason/step-shift-reason.component';
import {StepDisplacementTimeComponent} from '../../pages/request-form/shift-form/step-substitution-period/step-displacement-time.component';
import {StepUniversityComponent} from '../../pages/request-form/shift-form/step-university/step-university.component';
import {CodeListStateSelectors} from './code-list.state.selectors';
import {EditRequestStateSelectors} from './edit-request-state.selectors';

export interface StepData<C extends StepEditRequestComponent> {
  component: ComponentType<C>;
  title: TranslationString;
  inputs?: ComponentInputs<C>;
}

// helper for typing inputs
const createStep = <C extends StepEditRequestComponent>(
  component: ComponentType<C>,
  title: TranslationString,
  inputs: ComponentInputs<C>
): StepData<C> => {
  return {component, title, inputs};
};

const LEAVE_STEPS = (request: RequestDto, hasUnsavedChanges: boolean): StepData<any>[] => [
  createStep(StepLeaveAndTransportDetailsComponent, 'i18n.leave.header.reasonTime', {request, hasUnsavedChanges}),
  createStep(StepDetailedExplanationComponent, 'i18n.duty-type.form.header.detailed-reason', {
    request,
    hasUnsavedChanges,
    reasonHintText: 'i18n.leave.reasonMotivation'
  }),
  createStep(StepLeavePrivilegesComponent, 'i18n.leave.header.approval', {request, hasUnsavedChanges}),
  createStep(StepShiftRequestSummaryComponent, 'i18n.duty-type.form.header.summary', {request, hasUnsavedChanges}),
  createStep(StepSubmitSuccessComponent, 'i18n.duty-type.form.header.request-submitted', {
    request,
    hasUnsavedChanges,
    disclaimer: 'i18n.leave.submit.disclaimer'
  })
];

function getStepForDvs(
  request: RequestDto,
  duty: RequestDutyInfoDto,
  reasonTypes: CodeDto[],
  universities: CodeDto[],
  universityDepartments: CodeDto[],
  isGADB: boolean,
  hasUnsavedChanges: boolean
): StepData<any>[] {
  const steps: StepData<any>[] = [];
  const isUniversityDetail = EditRequestStateSelectors.isUniversityDetail(request.requestDetail);
  const isGadDetail = EditRequestStateSelectors.isGadDetail(request.requestDetail);
  const isRekrDetail = EditRequestStateSelectors.isRecruitmentDetail(request.requestDetail);
  const isSideProcessDetail = EditRequestStateSelectors.isSideProcessDetail(request.requestDetail);

  steps.push(
    createStep(StepShiftReasonComponent, 'i18n.duty-type.form.header.reason-type', {
      request,
      reasonTypes,
      hasUnsavedChanges
    })
  );
  if (isUniversityDetail) {
    steps.push(
      createStep(StepUniversityComponent, 'i18n.duty-type.form.header.study', {
        request,
        universities,
        universityDepartments,
        hasUnsavedChanges
      }),
      createStep(StepDetailedExplanationUniversityComponent, 'i18n.duty-type.form.header.detailed-reason', {
        request,
        hasUnsavedChanges
      })
    );
  } else {
    steps.push(
      createStep(StepDetailedExplanationComponent, 'i18n.duty-type.form.header.detailed-reason', {
        request,
        hasUnsavedChanges,
        reasonHintText: 'i18n.duty-relocation-form.motivation.underline'
      })
    );
  }
  if (isGadDetail) {
    steps.push(
      createStep(StepGadDisplacementTimeComponent, 'i18n.duty-type.form.header.gad-prevented-period', {
        request,
        hasUnsavedChanges,
        duty
      })
    );
  } else if (isRekrDetail) {
    steps.push(
      createStep(StepRecruitmentSubstitutionTimeComponent, 'i18n.recruitment.substitutionDateTitle', {
        request,
        hasUnsavedChanges
      })
    );
  } else if (isSideProcessDetail) {
    steps.push(
      createStep(StepSideProcessSubstitutionTimeComponent, 'i18n.sideProcesses.substitutionDateTitle', {
        request,
        hasUnsavedChanges
      })
    );
  } else {
    steps.push(
      createStep(StepDisplacementTimeComponent, 'i18n.duty-type.form.header.prevented-and-language', {
        request,
        hasUnsavedChanges,
        duty,
        isGADB
      })
    );
  }
  steps.push(
    createStep(StepShiftRequestSummaryComponent, 'i18n.duty-type.form.header.summary', {request, hasUnsavedChanges}),
    createStep(StepSubmitSuccessComponent, 'i18n.duty-type.form.header.request-submitted', {
      request,
      hasUnsavedChanges,
      disclaimer: 'i18n.user-info.obligation-disclaimer'
    })
  );
  return steps;
}

/**
 * This is a separate file to avoid circular dependencies with @see EditRequestStateSelectors
 */
export class RequestFormStateSelectors {
  @Selector([
    EditRequestStateSelectors.getCurrentEditingRequest,
    EditRequestStateSelectors.getAffectedDuty,
    CodeListStateSelectors.slices.reasonTypes,
    CodeListStateSelectors.getFixedHashCodeByName,
    CodeListStateSelectors.slices.universityNames,
    CodeListStateSelectors.slices.universityDepartments,
    EditRequestStateSelectors.hasUnsavedChanges
  ])
  static formSteps(
    request: RequestDto | null,
    duty: RequestDutyInfoDto,
    reasonTypes: CodeDto[],
    hashCodeByNameFn: (codeName: FixedCodeHashesDto) => string | null,
    universities: CodeDto[],
    universityDepartments: CodeDto[],
    hasUnsavedChanges: boolean
  ): StepData<any>[] {
    if (!request?.requestDetail) {
      return [];
    }

    if (EditRequestStateSelectors.isLeaveDetail(request.requestDetail)) {
      return LEAVE_STEPS(request, hasUnsavedChanges);
    } else {
      const isGADB = duty.dutyTypeCodeHash === hashCodeByNameFn(FixedCodeHashesDto.DutyTypeG);
      return getStepForDvs(request, duty, reasonTypes, universities, universityDepartments, isGADB, hasUnsavedChanges);
    }
  }

  @Selector([EditRequestStateSelectors.getCurrentEditingRequest, EditRequestStateSelectors.slices.isRightAfterSubmit])
  static showFormEditMode(request: RequestDto, isRightAfterSubmit: boolean): boolean {
    if (!request) {
      return false;
    }

    // we cannot just check the action, since after submit, this action will not be present
    // on the DTO. Still we want to show the success page to the user
    return request.actions.some((a: Action) => a === Action.EditRequest) || isRightAfterSubmit;
  }
}
