<msa-dialog
  [confirmButtonText]="confirmButtonText()"
  [footNote]="canEditPZP() ? 'i18n.common.mandatory' : ''"
  [disabled]="canEditPZP() ? form.invalid : false"
  [showCancelButton]="canEditPZP()"
  [isLoading]="isLoading()"
  (confirmEvent)="confirm($event)"
  title="i18n.wzp.title"
>
  @if (isLoadingRecruitSchoolInfo()) {
    <msa-spinner class="tw-size-48 tw-self-center" />
  } @else {
    <ng-container *ngTemplateOutlet="dialogContent" />
  }
</msa-dialog>

<ng-template #dialogContent>
  @if (isbeforePZP()) {
    <app-msa-content-notice contentNoticeTitle="i18n.wzp.notEditableInfoBefore.title" type="Info">
      <p class="msa-text-small" safeTranslate="i18n.wzp.notEditableInfoBefore.text"></p>
    </app-msa-content-notice>
  } @else if (isAfterPZP()) {
    <app-msa-content-notice contentNoticeTitle="i18n.wzp.notEditableInfoAfter.title" type="Error">
      <p class="msa-text-small" safeTranslate="i18n.wzp.notEditableInfoAfter.text"></p>
    </app-msa-content-notice>
  } @else if (isActiveAndSetPZP()) {
    <app-msa-content-notice contentNoticeTitle="i18n.wzp.notEditableInfoDuring.title" type="Error">
      <p class="msa-text-small" safeTranslate="i18n.wzp.notEditableInfoDuring.text"></p>
    </app-msa-content-notice>
  } @else {
    @if (isPreselectionInvalid()) {
      <app-msa-content-notice
        class="tw-mb-30"
        contentNoticeTitle="i18n.wzp.preselectionInvalidError.title"
        type="Error"
      >
        <p class="msa-text-small" safeTranslate="i18n.wzp.preselectionInvalidError.text"></p>
      </app-msa-content-notice>
    }

    <div [formGroup]="form">
      <label class="msa-text tw-mb-6" safeTranslate="i18n.wzp.schoolLabel"></label>
      <mat-form-field class="tw-w-full" appearance="outline" subscriptSizing="dynamic">
        <mat-select [formControl]="form.controls.school">
          @for (school of recruitSchoolOptions(); track school.code) {
            <mat-option [value]="school.code"
              >{{
                school.text
                  | safeTranslate
                    : {
                        rsLabel: school.seasonText | safeTranslate,
                        year: school.year,
                        start: school.startDate! | date,
                        end: school.endDate! | date,
                      }
              }}
            </mat-option>
          }
        </mat-select>

        <mat-hint class="msa-text-small" safeTranslate="i18n.wzp.birthdayHint" />
      </mat-form-field>

      <msa-checkbox
        class="tw-mt-15 tw-flex d:tw-mt-30"
        [formControl]="form.controls.consent"
        key="consent"
        label="i18n.wzp.consentText"
      />

      @if (!form.value.consent && form.controls.consent.touched) {
        <p class="msa-text-small tw-mt-12 tw-text-vermillion-light-2" safeTranslate="i18n.wzp.consentError"></p>
      }

      @if (hasError()) {
        <div class="tw-mb-0 tw-mt-30 d:tw-mb-30 d:tw-mt-60">
          <app-msa-content-notice type="Error" contentNoticeTitle="i18n.wzp.generalError.title">
            <p class="msa-text-small" safeTranslate="i18n.wzp.generalError.text"></p>
          </app-msa-content-notice>
        </div>
      }
    </div>
  }
</ng-template>
