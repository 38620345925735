import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, OnInit, signal} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {OrderByPipe} from '@shared/shared-module/pipes/order-by.pipe';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {getSeasonTranslation} from '@shared/shared-module/utils/season-utils';
import {DutyContextComponent} from 'projects/admin-query/src/app/components/duty-context/duty-context.component';
import {
  CyclesDto,
  RecruitmentRestService,
  RecruitSchoolOptionDto,
  RequestType
} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {map, take, tap} from 'rxjs';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

@Component({
  selector: 'msa-recruitment-substitution-time',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './recruitment-substitution-time.component.html',
  imports: [
    CommonModule,
    SafeTranslateModule,
    DutyContextComponent,
    RequestContextComponent,
    ReactiveFormsModule,
    MatSelectModule,
    FormStepperNavigationComponent,
    MsaSaveDraftComponent,
    RequiredFieldsNoticeComponent,
    MsaContentNoticeComponent,
    OrderByPipe
  ]
})
export class StepRecruitmentSubstitutionTimeComponent extends StepEditRequestComponent implements OnInit {
  private recruitmentRestService = inject(RecruitmentRestService);
  private destroyRef = inject(DestroyRef);

  public readonly RequestType = RequestType;
  public readonly getSeasonTranslation = getSeasonTranslation;

  public form = new FormGroup({
    cycleId: new FormControl<CyclesDto['cycleId'] | null>(null, [Validators.required]),
    desiredMomentOfRecruitSchool: new FormControl<string | null>(null, [Validators.required])
  });

  // exposed state
  public availableRecruitSchoolOptions = signal<RecruitSchoolOptionDto[]>([]);
  public availableSubstitutionDates = signal<CyclesDto[]>([]);

  public freePZPPlacesAvailable = computed(
    () => isDefined(this.availableSubstitutionDates()) && this.availableSubstitutionDates().length > 0
  );

  private desiredMomentOfRecruitSchoolChanges = toSignal<string | null>(
    this.form.controls.desiredMomentOfRecruitSchool.valueChanges
  );

  private _fetchRecruitmentCycles = effect(() => {
    const momentOfRecruitSchool = this.desiredMomentOfRecruitSchoolChanges();
    if (!momentOfRecruitSchool) return;

    this.recruitmentRestService
      .getRecruitmentCycles(this.request().dutyId, momentOfRecruitSchool)
      .pipe(
        take(1),
        tap(response => this.availableSubstitutionDates.set(response)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  });

  selectedCycleId = toSignal(
    this.form.valueChanges.pipe(
      map(value => {
        return value.cycleId;
      })
    )
  );

  public isCurrentCycle = computed<boolean>(() => {
    if (!this.availableSubstitutionDates()) return false;
    const selectedCycle = this.availableSubstitutionDates().find(e => e.cycleId === this.selectedCycleId());
    return selectedCycle ? selectedCycle.isCurrentCycle : false;
  });

  ngOnInit(): void {
    const request = this.request();
    let savedRecruitSchool = request.requestDetail.recruitSchool;

    this.recruitmentRestService
      .getDesiredMomentOfRecruitSchool(this.request().dutyId)
      .pipe(
        take(1),
        tap(recruitSchoolOptions => {
          this.availableRecruitSchoolOptions.set(recruitSchoolOptions ?? []);

          // make automatic selection to earliest possible date
          if (!savedRecruitSchool) {
            savedRecruitSchool = this.availableRecruitSchoolOptions().reduce((prev, current) =>
              prev.startDate < current.startDate ? prev : current
            );
          }

          this.form.patchValue({
            desiredMomentOfRecruitSchool: savedRecruitSchool?.startDate,
            cycleId: request.requestDetail.substitutionCycleId
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  onNext(): void {
    const selectedSchoolStart = this.form.value.desiredMomentOfRecruitSchool!;
    const recruitSchool = this.availableRecruitSchoolOptions()!.find(opt => opt.startDate === selectedSchoolStart)!;
    this.action.emit({
      type: 'updateRekSubstitutionDate',
      payload: {
        recruitSchool: {
          year: recruitSchool.year,
          seasonDto: recruitSchool.seasonDto,
          startDate: recruitSchool.startDate,
          endDate: recruitSchool.endDate
        },
        substitutionCycleId: this.form.value.cycleId!
      }
    });
  }

  onSaveClick(): void {
    this.action.emit({type: 'saveDraft'});
  }
}
