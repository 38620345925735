import {Directive} from '@angular/core';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MONTH_YEAR_DATE_FORMAT} from '../config/date.config';

/**
 * In Angular it is cumbersome to have multiple date adapters with different format
 * in the same component. To mitigate this, we introduce the following directives,
 * that provide a custom date format inside the scope on which it is applied.
 * Example:
 *  <mat-form-field></mat-form-field> <-- uses default provided format
 *  <mat-form-field appDateFormatYearMonth></mat-form-field> <-- uses month-year format
 */
@Directive({
  selector: '[appDateFormatMonthYear]',
  providers: [{provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_DATE_FORMAT}],
  standalone: true
})
export class DateFormatMonthYearDirective {}
