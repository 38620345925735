import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, EventEmitter, Input, input, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DateRange, MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {tap} from 'rxjs';

@Component({
  selector: 'msa-date-range',
  templateUrl: './date-range.component.html',
  styleUrl: './date-range.component.css',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MarkRequiredDirective,
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    SafeTranslateDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MsaDateRangeComponent,
      multi: true
    }
  ]
})
export class MsaDateRangeComponent implements ControlValueAccessor {
  @Input() id: string | number = '';
  @Input() label = '';
  @Input() dynamicClass = '';
  public showDelete = input(true);
  @Input() hint = '';
  @Input() placeholderFrom = '';
  @Input() placeholderTo = '';
  @Output() remove = new EventEmitter();

  yearFilter = input<number>();
  minDate = computed(() => (this.yearFilter() ? new Date(this.yearFilter()!, 0, 1) : undefined));
  maxDate = computed(() => (this.yearFilter() ? new Date(this.yearFilter()!, 11, 31) : undefined));

  form = new FormGroup({
    start: new FormControl<Date | null>(null, [Validators.required]),
    end: new FormControl<Date | null>(null, [Validators.required])
  });

  constructor() {
    this.form.valueChanges
      .pipe(
        tap(values => {
          if (!values.start || !values.end) return;

          this.onChange(new DateRange<Date>(values.start, values.end));
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  // Function to call when the date range changes.
  onChange = (_dateRange: DateRange<Date> | null) => {
    // // will be overridden
  };

  onTouched = () => {
    // will be overridden
  };

  writeValue(dateRange: DateRange<Date> | null): void {
    this.form.patchValue({start: dateRange?.start, end: dateRange?.end});
  }

  registerOnChange(fn: (dateRange: DateRange<Date> | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  onRemove(): void {
    this.remove.emit();
  }
}
