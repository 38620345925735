import {ChangeDetectionStrategy, Component, EventEmitter, input, Output} from '@angular/core';
import {MsaArrowLinkComponent} from '../msa-arrow-link/msa-arrow-link.component';

@Component({
  selector: 'msa-save-draft',
  templateUrl: './msa-save-draft.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MsaArrowLinkComponent]
})
export class MsaSaveDraftComponent {
  public showSaveDraft = input(false);
  @Output() saveDraft = new EventEmitter();
}
