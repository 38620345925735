import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Store} from '@ngxs/store';
import {Observable, take} from 'rxjs';
import {RequestDto, RequestType} from '../core/api/generated/msa-duty-service';
import {OpenRequest} from '../stores/actions/requests.state.actions';

@Injectable({providedIn: 'root'})
export class RequestResolver implements Resolve<RequestDto> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<RequestDto> | Promise<RequestDto> | RequestDto {
    const shiftRequestId = route.paramMap.get('id');
    const requestType = route.paramMap.get('type');
    if (!shiftRequestId) throw new Error('Param for shift request id may not be null or undefined!');
    if (!requestType) throw new Error('Param for shift request requestType may not be null or undefined!');

    return this.store.dispatch(new OpenRequest(shiftRequestId, requestType.toUpperCase() as RequestType)).pipe(take(1));
  }
}
