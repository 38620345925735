<msa-collapsible title="i18n.duty.details.contacts.title">
  @if (commander()) {
    <msa-commander [commander]="commander()!" />
  } @else {
    <!-- FALLBACK: Hotline -->
    <div
      class="msa-text tw-grid tw-grid-cols-1 tw-gap-6 tw-bg-black-light-1 tw-px-18 tw-py-24 d:tw-grid d:tw-grid-cols-4 d:tw-p-30"
    >
      <div class="tw-grid d:tw-col-span-4 d:tw-grid-cols-subgrid">
        <p class="msa-text-h4 tw-text-white d:tw-col-span-3">
          {{ "i18n.duty.details.contacts.hotline" | safeTranslate }}
        </p>
      </div>

      <div class="tw-grid d:tw-col-span-1 d:tw-mt-12 d:tw-grid-cols-subgrid">
        <p class="tw-text-black-light-5.5" [innerHtml]="'i18n.duty.details.contacts.phone' | safeTranslate"></p>
      </div>
      <div class="tw-w-fit d:tw-col-span-3 d:tw-mt-12">
        <msa-arrow-link [label]="hotline" [showArrow]="false" [externalLink]="'tel:' + hotline | removeWhitespace" />
      </div>
    </div>
  }
</msa-collapsible>
