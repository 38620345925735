<div class="tw-pb-60 tw-pt-30 d:tw-pb-120 d:tw-pt-60">
  <h1 class="msa-text-h1" safeTranslate="i18n.duties.title"></h1>

  <!-- OPEN -->
  <div class="tw-mt-30 d:tw-pl-[86.5px]">
    @if (isOpenDutiesLoading()) {
      <div class="tw-flex tw-justify-center">
        <msa-spinner class="tw-size-48 tw-text-navy" />
      </div>
    } @else {
      <div class="tw-hidden d:tw-block">
        <msa-timeline
          [timelineData]="openDutiesViewModels()"
          [timelineItemTemplate]="desktopItemTemplateOpen"
          textOnEmpty="i18n.duties.empty"
        >
          <div
            class="tw-gap-4 msa-text-small tw-grid tw-h-auto tw-min-h-[32px] tw-grid-cols-24 tw-pb-9 tw-ps-90 tw-text-left tw-text-black-light-5"
            header
          >
            <th class="tw-font-normal d:tw-col-span-6" safeTranslate="i18n.duties.headers.duty"></th>
            <th class="tw-font-normal d:tw-col-span-6" safeTranslate="i18n.duties.headers.timePeriod"></th>
            <th class="tw-font-normal d:tw-col-span-6" safeTranslate="i18n.duties.headers.marchingOrder.title"></th>
            <th class="tw-font-normal d:tw-col-span-4" safeTranslate="i18n.duties.headers.status"></th>
            <th
              class="tw-flex tw-justify-end tw-font-normal d:tw-col-span-2"
              safeTranslate="i18n.duties.headers.actions"
            ></th>
          </div>
        </msa-timeline>
      </div>

      <div class="tw-block d:tw-hidden">
        <!-- Mobile View -->
        <msa-timeline
          [timelineData]="openDutiesViewModels()"
          [timelineItemTemplate]="mobileItemTemplateActual"
          textOnEmpty="i18n.duties.empty"
        />
      </div>
    }
  </div>

  <!-- PAST -->
  <h2 class="tw-mb-15 tw-mt-60" safeTranslate="i18n.duties.headers.dutiesPast"></h2>

  <div class="d:tw-pl-[86.5px]">
    @if (isOpenDutiesLoading()) {
      <div class="tw-flex tw-justify-center">
        <msa-spinner class="tw-size-48 tw-text-navy" />
      </div>
    } @else {
      <div class="tw-hidden d:tw-block">
        <msa-timeline
          [timelineData]="doneDutiesViewModels()"
          [timelineItemTemplate]="desktopItemTemplatePast"
          textOnEmpty="i18n.duties.empty"
        >
          <div
            class="tw-gap-4 msa-text-small tw-grid tw-h-auto tw-min-h-[32px] tw-grid-cols-24 tw-pb-9 tw-text-left tw-text-black-light-5 d:tw-ps-90"
            header
          >
            <th class="tw-font-normal d:tw-col-span-6" safeTranslate="i18n.duties.headers.duty"></th>
            <th class="tw-font-normal d:tw-col-span-6" safeTranslate="i18n.duties.headers.timePeriod"></th>
            <th class="tw-font-normal d:tw-col-span-3" safeTranslate="i18n.duties.headers.daysCompleted"></th>
            <th class="tw-font-normal d:tw-col-span-3" safeTranslate="i18n.duties.headers.daysCreditable"></th>
            <th class="tw-font-normal d:tw-col-span-4" safeTranslate="i18n.duties.headers.status"></th>
            <th
              class="tw-flex tw-justify-end tw-font-normal d:tw-col-span-2"
              safeTranslate="i18n.duties.headers.actions"
            ></th>
          </div>
        </msa-timeline>
      </div>

      <div class="tw-block d:tw-hidden">
        <!-- Mobile View -->
        <msa-timeline
          [timelineData]="doneDutiesViewModels()"
          [timelineItemTemplate]="mobileItemTemplatePast"
          textOnEmpty="i18n.duties.empty"
        />
      </div>
    }
  </div>
</div>

<!-- template OPEN -->
<ng-template #desktopItemTemplateOpen let-data>
  <div
    class="tw-gap-4 tw-grid tw-h-[72px] tw-grid-cols-24 tw-items-center tw-ps-15"
    [attr.data-extid]="data.extDutyId"
    [class]="
      data.msaDutyState === plannedValue
        ? 'tw-bg-rg-black-light-1 tw-text-black-light-5'
        : 'tw-bg-black-light-1 hover:tw-bg-black-light-0.5'
    "
  >
    @if (data.divisionCode) {
      <div
        class="row-value d:tw-col-span-6"
        [innerHTML]="
          'i18n.duties.serviceAt'
            | safeTranslate
              : {
                  dutyType: data.dutyTypeCode.shortDescriptionDto | translateObject,
                  division: data.divisionCode.descriptionDto | translateObject,
                }
        "
      ></div>
    } @else {
      <div class="row-value d:tw-col-span-6" [innerHTML]="data.dutyTypeCode.descriptionDto | translateObject"></div>
    }

    <div class="row-value d:tw-col-span-6">
      @if (data.detachmentCode == null || data.detachmentCode.length == 0) {
        @if (data.startDate && data.endDate) {
          {{ data.startDate | date }} - {{ data.endDate | date }}
          @if (data.msaDutyState === plannedValue) {
            @if (data.dutyTypeCode.codeHash === dutyTypeA()) {
              <msa-tooltip
                tooltipTitle="i18n.duties.flyouts.plannedRS.title"
                tooltipContent="i18n.duties.flyouts.plannedRS.text"
                iconColor="tw-text-tuscany"
              />
            } @else {
              <msa-tooltip
                tooltipTitle="i18n.duties.flyouts.datesNotFixed.title"
                tooltipContent="i18n.duties.flyouts.datesNotFixed.text"
                iconColor="tw-text-tuscany"
              />
            }
          }
        } @else {
          <p safeTranslate="i18n.duties.unknown"></p>
        }
      }
    </div>

    <div class="row-value d:tw-col-span-6">
      <div class="tw-flex tw-align-middle">
        <msa-marching-order-document [marchingOrderDoc]="data.marchingOrder!" [dutyId]="data.id" />
      </div>
    </div>

    <div class="row-value d:tw-col-span-4">
      <msa-status-pill
        [text]="data.statusText"
        [backgroundClass]="data.backgroundColor"
        [colorClass]="data.textColor"
      />
    </div>

    <div class="tw-flex tw-justify-end d:tw-col-span-2">
      <msa-actions-menu [actions]="data.actions" />
    </div>
  </div>

  @if (data.showWZPInfo) {
    <app-msa-content-notice>
      <msa-arrow-link label="i18n.wzp.submitWZPInfo" colorStyling="white" />
    </app-msa-content-notice>
  }

  @for (detachment of data.detachmentCode; track detachment.order) {
    <div
      class="tw-gap-4 tw-mb-[2px] tw-grid tw-h-[72px] tw-grid-cols-12 tw-items-center tw-bg-black-light-2 tw-pe-15 tw-ps-15"
    >
      <td class="row-value d:tw-col-span-3">
        <span class="tw-font-bold">{{ detachment.order }}</span>
      </td>
      <td class="row-value d:tw-col-span-3">{{ detachment.startDate | date }}- {{ detachment.endDate | date }}</td>
      <td class="row-value d:tw-col-span-6">
        <div class="tw-flex tw-align-middle">
          <msa-marching-order-document [marchingOrderDoc]="detachment.marchingOrder!" [dutyId]="data.id" />
        </div>
      </td>
    </div>
  }
</ng-template>

<ng-template #mobileItemTemplateActual let-data>
  <msa-card
    class="tw-relative tw-mb-0 tw-mt-0"
    [activeCard]="data.msaDutyState !== plannedValue"
    [attr.data-extid]="data.extDutyId"
  >
    <msa-actions-menu [actions]="data.actions" />

    <div class="mobile-duty-item tw-text-black-light-5">
      @if (data.divisionCode) {
        <p
          class="mobile-duty-item__title"
          [class]="data.msaDutyState === plannedValue ? 'tw-text-black-light-5' : ''"
          [innerHTML]="
            'i18n.duties.serviceAt'
              | safeTranslate
                : {
                    dutyType: data.dutyTypeCode.shortDescriptionDto | translateObject,
                    division: data.divisionCode.descriptionDto | translateObject,
                  }
          "
        ></p>
      } @else {
        <p
          class="mobile-duty-item__title"
          [class]="data.msaDutyState === plannedValue ? 'tw-text-black-light-5' : ''"
          [innerHTML]="data.dutyTypeCode.descriptionDto | translateObject"
        ></p>
      }

      @if (data.detachmentCode.length == 0) {
        <div
          class="mobile-duty-item__label"
          [class]="data.msaDutyState === plannedValue ? 'tw-text-black-light-5' : ''"
        >
          @if (data.startDate && data.endDate) {
            {{ data.startDate | date }} - {{ data.endDate | date }}

            @if (data.msaDutyState === plannedValue) {
              @if (data.dutyTypeCode.codeHash === dutyTypeA()) {
                <msa-tooltip
                  tooltipTitle="i18n.duties.flyouts.plannedRS.title"
                  tooltipContent="i18n.duties.flyouts.plannedRS.text"
                  iconColor="tw-text-tuscany"
                />
              } @else {
                <msa-tooltip
                  tooltipTitle="i18n.duties.flyouts.datesNotFixed.title"
                  tooltipContent="i18n.duties.flyouts.datesNotFixed.text"
                  iconColor="tw-text-tuscany"
                />
              }
            }
          } @else {
            <p safeTranslate="i18n.duties.unknown"></p>
          }
        </div>
      }
      <div class="mobile-duty-item__label" [class]="data.msaDutyState === plannedValue ? 'tw-text-black-light-5' : ''">
        <span>{{ "i18n.duties.headers.status" | safeTranslate }}: </span>
        <msa-status-pill
          [text]="data.statusText"
          [backgroundClass]="data.backgroundColor"
          [colorClass]="data.textColor"
        />
      </div>
      <div class="tw-flex tw-align-middle">
        <msa-marching-order-document [marchingOrderDoc]="data.marchingOrder!" [dutyId]="data.id" [isMobile]="true" />
      </div>
      @for (detachment of data.detachmentCode; track detachment.order) {
        <div class="tw-bg-black-light-2 tw-p-12">
          <p class="mobile-duty-item__title tw-mb-9 tw-font-bold">{{ detachment.order }}</p>
          <div class="mobile-duty-item__label">{{ detachment.startDate | date }} - {{ detachment.endDate | date }}</div>
          <div class="tw-flex tw-w-full tw-text-black-light-5.5">
            <msa-marching-order-document
              class="tw-w-full"
              [marchingOrderDoc]="detachment.marchingOrder!"
              [dutyId]="data.id"
              [isMobile]="true"
            />
          </div>
        </div>
      }
    </div>
  </msa-card>
  @if (data.showWZPInfo) {
    <app-msa-content-notice>
      <msa-arrow-link label="i18n.wzp.submitWZPInfo" colorStyling="white" />
    </app-msa-content-notice>
  }
</ng-template>

<!-- template PAST -->
<ng-template #desktopItemTemplatePast let-data>
  <div
    class="tw-gap-4 tw-relative tw-grid tw-h-[72px] tw-grid-cols-24 tw-items-center tw-bg-black-light-1 tw-ps-15"
    [attr.data-extid]="data.extDutyId"
  >
    <div
      class="row-value tw-col-span-6"
      [innerHTML]="
        'i18n.duties.serviceAt'
          | safeTranslate
            : {
                dutyType: data.dutyTypeCode.shortDescriptionDto | translateObject,
                division: data.divisionCode.descriptionDto | translateObject,
              }
      "
    ></div>
    <div class="row-value tw-col-span-6">{{ data.startDate | date }} - {{ data.endDate | date }}</div>
    <div class="row-value tw-col-span-3">{{ data.daysCompleted }}</div>
    <div class="row-value tw-col-span-3">{{ data.daysCreditable }}</div>
    <div class="row-value tw-col-span-4 tw-flex tw-items-center tw-gap-6">
      <msa-status-pill
        [text]="data.statusText"
        [backgroundClass]="data.backgroundColor"
        [colorClass]="data.textColor"
      />
      <!-- deprecated -->
      @if (data.msaDutyState === completedState) {
        <msa-tooltip
          tooltipTitle="i18n.duties.flyouts.days-completed.title"
          tooltipContent="i18n.duties.flyouts.days-completed.text"
        />
      }
    </div>
    <div class="tw-col-span-2 tw-flex tw-justify-end">
      <msa-actions-menu [actions]="data.actions" />
    </div>
  </div>
</ng-template>

<ng-template #mobileItemTemplatePast let-data>
  <msa-card class="tw-relative" [attr.data-extid]="data.extDutyId">
    <msa-actions-menu [actions]="data.actions" />

    <div class="mobile-duty-item">
      <p class="mobile-duty-item__title">
        <span class="tw-font-bold">{{ data.dutyTypeCode.shortDescriptionDto | translateObject }} </span> bei
        {{ data.divisionCode.descriptionDto | translateObject }}
      </p>
      <div class="mobile-duty-item__label">{{ data.startDate | date }} - {{ data.endDate | date }}</div>
      @if (data.daysCompleted != null && data.daysCreditable != null) {
        <div class="mobile-duty-item__label">
          <span class="tw-text-black-light-5.5"
            >{{ "i18n.duties.headers.daysCompletedCreditable" | safeTranslate }} :
          </span>

          {{ data.daysCompleted }}/{{ data.daysCreditable }}
        </div>
      }
      <div class="mobile-duty-item__label tw-flex tw-items-center tw-gap-6 tw-break-keep">
        <span class="tw-text-black-light-5.5">{{ "i18n.duties.headers.status" | safeTranslate }}: </span>
        <msa-status-pill
          [text]="data.statusText"
          [backgroundClass]="data.backgroundColor"
          [colorClass]="data.textColor"
        />
        @if (data.msaDutyState === completedState) {
          <msa-tooltip
            tooltipTitle="i18n.duties.flyouts.days-completed.title"
            tooltipContent="i18n.duties.flyouts.days-completed.text"
          />
        }
      </div>
    </div>
  </msa-card>
</ng-template>
