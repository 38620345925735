import {CdkAccordionItem, CdkAccordionModule} from '@angular/cdk/accordion';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatExpansionPanelState} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {provideAnimations} from '@angular/platform-browser/animations';
import {AccordionSlide} from '@shared/shared-module/animations/animations';

@Component({
  selector: 'app-msa-accordion',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule, MatIconModule],
  providers: [provideAnimations()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [AccordionSlide],
  templateUrl: './msa-accordion.component.html'
})
export class MsaAccordionComponent extends CdkAccordionItem {
  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }
}
