import {Predicate} from '@angular/core';

export type WithId = {id?: string};
export type WithDutyId = {dutyId?: string};
type WithCode = {code?: string};
type WithCodeHash = {codeHash?: string};

export function findById<T extends WithId>(targetId: string): Predicate<T> {
  return (item: T) => item.id === targetId;
}

export function findByDutyId<T extends WithDutyId>(targetId: string): Predicate<T> {
  return (item: T) => item.dutyId === targetId;
}

export function findByCode<T extends WithCode>(targetId: string): Predicate<T> {
  return (item: T) => item.code === targetId;
}

export function findByCodeV2<T extends WithCodeHash>(targetId: string): Predicate<T> {
  return (item: T) => item.codeHash === targetId;
}
