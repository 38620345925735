import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, DestroyRef, effect, inject, input, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {getSeasonTranslation} from '@shared/shared-module/utils/season-utils';
import {
  CyclesDto,
  DetailsShiftRekrDto,
  DetailsShiftRekrUniversityDto,
  RecruitmentRestService
} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {take, tap} from 'rxjs';
import {KeyValueComponent} from '../../../../../../../../src/app/shared-module/components/key-value/key-value.component';

@Component({
  selector: 'msa-recruitment-substitution-time-summary',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './msa-recruitment-substitution-date-summary.component.html',
  imports: [CommonModule, SafeTranslateModule, KeyValueComponent]
})
export class MsaRecruitmentSubstitutionTimeSummaryComponent {
  public dutyId = input.required<string>();
  public requestDetails = input.required<DetailsShiftRekrDto | DetailsShiftRekrUniversityDto>();

  private readonly recruitmentRestService = inject(RecruitmentRestService);
  private readonly destoryRef = inject(DestroyRef);

  public substitutionCycle = signal<CyclesDto | undefined>(undefined);

  public readonly getSeasonTranslation = getSeasonTranslation;
  public _fetchSubstitutionCycle = effect(() => {
    this.recruitmentRestService
      .getRecruitmentCycles(this.dutyId(), this.requestDetails().recruitSchool.startDate)
      .pipe(
        take(1),
        tap(cycles =>
          this.substitutionCycle.set(cycles.find(c => c.cycleId === this.requestDetails().substitutionCycleId))
        ),
        takeUntilDestroyed(this.destoryRef)
      )
      .subscribe();
  });
}
