import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

type Primitive = string | number | boolean | null | undefined;
type NestedPaths<T> = T extends Primitive
  ? never
  : {
      [K in keyof T]: T[K] extends Primitive ? `${K & string}` : `${K & string}` | `${K & string}.${NestedPaths<T[K]>}`;
    }[keyof T];

@Pipe({
  name: 'orderBy',
  standalone: true
})
export class OrderByPipe implements PipeTransform {
  transform<S>(array: S[], sortBy: NestedPaths<S>, sortOrder?: 'asc' | 'desc'): S[] {
    if (!sortOrder) sortOrder = 'asc';
    return orderBy(array, [sortBy], [sortOrder]);
  }
}
