<div class="tw-w-full" [class.tw-text-tuscany]="mustNotMarch()">
  @if (isMobile && marchingOrderDoc().type != MarchingOrderDocumentDtoTypeEnum.None) {
    <div class="tw-mb-[3px] tw-pt-6">{{ "i18n.duties.headers.marchingOrder.title" | safeTranslate }}:</div>
  }

  <div class="tw-flex tw-flex-row tw-items-center tw-gap-6">
    @if (marchingOrderDoc().type === MarchingOrderDocumentDtoTypeEnum.Digital) {
      <button
        class="tw-flex tw-items-center"
        (click)="downloadDocument(marchingOrderDoc().documentId)"
        data-cy="downloadDocument"
      >
        <div class="tw-me-6 tw-flex-grow-0"><mat-icon class="tw-font-large">picture_as_pdf</mat-icon></div>
        <p class="tw-flex-grow-1 tw-content-start tw-break-words tw-text-start">
          {{ marchingOrderDoc().documentName! }}
        </p>
      </button>
    } @else {
      {{ text() | safeTranslate }}
    }

    @switch (marchingOrderDoc().type) {
      @case (MarchingOrderDocumentDtoTypeEnum.Paper) {
        <msa-tooltip
          class="tw-flex tw-align-middle"
          tooltipTitle="i18n.duties.headers.marchingOrder.flyouts.paper.title"
          tooltipContent="i18n.duties.headers.marchingOrder.flyouts.paper.text"
        />
      }

      @case (MarchingOrderDocumentDtoTypeEnum.NotYetIssued) {
        <msa-tooltip
          class="tw-flex tw-align-middle"
          tooltipTitle="i18n.duties.headers.marchingOrder.flyouts.notYetIssued.title"
          tooltipContent="i18n.duties.headers.marchingOrder.flyouts.notYetIssued.text"
        />
      }

      @case (MarchingOrderDocumentDtoTypeEnum.DoNotMarch) {
        <msa-tooltip
          class="tw-flex tw-align-middle"
          iconColor="tw-text-tuscany"
          tooltipTitle="i18n.duties.headers.marchingOrder.flyouts.doNotMarch.title"
          tooltipCustomIcon="error_outline"
          tooltipContent="i18n.duties.headers.marchingOrder.flyouts.doNotMarch.text"
        />
      }
    }
  </div>
</div>
