import {
  AfterViewInit,
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  TemplateRef,
  ViewContainerRef,
  computed,
  inject,
  input
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, ValidationErrors} from '@angular/forms';
import {MatError} from '@angular/material/form-field';
import {TranslateService} from '@ngx-translate/core';
import {isEmpty, omit} from 'lodash';
import {combineLatest, distinctUntilChanged, map, startWith, tap} from 'rxjs';

/**
 * Unfortunately it is not possible to content project mat-errors in Angular.
 * This directive enables us to dislay mat-errors dynamically, with all the
 * content provided inside the validation error itself.
 */
@Directive({
  selector: '[msaFormErrors]',
  standalone: true
})
export class MsaFormErrorDirective implements AfterViewInit {
  public msaFormErrors = input.required<FormControl>();

  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly container = inject(ViewContainerRef);
  private readonly templateRef = inject(TemplateRef<MatError>);
  private readonly translateService = inject(TranslateService);

  errors = computed(() => this.msaFormErrors().errors ?? []);

  ngAfterViewInit(): void {
    combineLatest([
      this.msaFormErrors().statusChanges,
      this.translateService.onLangChange.pipe(startWith({translations: [], lang: this.translateService.currentLang}))
    ])
      .pipe(
        startWith(void 0),
        map(() => this.msaFormErrors().errors ?? {}),
        // we don´t want to show required as form errors
        map(errors => omit(errors, 'required')),
        distinctUntilChanged(),
        tap(errors => this.updateView(errors)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private updateView(errors: ValidationErrors | null): void {
    this.container.clear();

    if (!isEmpty(errors)) {
      Object.entries(errors).forEach(([errorKey, validationError]) => {
        const errorText = this.translateService.instant(validationError.text, validationError.params);

        // Get error
        const view = this.container.createEmbeddedView<MatError>(this.templateRef);
        const el: HTMLElement = view.rootNodes[0];
        el.innerHTML = errorText;

        el.setAttribute('data-error', errorKey);
      });

      this.changeDetectorRef.detectChanges();
    }
  }
}
