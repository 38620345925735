import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, effect, input, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {tap} from 'rxjs';
import {FormStepperNavigationComponent} from '../../../../../../../../src/app/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaSaveDraftComponent} from '../../../../../../../../src/app/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '../../../../../../../../src/app/shared-module/components/required-fields-notice/required-fields-notice.component';
import {MarkRequiredDirective} from '../../../../../../../../src/app/shared-module/directives/mark-required.directive';
import {TranslateObjectPipe} from '../../../../../../../../src/app/shared-module/pipes/translate-object.pipe';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {
  CodeDto,
  DetailsShiftFdtDto,
  ImmutableConstraint,
  RequestType
} from '../../../../core/api/generated/msa-duty-service';
import {RequestIncompleteNoticeComponent} from '../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

interface ShiftReasonForm {
  reasonType: FormControl<CodeDto['code']>;
}

@Component({
  selector: 'msa-step-shift-reason',
  templateUrl: './step-shift-reason.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    TranslateObjectPipe,
    MsaSaveDraftComponent,
    RequestIncompleteNoticeComponent,
    RequestContextComponent,
    SafeTranslateDirective
  ]
})
export class StepShiftReasonComponent extends StepEditRequestComponent implements OnInit {
  public reasonTypes = input.required<CodeDto[]>();

  form = new FormGroup<ShiftReasonForm>({
    reasonType: new FormControl(undefined, {nonNullable: true, validators: [Validators.required]})
  });

  constructor() {
    super();
    this.form.valueChanges
      .pipe(
        tap(values => this.action.emit({type: 'updateUserFormData', payload: values})),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      this.form.enable({emitEvent: false});
      if (this.request().immutables.some(i => i === ImmutableConstraint.ReasonType)) {
        this.form.disable({emitEvent: false});
      }
    });
  }

  ngOnInit(): void {
    this.form.patchValue(
      {reasonType: (<DetailsShiftFdtDto>this.request().requestDetail).reasonType},
      {emitEvent: false}
    );
  }

  onNext(): void {
    this.action.emit({type: 'updateReasonType', payload: {text: this.form.value.reasonType!}});
  }

  onSaveClick(): void {
    this.action.emit({type: 'saveDraft'});
  }

  protected readonly RequestType = RequestType;
}
