import agreementTranslations from '@assets/i18n/agreement/de.json';
import rootTranslations from '@assets/i18n/de.json';
import dvsTranslations from '@assets/i18n/dvs/de.json';
import landingPageTranslations from '@assets/i18n/landing-page/de.json';
import leaveTranslations from '@assets/i18n/leave/de.json';
import militaryCareerTranslations from '@assets/i18n/military-career/de.json';
import notificationsTranslations from '@assets/i18n/notifications/de.json';
import profileSettingsTranslations from '@assets/i18n/profile_settings/de.json';
import {
  DaysOfWeekDto,
  DisplacementTime,
  RequestType,
  TrackingState
} from '../../../../projects/admin-query/src/app/core/api/generated/msa-duty-service';

// add all existing translation types here
type TranslationUnion = typeof rootTranslations &
  typeof dvsTranslations &
  typeof agreementTranslations &
  typeof profileSettingsTranslations &
  typeof militaryCareerTranslations &
  typeof landingPageTranslations &
  typeof notificationsTranslations &
  typeof leaveTranslations;

type JsonValue = string | number | boolean | null | JsonObject | JsonArray;

interface JsonObject {
  [key: string]: JsonValue;
}

interface JsonArray extends Array<JsonValue> {}

type ObjectPath<T, K extends keyof T = keyof T> = K extends string
  ? T[K] extends JsonObject
    ? `${K}.${ObjectPath<T[K]>}`
    : T[K] extends JsonArray
      ? never // Arrays are not supported in this context
      : K
  : never;

export type TranslationString = ObjectPath<TranslationUnion> | '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TranslationParams = any;

export function getTrackingStateTranslation(trackingState: TrackingState): TranslationString {
  switch (trackingState) {
    case TrackingState.Army:
      return 'i18n.requests.tracking-states.army';
    case TrackingState.University:
      return 'i18n.requests.tracking-states.university';
    case TrackingState.ArmyCompleted:
      return 'i18n.requests.tracking-states.army-completed';
    default:
      return '';
  }
}

export function getStudyDayTranslation(studyDay: DaysOfWeekDto): TranslationString {
  switch (studyDay) {
    case DaysOfWeekDto.Monday:
      return 'i18n.duty-relocation-form.university.day1.monday-short.label';
    case DaysOfWeekDto.Tuesday:
      return 'i18n.duty-relocation-form.university.day2.tuesday-short.label';
    case DaysOfWeekDto.Wednesday:
      return 'i18n.duty-relocation-form.university.day3.wednesday-short.label';
    case DaysOfWeekDto.Thursday:
      return 'i18n.duty-relocation-form.university.day4.thursday-short.label';
    case DaysOfWeekDto.Friday:
      return 'i18n.duty-relocation-form.university.day5.friday-short.label';
    case DaysOfWeekDto.Saturday:
      return 'i18n.duty-relocation-form.university.day6.saturday-short.label';
    case DaysOfWeekDto.Sunday:
      return 'i18n.duty-relocation-form.university.day7.sunday-short.label';
    case DaysOfWeekDto.Irregular:
      return 'i18n.duty-relocation-form.university.day8.irregular.label';
    default:
      throw new Error(`Unknown study day ${studyDay}!`);
  }
}

export function getSubstitutionTimeTranslation(substitutionTimeOption: DisplacementTime): TranslationString {
  switch (substitutionTimeOption) {
    case DisplacementTime.ThisYear:
      return 'i18n.duty-relocation-form.period.always';
    case DisplacementTime.Partially:
      return 'i18n.duty-relocation-form.period.not_always';
    case DisplacementTime.NotThisYear:
      return 'i18n.duty-relocation-form.period.never.text';
    default:
      throw new Error(`Unknown substitution time option ${substitutionTimeOption}!`);
  }
}

export function getStudyTypeTranslation(studyType: string): TranslationString {
  switch (studyType) {
    case 'HOEHERE_BERUFSBILDUNG':
      return 'i18n.duty-relocation-form.university.studyType.HOEHERE_BERUFSBILDUNG';
    case 'HOCHSCHULE':
      return 'i18n.duty-relocation-form.university.studyType.HOCHSCHULE';
    case 'UNIVERSITAET':
      return 'i18n.duty-relocation-form.university.studyType.UNIVERSITAET';
    default:
      return '';
  }
}

export function getRequestTypeTranslation(requestType: RequestType): TranslationString {
  switch (requestType) {
    case RequestType.Shift:
      return 'i18n.requests.label.dvs';
    case RequestType.Leave:
      return 'i18n.requests.label.leave';
    case RequestType.Reconsideration:
      return 'i18n.requests.label.weg';
    default:
      throw new Error(`Unknown request type ${requestType}!`);
  }
}
