<msa-collapsible title="i18n.duty.details.pendingRequests">
  <div class="msa-text-small tw-grid tw-grid-cols-1 tw-gap-6 d:tw-grid d:tw-grid-cols-3">
    @for (request of pendingRequestViews(); track request.id) {
      @if ($first) {
        <!-- Header -->
        <span
          class="tw-hidden tw-pb-9 tw-ps-24 tw-text-black-light-5 d:tw-block"
          safeTranslate="i18n.requests.headers.requestType"
        ></span>
        <span
          class="tw-hidden tw-pb-9 tw-text-black-light-5 d:tw-block"
          safeTranslate="i18n.requests.headers.submitted"
        ></span>
        <span
          class="tw-hidden tw-pb-9 tw-text-black-light-5 d:tw-block"
          safeTranslate="i18n.requests.headers.state"
        ></span>
      }

      <!-- Desktop View -->
      <div
        class="msa-text tw-col-span-3 tw-hidden tw-min-h-[72px] tw-cursor-pointer tw-grid-cols-3 tw-items-center tw-bg-black-light-1 tw-pb-21 tw-pe-24 tw-ps-24 tw-pt-21 hover:tw-bg-black-light-2 d:tw-grid"
        [routerLink]="['/admin-query', 'requests', request.requestType, request.id]"
        [attr.data-id]="request.id"
      >
        <span class="tw-font-bold">{{ request.requestTypeText | safeTranslate }}</span>
        <span>{{ request.submittedAt | date }}</span>
        <msa-status-pill [text]="request.statusText" [backgroundClass]="request.backgroundColor" />
      </div>

      <!-- Mobile View -->
      <div
        class="tw-flex tw-cursor-pointer tw-flex-col tw-rounded-[4px] tw-bg-black-light-1 tw-p-15 hover:tw-bg-black-light-2 d:tw-hidden"
        [routerLink]="['/admin-query', 'requests', request.requestType, request.id]"
        [attr.data-id]="request.id"
      >
        <span class="msa-text tw-mb-9 tw-font-bold">{{ request.requestTypeText | safeTranslate }}</span>
        <div class="tw-mb-6 tw-flex tw-gap-12">
          <span class="tw-text-black-light-5.5">{{ "i18n.requests.headers.submitted" | safeTranslate }} :</span>
          {{ request.submittedAt | date }}
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-12">
          <span class="tw-text-black-light-5.5">{{ "i18n.requests.headers.state" | safeTranslate }}: </span>
          <msa-status-pill [text]="request.statusText" [backgroundClass]="request.backgroundColor" />
        </div>
      </div>
    } @empty {
      <msa-empty-list-box class="tw-col-span-3" message="i18n.requests.empty" />
    }
  </div>
</msa-collapsible>
