import {DialogRef} from '@angular/cdk/dialog';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, Signal, signal} from '@angular/core';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {catchError, map, Observable, of, take, tap} from 'rxjs';
import {MsaCheckboxComponent} from '../../../../../../src/app/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaContentNoticeComponent} from '../../../../../../src/app/shared-module/components/msa-content-notice/msa-content-notice.component';
import {
  DialogConfirmEvent,
  MsaDialogComponent
} from '../../../../../../src/app/shared-module/components/msa-dialog/msa-dialog.component';
import {MsaSpinnerComponent} from '../../../../../../src/app/shared-module/components/msa-spinner/msa-spinner.component';
import {DesiredRecruitSchoolRestService} from '../../core/api/generated/msa-duty-service';
import {
  DesiredRecruitSchoolDto,
  DesiredRecruitSchoolDtoOtStateEnum
} from '../../core/api/generated/msa-duty-service/model/desiredRecruitSchoolDto.model';
import {RecruitSchoolOptionDto} from '../../core/api/generated/msa-duty-service/model/recruitSchoolOptionDto.model';
import {SeasonDto} from '../../core/api/generated/msa-duty-service/model/seasonDto.model';
import {YearAndSeasonDto} from '../../core/api/generated/msa-duty-service/model/yearAndSeasonDto.model';
import {getSeasonText} from '../../utils/translation.utils';

export type MsaDesiredRecruitSchoolDialogData = {
  // is seasonNumber-year
  // 3-2024 is Summer 2024
  wzp?: string;
};

type SchoolOption = RecruitSchoolOptionDto & {
  code: string;
  text: TranslationString;
  seasonText: TranslationString;
};

const isValidSchool = (schoolOptions: Signal<SchoolOption[]>): AsyncValidatorFn => {
  const schoolOptions$ = toObservable(schoolOptions);
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const schoolCode = control.value;
    if (!isDefined(schoolCode)) return of(null);

    return schoolOptions$.pipe(
      map(schools => {
        const selectedSchool = schools.find(s => s.code === schoolCode);
        return !selectedSchool?.valid ? {isValid: false} : null;
      }),
      take(1)
    );
  };
};

@Component({
  selector: 'msa-desired-recruit-school-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SafeTranslateModule,
    ReactiveFormsModule,
    MatSelectModule,
    MsaDialogComponent,
    MsaCheckboxComponent,
    MsaContentNoticeComponent,
    MsaSpinnerComponent
  ],
  templateUrl: './desired-recruit-school-dialog.component.html'
})
export class MsaDesiredRecreuitSchoolDialogComponent implements OnInit {
  private desiredRecruitSchoolRestService = inject(DesiredRecruitSchoolRestService);
  private dialogData = inject<MsaDesiredRecruitSchoolDialogData>(MAT_DIALOG_DATA, {optional: true});
  private dialogRef = inject(DialogRef);
  private destroyRef = inject(DestroyRef);
  private errorMessageHandler = inject(ErrorMessageHandler);
  private snackbarService = inject(SnackbarService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  // Exposed state variables
  public isLoadingRecruitSchoolInfo = signal(true);
  public hasError = signal(false);
  public isSendingDesiredRecruitSchool = signal(false);
  public isLoading = signal(false);

  public desiredRecruitSchoolInfo = signal<DesiredRecruitSchoolDto | undefined>(undefined);
  public recruitSchoolOptions = computed<SchoolOption[]>(() => {
    const schools = this.desiredRecruitSchoolInfo()?.recruitSchoolOptions ?? [];
    return schools.filter(isDefined).map((school: RecruitSchoolOptionDto) => this.getSchoolOption(school));
  });
  public canEditPZP = computed(
    () =>
      !this.desiredRecruitSchoolInfo()?.isWZPSet &&
      this.desiredRecruitSchoolInfo()?.otState === DesiredRecruitSchoolDtoOtStateEnum.Active
  );
  public isActiveAndSetPZP = computed(
    () =>
      this.desiredRecruitSchoolInfo()?.isWZPSet &&
      this.desiredRecruitSchoolInfo()?.otState === DesiredRecruitSchoolDtoOtStateEnum.Active
  );

  public isbeforePZP = computed(
    () => this.desiredRecruitSchoolInfo()?.otState === DesiredRecruitSchoolDtoOtStateEnum.Future
  );
  public isAfterPZP = computed(
    () => this.desiredRecruitSchoolInfo()?.otState === DesiredRecruitSchoolDtoOtStateEnum.Past
  );
  public isPreselectionInvalid = computed(
    () =>
      !this.selectedSchool()?.valid && this.desiredRecruitSchoolInfo()?.recruitSchoolOptions?.some(opt => !opt.valid)
  );
  public confirmButtonText = computed<TranslationString>(() =>
    !this.canEditPZP() ? 'i18n.common.close' : 'i18n.common.save'
  );

  public form = new FormGroup({
    school: new FormControl<SchoolOption['code'] | undefined>(
      undefined,
      [Validators.required],
      [isValidSchool(this.recruitSchoolOptions)]
    ),
    consent: new FormControl<boolean>(false, [Validators.requiredTrue, Validators.required])
  });

  private schoolChanges = toSignal(this.form.controls.school.valueChanges, {
    initialValue: this.form.value.school
  });
  private selectedSchool = computed(() => {
    return this.recruitSchoolOptions()?.find(school => school.code === this.schoolChanges());
  });

  private wzpRegex = new RegExp(/(\d+)-(\d+)/);

  ngOnInit(): void {
    const preselectedWZP = this.parseWZPParameter(this.dialogData?.wzp);
    this.isLoadingRecruitSchoolInfo.set(true);

    this.desiredRecruitSchoolRestService
      .getDesiredRecruitSchoolOptions(preselectedWZP)
      .pipe(
        tap(() => this.isLoadingRecruitSchoolInfo.set(false)),
        tap(response => {
          this.desiredRecruitSchoolInfo.set(response);

          if (!preselectedWZP) return;
          const preselectedSchool = response.recruitSchoolOptions?.find(
            school => school.seasonDto === preselectedWZP.seasonDto && school.year === preselectedWZP.year
          );
          if (!preselectedSchool) return;
          this.form.controls.school.setValue(this.getSchoolOption(preselectedSchool).code);
          this.form.controls.school.markAsTouched();
        }),
        catchError((err: unknown) => {
          this.hasError.set(true);
          this.isLoadingRecruitSchoolInfo.set(false);
          return this.errorMessageHandler.logAndIgnore(err);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public confirm(event: DialogConfirmEvent): void {
    if (!this.canEditPZP()) {
      this.dialogRef.close();
      return;
    }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (!this.form.valid) return;

    const selectedSchool = this.recruitSchoolOptions().find(s => s.code === this.form.value.school);
    if (!selectedSchool) return;

    this.hasError.set(false);
    this.isLoading.set(true);
    this.desiredRecruitSchoolRestService
      .putDesiredRecruitSchool({year: selectedSchool.year, seasonDto: selectedSchool.seasonDto})
      .pipe(
        tap(_response => {
          this.isLoading.set(false);

          // close and show success notification
          this.snackbarService.openSnackbar({
            text: 'i18n.wzp.successText',
            type: MessageType.Success
          });

          event.resolve();
        }),
        catchError((err: unknown) => {
          this.hasError.set(true);
          this.isLoading.set(false);
          return this.errorMessageHandler.logAndIgnore(err);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private parseWZPParameter(wzp?: string): YearAndSeasonDto | undefined {
    if (!wzp) return undefined;

    if (!this.wzpRegex.test(wzp)) return undefined;

    const matches = this.wzpRegex.exec(wzp);
    const [year, seasonDto] = [+matches!.at(2)!, this.parseSeason(+matches!.at(1)!)];
    if (!year || !seasonDto) return;
    return {year, seasonDto};
  }

  /**
   * 1: winter
   * 2: spring
   * 3: summer
   * 4: autumn
   */
  private parseSeason(seasonCode: number): SeasonDto | undefined {
    switch (seasonCode) {
      case 1:
        return SeasonDto.Winter;
      case 2:
        return SeasonDto.Spring;
      case 3:
        return SeasonDto.Summer;
      case 4:
        return SeasonDto.Autumn;
      default:
        console.warn(`Unknown season code ${seasonCode}!`);
        return undefined;
    }
  }

  private getSchoolOption = (school: RecruitSchoolOptionDto): SchoolOption => {
    return {
      ...school,
      code: `${school.seasonDto}-${school.year}`,
      text: 'i18n.wzp.schoolText' as TranslationString,
      seasonText: school.seasonDto ? getSeasonText(school.seasonDto) : ''
    };
  };
}
