@if (contextData(); as data) {
  <p class="msa-text tw-mb-6" translate="i18n.shift.affectedRequest"></p>
  <app-msa-accordion>
    <div class="tw-flex tw-flex-col tw-gap-12" header>
      <p class="msa-text-small tw-font-bold" safeTranslate="i18n.requests.label.dvs"></p>
      <p class="msa-text-small">
        {{ data.dutyType | translateObject }}:&nbsp;
        {{ data.division | translateObject }}
      </p>
      <p class="tw-flex tw-flex-row tw-gap-12">
        <span class="msa-text-small tw-text-black-light-5.5" safeTranslate="i18n.requests.headers.submitted"></span>
        {{ data.submittedStateDateTime | date }}
      </p>
      <p class="tw-flex tw-flex-row tw-items-center tw-gap-12">
        <span class="msa-text-small tw-text-black-light-5.5">{{ "i18n.requests.headers.state" | safeTranslate }}:</span>
        <msa-status-pill [text]="data.state.statusText" [backgroundClass]="data.state.backgroundColor" />
      </p>
    </div>

    <div body>
      <msa-request-summary [requestId]="affectedRequest()!.id" />
    </div>
  </app-msa-accordion>

  <div class="tw-mt-30 d:tw-mt-60"></div>
}
