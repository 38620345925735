import {RequestDto, RequestType} from '../../core/api/generated/msa-duty-service';

export class FetchRequest {
  static readonly type = '[requests] Load request';

  constructor(
    public requestId: string,
    public requestType: RequestType
  ) {}
}

export class FetchAllRequests {
  static readonly type = '[requests] Fetch all requests';
}

export class WithdrawLeaveRequest {
  static readonly type = '[editRequest] Withdraw leave request';

  constructor(public requestId: string) {}
}

export class DeleteRequest {
  static readonly type = '[requests] Delete a request';
  constructor(
    public requestId: string,
    public s3BucketType: 'PISA' | 'MILO'
  ) {}
}

export class OpenRequest {
  static readonly type = '[requests] Open a request';
  constructor(
    public requestId: string,
    public requestType: RequestType
  ) {}
}

export class UpdateRequest {
  static readonly type = '[request] Update request';
  constructor(public request: RequestDto) {}
}
