import {DatePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {MsaSpinnerComponent} from '@shared/shared-module/components/msa-spinner/msa-spinner.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {FeatureFlagDirective} from '@shared/shared-module/directives/feature-flag.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {OrderByPipe} from '@shared/shared-module/pipes/order-by.pipe';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {translateObject, TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {LoggingService} from '@shared/shared-module/services/logging/base-logging.service';
import {addToDate, formatDate, GERMAN_SHORT_DATE_FORMAT, UTCdate} from '@shared/shared-module/utils/date-time.utils';
import {catchError, EMPTY, take, tap} from 'rxjs';
import {Action, DateTimeRangeDtoV2, DutiesRestService, DutyDetailsDto} from '../../core/api/generated/msa-duty-service';
import {CreateRequestDialogComponent} from '../../dialogs/duties-dialog/create-request-dialog.component';
import {DutyTranslationsUtils, StatusFields} from '../../utils/translation.utils';
import {ContactsComponent} from './contacts/contacts.component';
import {MarchingOrderDetailsComponent} from './marching-order-details/marching-order-details.component';
import {MilitaryPostalAddressComponent} from './military-postal-address/military-postal-address.component';
import {PendingRequestsComponent} from './pending-requests/pending-requests.component';
import {SwissPassDetailsComponent} from './swisspass-details/swisspass-details.component';

type DutyDetailViewModel = DutyDetailsDto & StatusFields<string>;

@Component({
  selector: 'msa-duties-details',
  templateUrl: './duty-details.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    SafeTranslatePipe,
    TranslateObjectPipe,
    RouterLink,
    SafeTranslateDirective,
    StatusPillComponent,
    MatIconModule,
    MatMenuModule,
    DatePipe,
    OrderByPipe,
    MsaContentNoticeComponent,
    MsaSpinnerComponent,
    MarchingOrderDetailsComponent,
    PendingRequestsComponent,
    MilitaryPostalAddressComponent,
    ContactsComponent,
    FeatureFlagDirective,
    SwissPassDetailsComponent
  ]
})
export class DutyDetailsComponent {
  private activatedRoute = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);
  private dutyService: DutiesRestService = inject(DutiesRestService);
  private snackbarService = inject(SnackbarService);
  private translateService = inject(TranslateService);
  private loggingService = inject(LoggingService);

  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;

  public duty = signal<DutyDetailViewModel | undefined>(undefined);
  private dutyId = computed<string>(() => this.activatedRoute.snapshot.paramMap.get('id')!);
  public error = signal<unknown>(null);
  public isLoading = computed(() => !this.duty() && !this.error());
  public dialogShiftAndLeaveRequests = computed(() =>
    this.duty()?.actions?.some(a => a === Action.DialogShiftAndLeaveRequests)
  );

  constructor(private dialog: MatDialog) {
    effect(
      () => {
        this.dutyService
          .getDetailsById(this.dutyId())
          .pipe(
            take(1),
            tap(() => this.error.set(null)),
            tap(duty =>
              this.duty.set({
                ...duty,
                ...DutyTranslationsUtils.getStatusFields(duty.msaState)
              })
            ),
            catchError((err: unknown) => {
              this.error.set(err);
              this.loggingService.error(err);
              return EMPTY;
            }),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe();
      },
      {allowSignalWrites: true}
    );
  }

  openDialog() {
    try {
      this.dialog.open(CreateRequestDialogComponent, {
        data: this.dutyId()
      });
    } catch (error) {
      this.snackbarService.openSnackbar({
        text: this.translateService.instant('i18n.common.error.generic'),
        type: MessageType.Error
      });
    }
  }

  setCalendarLink(duty: DutyDetailsDto, ecDateTime: DateTimeRangeDtoV2, buttonRef: HTMLAnchorElement): void {
    const dutyType = translateObject(duty.dutyTypeCode.shortDescriptionDto, this.translateService.currentLang);
    const division = translateObject(duty.divisionCode.shortDescriptionDto, this.translateService.currentLang);

    // Translation String contains HTML tags -> convert to text only
    const translatedTextAt = new DOMParser().parseFromString(
      this.translateService.instant('i18n.duties.serviceAt', {dutyType, division}),
      'text/html'
    ).documentElement.textContent;

    const dateToCorrected = addToDate(ecDateTime.endDate!, 0, 1); // use next day for proper calendar handling

    const icsFileContent = `BEGIN:VCALENDAR
        VERSION:2.0
        PRODID:-//Portal Armee//NONSGML www.portal-armee.ch//DE
        BEGIN:VEVENT
        UID:msa-portal-armee@${ecDateTime.id}
        DTSTAMP:${UTCdate('YYYYMMDDTHHmmss[Z]')}
        DTSTART;VALUE=DATE:${formatDate(ecDateTime.startDate, 'YYYYMMDD')}
        DTEND;VALUE=DATE:${formatDate(dateToCorrected, 'YYYYMMDD')}
        SUMMARY:${translatedTextAt}
        URL:https://www.portal-armee.ch/
        X-MICROSOFT-CDO-BUSYSTATUS:BUSY
        END:VEVENT
        END:VCALENDAR`.replace(/  +/g, ''); // remove intendation from multiline string as this can lead to parsing errors in calendar applications

    const link = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsFileContent);
    buttonRef.setAttribute('href', link);
  }
}
