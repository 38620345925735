import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {RequestType} from '../../../../core/api/generated/msa-duty-service';
import {StepEditRequestComponent} from '../step-edit-request-component/step-edit-request.component';

@Component({
  selector: 'app-step-submit-success',
  templateUrl: './step-submit-success.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    MsaContentNoticeComponent,
    SafeTranslateDirective,
    SafeTranslatePipe,
    MsaButtonComponent
  ]
})
export class StepSubmitSuccessComponent extends StepEditRequestComponent {
  public disclaimer = input<TranslationString>('');

  message = computed<TranslationString>(() => {
    if (this.request().requestType === RequestType.Reconsideration) {
      return 'i18n.duty.reconsideration.submit-text.SUBMITTED';
    } else {
      return 'i18n.duty.submit-text.SUBMITTED';
    }
  });

  closeShiftRequest(): void {
    this.action.emit({type: 'close'});
  }
}
