import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {DutyDetailsContactsDtoCommander, RankAddition} from '../../../../core/api/generated/msa-duty-service';
import {RemoveWhitespacePipe} from '@shared/shared-module/pipes/remove-whitespace.pipe';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {NgTemplateOutlet} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';

@Component({
  selector: 'msa-commander',
  standalone: true,
  imports: [RemoveWhitespacePipe, SafeTranslatePipe, NgTemplateOutlet, MatIconModule, TranslateObjectPipe],
  templateUrl: './commander.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommanderComponent {
  commander = input.required<DutyDetailsContactsDtoCommander>();

  rankAddition = computed(() => {
    switch (this.commander().rankAddition) {
      case RankAddition.J:
        return 'i18n.user.banner.rank-addition.j';
      case RankAddition.Rkd:
        return 'i18n.user.banner.rank-addition.rkd';
      case RankAddition.N:
        return '';
      default:
        throw new Error('rank addition not found: ' + this.commander().rankAddition);
    }
  });
  avatar = computed(() => 'avatar_' + (this.commander().gender === 'F' ? 'female' : 'male'));
}
