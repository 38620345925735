<cdk-accordion class="tw-block">
  <cdk-accordion-item
    class="tw-rounded-4 tw-block tw-rounded tw-bg-black-light-0.5 hover:tw-bg-black-light-0.5"
    role="button"
    tabindex="0"
  >
    <div
      class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-bg-black-light-1 tw-pb-30 tw-pe-30 tw-ps-30 tw-pt-24 hover:tw-bg-black-light-2"
      (click)="this.toggle()"
    >
      <ng-content select="[header]" />
      <div>
        <mat-icon [fontIcon]="this.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" />
      </div>
    </div>

    <div [@slideInOut]="getExpandedState()" [class.expanded]="expandedChange | async" role="region">
      <div class="tw-h-auto tw-bg-black-light-0.5 tw-pb-30 tw-pe-30 tw-ps-30 tw-pt-24">
        <ng-content select="[body]" />
      </div>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
