import {DeepPartial} from '@shared/shared-module/utils/store.utils';
import {DocumentInfoDto, RequestDetailDto, RequestType} from '../../core/api/generated/msa-duty-service';

export class CreateReconsideration {
  static readonly type = '[editRequest] Create reconsideration';

  constructor(public requestId: string) {}
}

export class CreateShiftRequest {
  static readonly type = '[editRequest] Create new shift request';

  constructor(public dutyId: string) {}
}

export class CreateLeaveRequest {
  static readonly type = '[editRequest] Create new leave request';

  constructor(public dutyId: string) {}
}

export class SaveDraft {
  static readonly type = '[editRequest] Save request detail';
}

export class UpdateUserFormData {
  static readonly type = '[editRequest] Update User Form Data';

  constructor(public requestDetail: DeepPartial<RequestDetailDto>) {}
}

export class DiscardUserFormData {
  static readonly type = '[editRequest] Discard User Form Data';
}

export class UpdateRequestDocuments {
  static readonly type = '[editRequest] Update request documents';

  constructor(public documents: DocumentInfoDto[]) {}
}

export class RemoveDocument {
  static readonly type = '[editRequest] Remove Document';

  constructor(public documentId: string) {}
}

export class DownloadAttachmentDocument {
  static readonly type = '[editRequest] download attachment document';

  constructor(
    public requestId: string,
    public document: DocumentInfoDto,
    public requestType: RequestType
  ) {}
}

export class DownloadResponseDocument {
  static readonly type = '[editRequest] download response document';

  constructor(
    public requestId: string,
    public document: DocumentInfoDto,
    public requestType: RequestType
  ) {}
}

export class SubmitRequest {
  static readonly type = '[editRequest] Submit request';
}

export class CloseRequest {
  static readonly type = '[editRequest] Close request';
}

export class FetchDivisionSchedule {
  static readonly type = '[editRequest] Fetch division schedule';
}
