<p class="msa-text-h4 tw-mb-6 d:tw-mb-12" [innerHtml]="'i18n.duty.details.contacts.commander' | safeTranslate"></p>

<!-- mobile -->

<div class="msa-text tw-bg-black-light-1 d:tw-hidden">
  <div class="tw-grid tw-grid-cols-5">
    <div class="tw-col-span-2 tw-flex tw-justify-center tw-bg-black-light-8">
      <mat-icon class="!tw-h-[120px] !tw-w-auto !tw-p-21 tw-text-[#213344]" [svgIcon]="avatar()" />
    </div>
    <div class="tw-col-span-3 tw-px-18 tw-py-15 tw-font-bold">
      {{ commander().rank | translateObject }} {{ rankAddition() | safeTranslate }} {{ commander().firstName }}
      {{ commander().lastName }}
    </div>
  </div>

  <div class="tw-px-18 tw-py-24">
    @if (commander().phone) {
      <div
        class="tw-mb-9 tw-text-black-light-5.5"
        [innerHtml]="'i18n.duty.details.contacts.phone' | safeTranslate"
      ></div>
      <div>
        <a href="{{ 'tel:' + commander().phone | removeWhitespace }}">
          {{ commander().phone }}
        </a>
      </div>
    }
    <div class="tw-my-15 tw-border-t tw-border-t-black-light-4"></div>
    @if (commander().email) {
      <div
        class="tw-mb-9 tw-text-black-light-5.5"
        [innerHtml]="'i18n.duty.details.contacts.mail' | safeTranslate"
      ></div>
      <div>
        <a href="{{ 'mailto:' + commander().email }}">{{ commander().email }}</a>
      </div>
    }
  </div>
</div>

<!-- desktop -->

<div class="msa-text tw-hidden tw-grid-cols-4 tw-gap-6 tw-bg-black-light-1 d:tw-grid">
  <div class="tw-col-span-1 tw-flex tw-justify-center tw-bg-black-light-8">
    <mat-icon class="!tw-h-[220px] !tw-w-auto !tw-p-21 tw-text-[#213344]" [svgIcon]="avatar()" />
  </div>
  <div class="tw-col-span-3 tw-px-30 tw-py-24">
    <p class="tw-pb-12 tw-font-bold">
      {{ commander().rank | translateObject }} {{ rankAddition() | safeTranslate }} {{ commander().firstName }}
      {{ commander().lastName }}
    </p>
    <div class="tw-grid tw-grid-cols-3">
      @if (commander().phone) {
        <div
          class="tw-col-span-1 tw-py-6 tw-text-black-light-5.5"
          [innerHtml]="'i18n.duty.details.contacts.phone' | safeTranslate"
        ></div>
        <div class="tw-col-span-2 tw-py-6">
          <a href="{{ 'tel:' + commander().phone | removeWhitespace }}">
            {{ commander().phone }}
          </a>
        </div>
      }
      @if (commander().email) {
        <div
          class="tw-col-span-1 tw-py-6 tw-text-black-light-5.5"
          [innerHtml]="'i18n.duty.details.contacts.mail' | safeTranslate"
        ></div>
        <div class="tw-col-span-2 tw-py-6">
          <a href="{{ 'mailto:' + commander().email }}">{{ commander().email }}</a>
        </div>
      }
    </div>
  </div>
</div>
