<div class="tw-m-auto tw-max-w-[892px] tw-py-60 d:tw-py-120">
  <div class="tw-absolute tw-right-[3px] tw-top-[3px]">
    <mat-icon class="!tw-size-48 tw-text-black-light-7" routerLink="../" svgIcon="close" />
  </div>

  @if (isLoading()) {
    <div class="tw-flex tw-w-full tw-justify-center">
      <msa-spinner class="tw-size-60" />
    </div>
  } @else if (error()) {
    <app-msa-content-notice type="Error">
      <p safeTranslate="i18n.common.error.generic"></p>
    </app-msa-content-notice>
  } @else {
    <h1 class="msa-text-h1">{{ duty()!.dutyTypeCode.descriptionDto | translateObject }}</h1>

    <div class="msa-text tw-mb-30 tw-mt-30 tw-grid tw-gap-9 d:tw-mb-60 d:tw-mt-60 d:tw-grid-cols-3 d:tw-gap-15">
      <div class="tw-text-black-light-5">{{ "i18n.common.date" | safeTranslate }}</div>
      <div class="tw-col-span-3 d:tw-col-span-2">
        <div class="tw-grid tw-grid-cols-8">
          @for (ecDateTime of duty()!.dateTimeRanges! | orderBy: "startDate"; track ecDateTime) {
            <div class="tw-col-span-5 d:tw-col-span-4">
              @if (ecDateTime.startTime) {
                {{ ecDateTime.startTime }},
              }
              {{ ecDateTime.startDate | date: GERMAN_SHORT_DATE_FORMAT }} -
              {{ ecDateTime.endDate | date: GERMAN_SHORT_DATE_FORMAT }}
            </div>
            <div class="tw-col-span-3 tw-text-right tw-text-navy-light-2 d:tw-col-span-4">
              <a
                #calButton
                [download]="'i18n.duty.details.calendar.saveShort' | safeTranslate"
                (click)="setCalendarLink(duty()!, ecDateTime!, calButton)"
                href="#"
                data-cy="calendarDownload"
              >
                <mat-icon
                  class="tw-inline-block tw-size-24 tw-h-24 tw-w-24 tw-pe-6 tw-align-top"
                  svgIcon="calendar-add"
                />
                <span class="tw-align-super d:tw-hidden">{{
                  "i18n.duty.details.calendar.saveShort" | safeTranslate
                }}</span>
                <span class="tw-hidden tw-align-super d:tw-inline">{{
                  "i18n.duty.details.calendar.save" | safeTranslate
                }}</span>
              </a>
            </div>
          }
        </div>
      </div>

      <div class="tw-text-black-light-5">
        {{ "i18n.duties.headers.location" | safeTranslate }}
      </div>

      <div class="tw-col-span-3 d:tw-col-span-2">{{ duty()?.divisionCode?.descriptionDto | translateObject }}</div>

      <div class="tw-text-black-light-5">
        {{ "i18n.duties.headers.status" | safeTranslate }}
      </div>
      <div class="tw-col-span-3 d:tw-col-span-2">
        <msa-status-pill
          [text]="duty()!.statusText"
          [backgroundClass]="duty()!.backgroundColor"
          [colorClass]="duty()!.textColor"
        />
      </div>
    </div>

    <!-- Laufende Gesuche -->
    <msa-pending-request-component [requests]="duty()!.pendingRequests" />

    <!-- Marschbefehle -->
    <msa-marching-order-details [duty]="duty()!" />

    <!-- Swisspass Details -->
    <ng-container *appFeatureFlag="'dutyDetailsPublicTransport'">
      <msa-swisspass-details [dutyId]="duty()!.dutyId" />
    </ng-container>

    <!-- Kontakte -->
    <ng-container *appFeatureFlag="'dutyContacts'">
      <msa-contacts [duty]="duty()!" />
    </ng-container>

    <!-- Postadresse während Dienst -->
    <ng-container *appFeatureFlag="'militaryPostalAddress'">
      <msa-military-postal-address [duty]="duty()!" [militaryPostalAddress]="duty()!.militaryPostalAddress" />
    </ng-container>

    <div class="tw-border-t tw-border-t-black-light-4"></div>
  }

  <div class="tw-mt-30 tw-grid tw-gap-15 d:tw-mt-60 d:tw-grid-cols-2 d:tw-gap-30">
    <div class="d:tw-order-2">
      <button mat-flat-button color="primary" routerLink="../">
        {{ "i18n.common.close" | safeTranslate }}
      </button>
    </div>

    <div class="d:tw-order-1">
      @if (!isLoading() && !error() && dialogShiftAndLeaveRequests()) {
        <button (click)="openDialog()" mat-flat-button color="accent">
          {{ "i18n.duties.headers.shift-or-leave-duty-request" | safeTranslate }}
        </button>
      }
    </div>
  </div>
</div>
