<msa-request-summary [requestId]="request().id!">
  @if (request().requestType !== RequestType.Reconsideration) {
    <app-duty-context data-cy="affectedDuty" />
  } @else {
    <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" data-cy="reconsideredRequest" />
  }
</msa-request-summary>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation (next)="onNext()" (previous)="back.emit()" nextButtonText="i18n.common.submit-service" />
