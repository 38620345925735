import {Injectable, signal} from '@angular/core';

export type BottomBarOptions = {
  text: string;
  icon?: string;
};

export type BottomBarData = {
  show: boolean;
  text: string;
  icon?: string;
};

@Injectable({providedIn: 'root'})
export class BottomBarService {
  public bottomBarData = signal<BottomBarData | null>(null);

  private currentTimeoutId: number | undefined = undefined;
  private DEFAULT_BOTTOM_BAR_TIMEOUT_MILLISECONDS: number = 5000;

  public show(
    options: BottomBarOptions,
    timeoutMilliseconds: number = this.DEFAULT_BOTTOM_BAR_TIMEOUT_MILLISECONDS
  ): void {
    if (this.currentTimeoutId) {
      clearTimeout(this.currentTimeoutId);
    }

    this.bottomBarData.set({show: true, text: options?.text, icon: options?.icon});
    this.currentTimeoutId = window.setTimeout(() => {
      this.bottomBarData.set({show: false, text: ''});
    }, timeoutMilliseconds);
  }
}
