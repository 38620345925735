import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, Input, input, Signal} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {MarchingOrderDocumentDto, MarchingOrderDocumentDtoTypeEnum} from '../../../core/api/generated/msa-duty-service';
import {DocumentService} from '../../../services/document.service';

@Component({
  selector: 'msa-marching-order-document',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MsaTooltipComponent, SafeTranslatePipe, NgClass],
  templateUrl: './marching-order-document.component.html'
})
export class MarchingOrderDocumentComponent {
  protected readonly MarchingOrderDocumentDtoTypeEnum = MarchingOrderDocumentDtoTypeEnum;
  private documentService = inject(DocumentService);

  @Input() isMobile: boolean = false;
  marchingOrderDoc = input<MarchingOrderDocumentDto>({type: MarchingOrderDocumentDtoTypeEnum.None});
  dutyId = input<string>();
  mustNotMarch = computed(() => this.marchingOrderDoc().type === MarchingOrderDocumentDtoTypeEnum.DoNotMarch);

  text: Signal<TranslationString> = computed(() => {
    return this.getMarchingOrderText(this.marchingOrderDoc().type!);
  });

  private getMarchingOrderText(type: MarchingOrderDocumentDtoTypeEnum): TranslationString {
    switch (type) {
      case MarchingOrderDocumentDtoTypeEnum.NotYetIssued:
        return 'i18n.duties.headers.marchingOrder.states.notYetIssued';
      case MarchingOrderDocumentDtoTypeEnum.DoNotMarch:
        return 'i18n.duties.headers.marchingOrder.states.doNotMarch';
      case MarchingOrderDocumentDtoTypeEnum.Paper:
        return 'i18n.duties.headers.marchingOrder.states.paper';
    }
    return '';
  }

  downloadDocument(documentId: string | undefined) {
    let documentName = this.marchingOrderDoc().documentName;
    if (!documentName) {
      documentName = 'marchingOrder';
    }
    const dutyId = this.dutyId();
    if (documentId && dutyId) {
      this.documentService.downloadMarchingOrder(dutyId, documentId, documentName).subscribe();
    }
  }
}
