import {ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose} from '@angular/material/dialog';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import {Store} from '@ngxs/store';
import {
  Action,
  DutiesRestService,
  DutyDtoStateEnum,
  DutyInfoDto,
  Prevention
} from '../../core/api/generated/msa-duty-service';
import {ENGLISH_SHORT_DATE_FORMAT, stringToDate} from '@shared/shared-module/utils/date-time.utils';
import {CreateLeaveRequest, CreateShiftRequest} from '../../stores/actions/edit-request.state.actions';
import moment from 'moment';
import {catchError, EMPTY, take, tap} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {DatePipe} from '@angular/common';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatDialogClose,
    MatIconModule,
    SafeTranslatePipe,
    DatePipe,
    MsaContentNoticeComponent,
    MsaButtonComponent
  ],
  selector: 'app-create-request-dialog',
  standalone: true,
  templateUrl: './create-request-dialog.component.html'
})
export class CreateRequestDialogComponent {
  private data = inject(MAT_DIALOG_DATA);
  public featureFlagService = inject(FeatureFlagService);
  private store = inject(Store);
  private dutyService: DutiesRestService = inject(DutiesRestService);
  private dutyInfo = signal<DutyInfoDto | undefined>(undefined);
  public error = signal<unknown>(null);
  private destroyRef = inject(DestroyRef);

  public showDutyStartedInfo = computed(() => moment(this.dutyInfo()?.startDate).isBefore(moment()));

  public showDutyCancelledOrDoneInfo = computed(
    () =>
      this.dutyInfo()?.dutyState === DutyDtoStateEnum.Canceled || this.dutyInfo()?.dutyState === DutyDtoStateEnum.Done
  );

  public showHasExistingRequestInfo = computed(() => this.hasPrevention(Prevention.AlreadyHasRequest));

  public showDvsNotAllowedInfo = computed(() => this.hasPrevention(Prevention.DvsDutyTypeNotSupported));

  public showDutyTooFarInFutureInfo = computed(() => this.hasPrevention(Prevention.LeaveTooFarInFuture));

  dutyStartDate = computed(() =>
    this.dutyInfo()?.startDate !== undefined
      ? stringToDate(this.dutyInfo()?.startDate, ENGLISH_SHORT_DATE_FORMAT)
      : undefined
  );
  dutyEndDate = computed(() =>
    this.dutyInfo()?.endDate ? stringToDate(this.dutyInfo()?.endDate, ENGLISH_SHORT_DATE_FORMAT) : undefined
  );

  // flags for displaying notifications
  canCreateLeaveRequest = computed(() => this.dutyInfo()?.actions?.some(a => a === Action.CreateLeaveRequest));
  canCreateShiftRequest = computed(() => this.dutyInfo()?.actions?.some(a => a === Action.CreateShiftRequest));

  dvsDutyTypeNotSupported = computed(() => this.hasPrevention(Prevention.DvsDutyTypeNotSupported));

  constructor() {
    effect(
      () => {
        this.dutyService
          .getDutyAdditionalInfo(this.data)
          .pipe(
            take(1),
            tap(() => this.error.set(null)),
            tap(duty => {
              this.dutyInfo.set(duty);
            }),
            catchError((err: unknown) => {
              this.error.set(err);
              return EMPTY;
            }),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe();
      },
      {allowSignalWrites: true}
    );
  }

  private hasPrevention(preventionCode: Prevention): boolean | undefined {
    return this.dutyInfo()?.preventions.includes(preventionCode);
  }

  openShiftDraft() {
    this.store.dispatch(new CreateShiftRequest(this.data));
  }

  openLeaveDraft() {
    this.store.dispatch(new CreateLeaveRequest(this.data));
  }
}
