import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaFileUploadComponent} from '@shared/shared-module/components/msa-file-upload/msa-file-upload.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {distinctUntilChanged, tap} from 'rxjs';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {DetailsLeaveDto, PrivilegeDto, RequestType} from '../../../../core/api/generated/msa-duty-service';
import {RequestDocumentUploadService} from '../../../../services/request-document-upload.service';
import {RequestIncompleteNoticeComponent} from '../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

interface PrivilegeForm {
  privateVehicle: FormControl<boolean>;
  civilClothes: FormControl<boolean>;
}

@Component({
  selector: 'msa-step-leave-privileges',
  templateUrl: './step-leave-privileges.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    MsaFileUploadComponent,
    RequestIncompleteNoticeComponent,
    MsaSaveDraftComponent,
    RequestContextComponent,
    SafeTranslatePipe,
    SafeTranslateDirective,
    MsaCheckboxComponent
  ],
  providers: [RequestDocumentUploadService]
})
export class StepLeavePrivilegesComponent extends StepEditRequestComponent implements OnInit {
  form = new FormGroup<PrivilegeForm>({
    privateVehicle: new FormControl<boolean>(false, {nonNullable: true}),
    civilClothes: new FormControl<boolean>(false, {nonNullable: true})
  });

  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    const leaveRequestDetails = this.request().requestDetail as DetailsLeaveDto;
    this.form.patchValue({
      privateVehicle: leaveRequestDetails?.privateVehicle,
      civilClothes: leaveRequestDetails?.civilClothes
    });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() =>
          this.action.emit({
            type: 'updateUserFormData',
            payload: {
              privateVehicle: this.form.value.privateVehicle,
              civilClothes: this.form.value.civilClothes
            }
          })
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  onNext(): void {
    this.action.emit({type: 'updatePrivileges', payload: this.getUpdatePrivileges()});
  }

  onSaveClick() {
    this.action.emit({type: 'saveDraft'});
  }

  protected readonly RequestType = RequestType;

  getUpdatePrivileges(): PrivilegeDto {
    const civilClothes = this.form.value.civilClothes!;
    const privateVehicle = this.form.value.privateVehicle!;

    return {
      privateVehicle: privateVehicle,
      civilClothes: civilClothes
    };
  }
}
